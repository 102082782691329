import "./ExaminationResultTable.scss";
import { Flex, Table } from "antd";
import styles from "./ExaminationResultTable.module.scss";
import { ExaminationResultTableData } from "../../../types/examination-result/ExaminationResultTableData.model";
import { useEffect, useState } from "react";
import { isEqual } from "lodash";
import { checkExamValueInRange } from "../../../utils/examination-result/ExaminationResult.helper";
import { generateRandomNumber } from "../../../utils/helpers";

interface ExaminationResultTableProps {
  data: ExaminationResultTableData;
  firstColHeader: string;
  firstColHeaderIcon: string;
  firstColHeaderIconClassName?: string;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const ExaminationResultPdfTable = ({
  data,
  firstColHeader,
  firstColHeaderIcon,

  firstColHeaderIconClassName = "",
}: ExaminationResultTableProps) => {
  const { headers, tableData } = data;

  const [dataSource, setDataSource] = useState(tableData);

  useEffect(() => {
    if (!isEqual(tableData, dataSource)) {
      setDataSource(tableData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const nestedColumns: ColumnTypes[number][] = headers.map((header) => {
    return {
      title: (
        <div>
          <div>{header.name}</div>
          <div>({header.unit})</div>
          <div></div>
        </div>
      ),
      align: "center",
      dataIndex: header.name,
      id: `${header.name}Id`,
      createdAt: `${header.name}CreatedAt`,
      typeId: `${header.name}TypeId`,
      dataType: `${header.name}Result`,
      render: (_, record) => {
        const colMin = `${header.name}Min`;
        const colMax = `${header.name}Max`;

        return (
          <div>
            <span
              className={checkExamValueInRange(
                record[header.name],
                record[`${header.name}Min`],
                record[`${header.name}Max`]
              )}
            >
              {record[header.name] ?? "-"}
            </span>{" "}
            <span>{`(${record[colMin] ?? ""}-${record[colMax] ?? ""})`}</span>
          </div>
        );
      },
    };
  });

  const defaultColumns: ColumnTypes[number][] = [
    {
      title: (
        <Flex gap={10} justify="center" align="center">
          <img
            src={firstColHeaderIcon}
            alt="icon"
            className={firstColHeaderIconClassName}
          />
          <span>{firstColHeader}</span>
        </Flex>
      ),
      dataIndex: "createdAt",
      align: "center",
      className: `${styles.firstColHeader} examination-result-table-first-col-header`,
      width: "167px",
    },
    ...nestedColumns,
  ];

  return (
    <Table
      dataSource={dataSource}
      pagination={false}
      bordered
      className="examination-result-table"
      columns={defaultColumns}
      rowHoverable={false}
      rowKey={() => `${generateRandomNumber()}`}
    />
  );
};

export default ExaminationResultPdfTable;
