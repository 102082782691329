import {
  API_CREATE_PROBLEM_ITEM,
  API_DELETE_PROBLEM_ITEM,
  API_GET_PROBLEM_LIST,
  API_UPDATE_PROBLEM_ITEM,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { ProblemListCreateRequest } from "../../types/patient-detail/ProblemListCreateRequest.model";
import { ProblemListReadResponse } from "../../types/patient-detail/ProblemListReadResponse.model";
import { ProblemListUpdateRequest } from "../../types/patient-detail/ProblemListUpdateRequest.model";

export const getProblemList = (
  admissionRecordId: number
): Promise<BaseResponse<ProblemListReadResponse[]>> => {
  return networkAdapter.get(`${API_GET_PROBLEM_LIST}/${admissionRecordId}`);
};

export const createProblemItem = (data: ProblemListCreateRequest) => {
  return networkAdapter.post(API_CREATE_PROBLEM_ITEM, data);
};

export const updateProblemItem = (
  id: number,
  data: ProblemListUpdateRequest
) => {
  return networkAdapter.patch(`${API_UPDATE_PROBLEM_ITEM}/${id}`, data);
};

export const deleteProblemItem = (id: number) => {
  return networkAdapter.delete(`${API_DELETE_PROBLEM_ITEM}/${id}`);
};
