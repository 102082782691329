import React from "react";
import styles from "./IcuFooter.module.scss";
import { useTranslation } from "react-i18next";
import { Flex } from "antd";
import { useSelector } from "react-redux";
import { selectUpdateInfoTime } from "../../../redux/slices/patientSlice";

const IcuFooter = () => {
  const { t } = useTranslation();

  const updatedInfoTime = useSelector(selectUpdateInfoTime);

  return (
    <Flex justify="space-between" className={`${styles.footer} icu-footer`}>
      <div>
        {t("TXP medical")} | {t("API")}: v3.0.0 | {t("Client")}: v9.0.1.0
        |&nbsp;
        {t("Release Date")}: [2021-09-27]
      </div>
      {updatedInfoTime && (
        <div>
          {t("Autosaved at")} {updatedInfoTime}
        </div>
      )}
    </Flex>
  );
};

export default IcuFooter;
