import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import {
  VitalSignEntity,
  VitalSignReadResponse,
} from "../../types/vital-sign/VitalSignReadResponse.model";
import dayjs from "dayjs";
import { VitalSignTableRow } from "../../types/vital-sign/VitalSignTableRow.model";
import { COLOR_VALUE } from "../../constants/common/color.constant";
import { VentilatorReadResponse } from "../../types/vital-sign/VentilatorReadResponse.model";
import { convertUTCtoLocalDate } from "../helpers";
import { max, min } from "lodash";
import { VentilatorSettingCategory } from "../../constants/vital-sign/VentilatorSettingCategory.constant";
import { TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST } from "../../constants/vital-sign/TrianglePointStyleVitalSignList.constant";
import arrowUpIcon from "../../assets/icons/arrow-up.svg";
import arrowDownIcon from "../../assets/icons/arrow-down.svg";
import { VITAL_SIGN_NAMES_ORDER } from "../../constants/vital-sign/InitialShowVitalSignNames.constant";

const getHourLabels = (
  data: VitalSignReadResponse[] | VentilatorReadResponse[]
) => {
  const hourSet = new Set<string>();

  data.forEach((item) => {
    if (item.result.length > 0) {
      item.result.forEach(({ created_at }) => {
        hourSet.add(
          dayjs(convertUTCtoLocalDate(created_at)).format(DATE_FORMAT.HHmm)
        );
      });
    }
  });

  const hourLabels = Array.from(hourSet);

  hourLabels.sort((a, b) => a.localeCompare(b));

  return {
    hourLabels,
  };
};

const checkSkippedValue = (ctx: any, value: number[]) =>
  ctx.p0.skip || ctx.p1.skip ? value : undefined;

export const getColorByVitalSignName = (
  dataSource: VitalSignReadResponse[],
  name: string
) => {
  if (TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(name)) {
    return "#00ff00";
  }

  return dataSource.find((item) => item.name === name)?.color;
};

const calculateVitalSignStepSize = (
  minScale: number,
  maxScale: number,
  total: number
) => {
  return (maxScale - minScale) / (total - 1);
};

const getMinMaxByVitalSignName = (
  dataSource: VitalSignReadResponse[],
  name: string
) => {
  const targetVitalSign = dataSource.find((item) => item.name === name);

  if (targetVitalSign) {
    const minScale = min(targetVitalSign?.ranges);
    const maxScale = max(targetVitalSign?.ranges);

    return {
      stepSize: calculateVitalSignStepSize(
        minScale,
        maxScale,
        targetVitalSign.ranges.length
      ),
      maxScale,
      minScale,
    };
  }

  return {
    stepSize: 0,
    maxScale: 0,
    minScale: 0,
  };
};

export const getPointStyleByVitalSignName = (name: string) => {
  const sbpPointImage = new Image(16, 16);
  const dbpPointImage = new Image(16, 16);

  sbpPointImage.src = arrowDownIcon;
  dbpPointImage.src = arrowUpIcon;

  switch (name) {
    case "SBP":
      return sbpPointImage;
    case "DBP":
      return dbpPointImage;

    default:
      return "circle";
  }
};

export const transformVitalSignToGraphData = (
  data: VitalSignReadResponse[]
) => {
  data.forEach((item) => {
    if (item.result.length > 0) {
      item.result.sort(sortVitalSignByCreateAt);
    }
  });

  const { hourLabels: xLabels } = getHourLabels(data);

  const vitalSignValueMap = new Map<string, (number | null)[]>();

  data.forEach((item) => {
    const newVitalSignValues = xLabels.map((hourLabel) => {
      const correspondValue = item.result.find(
        ({ created_at }) =>
          dayjs(convertUTCtoLocalDate(created_at)).format(DATE_FORMAT.HHmm) ===
          hourLabel
      );

      if (correspondValue) {
        return correspondValue.value;
      }

      return null;
    });

    vitalSignValueMap.set(item.name, newVitalSignValues);
  });

  const vitalSignList = Array.from(vitalSignValueMap, ([label, value]) => ({
    label,
    value,
  }));

  vitalSignList.forEach((vitalSign) => {
    const valueList = vitalSign.value;

    if (valueList.length > 0) {
      for (let index = 0; index < valueList.length; index++) {
        if (!valueList[index]) {
          valueList[index] = null;
        }
      }
    }
  });

  const datasets = vitalSignList.map((item, index) => ({
    label: item.label,
    data: item.value,
    borderColor: getColorByVitalSignName(data, item.label) ?? "",
    backgroundColor: getColorByVitalSignName(data, item.label) ?? "",
    yAxisID: `y${index}`,
    pointRadius: 2,
    pointHoverRadius: 4,
    spanGaps: true,
    segment: {
      borderDash: (ctx: any) =>
        checkSkippedValue(
          ctx,
          TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(item.label)
            ? [10, 5]
            : [6, 0]
        ),
    },
    pointStyle: getPointStyleByVitalSignName(item.label),
    borderDash: TRIANGLE_POINT_STYLE_VITAL_SIGN_LIST.includes(item.label)
      ? [10, 5]
      : undefined,
  }));

  const scaleOption = datasets
    .map((item) => {
      const { stepSize, maxScale, minScale } = getMinMaxByVitalSignName(
        data,
        item.label
      );

      return {
        yAxisID: item.yAxisID,
        stepSize,
        max: maxScale,
        min: minScale,
      };
    })
    .reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.yAxisID]: {
            type: "linear",
            display: false,
            min: current.min - current.stepSize,
            max: current.max + current.stepSize,
            ticks: {
              stepSize: current.stepSize,
            },
          },
        };
      },
      {
        x: {
          ticks: {
            font: {
              size: 14,
              weight: 500,
              family: "Manrope, Poppins, sans-serif",
              color: COLOR_VALUE.grey1,
            },
          },
          grid: {
            lineWidth: 0.25,
          },
          border: {
            display: false,
          },
        },
      }
    );

  return {
    datasets,
    labels: xLabels,
    scaleOption,
  };
};

const filterVentilatorSettingByCategory = (
  list: VentilatorReadResponse[],
  category: string
) => {
  const resultTable = [
    {
      name:
        category === VentilatorSettingCategory.MEASURE
          ? `${category}d`
          : category,
    },
  ];

  list
    .filter((item) => item.category === category)
    .forEach((item) => {
      const rowData = item.result.reduce(
        (prev, current) => {
          return {
            ...prev,
            id: current.id,
            [`${dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.HHmm
            )}`]: current.value ?? undefined,
            date: dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.YYYYMMDDHHmm
            ),
            [`${dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.HHmm
            )}Id`]: current.id,
          };
        },
        {
          name: item.name,
          typeId: item.id,
        }
      );
      resultTable.push(rowData);
    });

  return resultTable;
};

export const transformResponseToVitalSignTableData = (
  data: VitalSignReadResponse[]
) => {
  if (data.length > 0) {
    let tableData: VitalSignTableRow[] = [];
    let { hourLabels: colHeaders } = getHourLabels(data);

    data.sort((a, b) => a.name.localeCompare(b.name));

    const vitalSignSortedByName: VitalSignReadResponse[] = [];

    VITAL_SIGN_NAMES_ORDER.forEach((vitalSignName) => {
      const targetVitalSign = data.find((item) => item.name === vitalSignName);

      if (targetVitalSign) {
        vitalSignSortedByName.push(targetVitalSign);
      }
    });

    vitalSignSortedByName.forEach((item) => {
      const rowData = item.result.reduce(
        (prev, current) => {
          return {
            ...prev,
            id: current.id,
            [`${dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.HHmm
            )}`]: current.value ?? undefined,
            date: dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.YYYYMMDDHHmm
            ),
            [`${dayjs(convertUTCtoLocalDate(current.created_at)).format(
              DATE_FORMAT.HHmm
            )}Id`]: current.id,
          };
        },
        {
          name: item.name,
          typeId: item.id,
        }
      );
      tableData.push(rowData);
    });
    return {
      colHeaders,
      tableData,
    };
  }
  return {
    colHeaders: [],
    tableData: [],
  };
};

export const transformResponseToVentilatorTableData = (
  data: VentilatorReadResponse[]
) => {
  if (data.length > 0) {
    let tableData: VitalSignTableRow[] = [];
    let { hourLabels: colHeaders } = getHourLabels(data);

    data.sort((a, b) => a.name.localeCompare(b.name));

    const measureData = filterVentilatorSettingByCategory(
      data,
      VentilatorSettingCategory.MEASURE
    );
    const settingData = filterVentilatorSettingByCategory(
      data,
      VentilatorSettingCategory.SETTING
    );

    tableData = [...measureData, ...settingData];

    return {
      colHeaders,
      tableData,
    };
  }
  return {
    colHeaders: [],
    tableData: [],
  };
};

export const sortVitalSignByCreateAt = (
  a: VitalSignEntity,
  b: VitalSignEntity
) => {
  if (dayjs(a.created_at).isBefore(dayjs(b.created_at))) return -1;
  if (dayjs(a.created_at).isAfter(dayjs(b.created_at))) return 1;

  return 0;
};

export const calculateGraphContentWidthNoScroll = (totalLabels: number) => {
  const colWidth = 80;

  return colWidth * (totalLabels - 1) + 38;
};

export const calculateGraphContentWidthWithScroll = (totalLabels: number) => {
  const colWidth = 80;

  return colWidth * (totalLabels - 1) + 40;
};

export const transformResponseToVitalSignRangeTable = (
  data: VitalSignReadResponse[]
) => {
  data.forEach((item) => {
    if (item.ranges.length > 0) {
      item.ranges.sort((rangeA, rangeB) => rangeB - rangeA);
    }
  });

  const vitalSignSortedByName: VitalSignReadResponse[] = [];

  VITAL_SIGN_NAMES_ORDER.forEach((vitalSignName) => {
    const targetVitalSign = data.find((item) => item.name === vitalSignName);

    if (targetVitalSign) {
      vitalSignSortedByName.push(targetVitalSign);
    }
  });

  return vitalSignSortedByName.map((item) => ({
    id: item.id,
    name: item.name,
    ranges: item.ranges,
    color: item.color,
  }));
};
