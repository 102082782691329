import React from "react";
import styles from "./TimeButton.module.scss";
import { useTranslation } from "react-i18next";
import { ProgressNoteEntity } from "../../../../../types/patient-detail/ProgressNote.model";
import { convertUTCtoLocalDate } from "../../../../../utils/helpers";
import { DATE_FORMAT } from "../../../../../constants/common/dateFormat.constant";

interface TimeButtonProps {
  data: ProgressNoteEntity;
  author: string;
  isActive: boolean;
  onClick: (id: number) => void;
}

const TimeButton = ({ data, author, onClick, isActive }: TimeButtonProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.timeBtn} ${isActive ? styles.active : ""}`}
      role="button"
      onClick={() => onClick(data.id)}
    >
      {data.createdAt ? (
        <div className={styles.time}>
          {convertUTCtoLocalDate(data.createdAt, DATE_FORMAT.HHmm)}
        </div>
      ) : (
        <div className={styles.underRecordingBtn}>{t("Under recording")}</div>
      )}

      {author && (
        <div className={styles.author}>
          {t("by")} {author}
        </div>
      )}
    </div>
  );
};

export default TimeButton;
