import { API_GET_MEDICAL_DATA } from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { MedicalReadResponse } from "../../types/patient-list/MedicalReadResponse.model";

export const getMedicalData = (): Promise<
  BaseResponse<MedicalReadResponse>
> => {
  return networkAdapter.get(API_GET_MEDICAL_DATA);
};
