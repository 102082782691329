import "./TimeSeriesExamTable.scss";
import { ConfigProvider, Table } from "antd";
import styles from "./TimeSeriesExamTable.module.scss";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";
import { TimeSeriesExamTableRow } from "../../../types/time-series-exam-table/TimeSeriesExamTableRow.model";
import { BACKGROUND_COLOR_VALUE } from "../../../constants/common/color.constant";
import { TimeSeriesExamEditableCell } from "./editable-cell/TimeSeriesExamEditableCell";
import { convertUTCtoLocalDate } from "../../../utils/helpers";
import { isEqual } from "lodash";
import { checkExamValueInRange } from "../../../utils/examination-result/ExaminationResult.helper";

interface TimeSeriesExamTableProps {
  data: TimeSeriesExamTableRow[];
  colHeaders: string[];
  onUpdateCell?: (
    id: number,
    value: string | null,
    typeId: number,
    createdAt: string | number
  ) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const TimeSeriesExamTable = ({
  data,
  colHeaders,
  onUpdateCell,
}: TimeSeriesExamTableProps) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    if (!isEqual(data, dataSource)) {
      setDataSource(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = colHeaders.map((header, index) => {
    const localDateTime = convertUTCtoLocalDate(header);

    return {
      title: (
        <div className="time-series-exam-header">
          <div>{dayjs(localDateTime).format(DATE_FORMAT.YYYYMMDD)}</div>
          <div>{dayjs(localDateTime).format(DATE_FORMAT.HHmm)}</div>
        </div>
      ),
      align: "center",
      dataIndex: header,
      className: index % 2 !== 0 ? "time-series-exam-odd-cell" : "",
      editable: true,
      id: `${header}Id`,
      render(value, record) {
        return (
          <div
            className={checkExamValueInRange(
              value,
              record["minVal"],
              record["maxVal"]
            )}
          >
            {value ?? "-"}
          </div>
        );
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
  })[] = [
    {
      title: <div></div>,
      dataIndex: "name",
      align: "center",
      className: `${styles.firstColHeader} time-series-exam-table-first-col-header`,
      width: "186px",
      fixed: "left",
      render: (_, record) => {
        return (
          <div>
            <div>{record.name}</div>
            <div>({record.unit})</div>
            <div>{record.reference}</div>
          </div>
        );
      },
    },
    ...dynamicColumns,
  ];

  const handleUpdateData = (
    id: number,
    value: string | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => {
    const updatedDataSource = data.map((item) => {
      if (item.resultId === newRowData.resultId) {
        return newRowData;
      }

      return item;
    });

    setDataSource(updatedDataSource);

    onUpdateCell?.(id, value, Number(newRowData.resultId), createdAt);
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        id: col.id ? record[col.id] : undefined,
        handleUpdateData,
      }),
    };
  });

  const components = {
    body: {
      cell: TimeSeriesExamEditableCell,
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            borderColor: BACKGROUND_COLOR_VALUE.grey3,
            headerBorderRadius: 6,
          },
        },
      }}
    >
      <Table
        dataSource={dataSource}
        pagination={false}
        bordered
        className="time-series-exam-table"
        columns={columns as ColumnTypes}
        components={components}
        rowHoverable={false}
        rowKey={(record) => `${record.id}-${record.name}`}
      />
    </ConfigProvider>
  );
};

export default TimeSeriesExamTable;
