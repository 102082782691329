import { Flex, Pagination, Select } from "antd";
import styles from "./PatientListPagination.module.scss";
import "./PatientListPagination.scss";
import { DefaultOptionType } from "antd/es/select";
import { PageEntity } from "../../../types/patient-list/PageEntity.model";

interface PatientListPaginationProps {
  pageInfo: PageEntity;
  options: DefaultOptionType[];
  totalCount: number;
  onChangePageLimit: (limit: number) => void;
  onChangePageOffset: (offset: number) => void;
}

const PatientListPagination = ({
  pageInfo,
  onChangePageOffset,
  onChangePageLimit,
  options,
  totalCount,
}: PatientListPaginationProps) => {
  return (
    <Flex justify="space-between" className={styles.paginationSection}>
      <Flex gap={16}>
        <div>
          <Select
            onChange={onChangePageLimit}
            options={options}
            value={pageInfo.limit}
            className="patient-list-limit-select"
          />
        </div>
      </Flex>
      <Pagination
        current={pageInfo.currentPage}
        onChange={onChangePageOffset}
        total={totalCount}
        showSizeChanger={false}
        className="patient-list-pagination"
        pageSize={pageInfo.limit}
      />
    </Flex>
  );
};

export default PatientListPagination;
