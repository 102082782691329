import React, { useRef, useState } from "react";
import styles from "./ProblemItem.module.scss";
import { Flex, Input } from "antd";
import IcuButton from "../../common/button/IcuButton";
import "./ProblemItem.scss";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ProblemItemEntity } from "../../../types/patient-detail/ProblemItem.model";
import { useDispatch } from "react-redux";
import { setUpdateInfoTime } from "../../../redux/slices/patientSlice";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";
import { UpdatedProblemTypeParams } from "../../../types/patient-detail/UpdatedProblemTypeParams.model";
import { TextAreaRef } from "antd/es/input/TextArea";

interface ProblemItemProps {
  data: ProblemItemEntity;
  onDeleteProblem: (id?: number) => void;
  onBlurProblemInput: (value: string, id?: number) => void;
  currentIndex: number;
  onUpdateProblemType: (item: UpdatedProblemTypeParams) => void;
}

const ProblemItem = ({
  data,
  onDeleteProblem,
  onBlurProblemInput,
  currentIndex,
  onUpdateProblemType,
}: ProblemItemProps) => {
  const { t } = useTranslation();

  const [isSuspicionActive, setIsSuspicionActive] = useState(
    data.suspicionCheck
  );
  const [isPMHCheckActive, setIsPMHCheckActive] = useState(
    data.pastMedicalHistoryCheck
  );

  const problemRef = useRef<TextAreaRef>(null);

  const dispatch = useDispatch();

  const onClickSuspicionBtn = (id: number | undefined) => {
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    setIsSuspicionActive(!isSuspicionActive);

    onUpdateProblemType({
      id,
      field: "suspicion_check",
      value: !isSuspicionActive,
    });
  };

  const onClickPMHCheckBtn = (id: number | undefined) => {
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    setIsPMHCheckActive(!isPMHCheckActive);

    onUpdateProblemType({
      id,
      field: "past_medical_history_check",
      value: !isPMHCheckActive,
    });
  };

  const handleChangeProblemInput = () => {
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));
  };

  const handleBlurProblemInput = (value: string, id: number | undefined) => {
    setTimeout(() => {
      if (data.remarkEntry !== value || data.id === undefined) {
        onBlurProblemInput(value, id);
      }
    }, 150);
  };

  return (
    <Flex className={styles.problemItem}>
      <div className={styles.orderNumber}>{currentIndex + 1}</div>

      <Flex className={styles.mainContent}>
        <Flex gap={10} align="center" className={styles.groupBtn}>
          <IcuButton
            width={84}
            height={42}
            backgroundColor={`${isSuspicionActive ? "primary-2" : "light"}`}
            color={`${isSuspicionActive ? "grey-9" : "body"}`}
            extraClassName={`suspicion-btn ${
              !isSuspicionActive ? "suspicion-btn-inactive" : ""
            }`}
            onClick={() => onClickSuspicionBtn(data.id)}
          >
            {t("Suspected")}
          </IcuButton>
          <IcuButton
            width={84}
            height={42}
            backgroundColor={`${isPMHCheckActive ? "primary-2" : "light"}`}
            color={`${isPMHCheckActive ? "grey-9" : "body"}`}
            extraClassName={`pmh-btn ${
              !isPMHCheckActive ? "pmh-btn-inactive" : ""
            }`}
            onClick={() => onClickPMHCheckBtn(data.id)}
          >
            {t("PMH")}
          </IcuButton>
        </Flex>

        <Input.TextArea
          className={`${styles.problemInput} patient-detail-problem-input`}
          placeholder={t("Detail")}
          defaultValue={data.remarkEntry}
          onBlur={(e) => handleBlurProblemInput(e.target.value, data.id)}
          onChange={handleChangeProblemInput}
          onKeyDown={(e: any) => {
            if (e.key === "Enter" && e.ctrlKey) {
              problemRef.current?.blur();
            }
          }}
          ref={problemRef}
        />

        <Flex align="center">
          <IcuButton
            backgroundColor="secondary"
            extraClassName="problem-item-delete-btn"
            width={34}
            height={34}
            onClick={() => onDeleteProblem(data.id)}
          >
            <CloseOutlined className={styles.closeIcon} />
          </IcuButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProblemItem;
