import React, { ReactNode } from "react";
import styles from "./Wrapper.module.scss";

interface WrapperProps {
  children: ReactNode;
  customClassName?: string;
}

const Wrapper = ({ children, customClassName }: WrapperProps) => {
  return (
    <div className={`${styles.wrapper} ${customClassName ?? ""} icu-wrapper`}>
      {children}
    </div>
  );
};

export default Wrapper;
