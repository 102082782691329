import React from "react";
import styles from "./NoteSection.module.scss";
import { Flex } from "antd";
import { TabFilterPatientDetail } from "../../../constants/patient-detail/TabFilterPersonalDetail";
import AdmissionInfo from "../tabs/admission-info/AdmissionInfo";
import ProgressNote from "../tabs/progress-note/ProgressNote";
import { useTranslation } from "react-i18next";
import PatientDetailInfo from "../tabs/patient-detail-info/PatientDetailInfo";
import { PatientDetailEntity } from "../../../types/common/PatientDetail.model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  getPatientById,
  getProgressNoteList,
} from "../../../redux/slices/patientSlice";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../../constants/common/dateFormat.constant";
import { ProgressNoteEntity } from "../../../types/patient-detail/ProgressNote.model";
import { PayloadAction } from "@reduxjs/toolkit";
import { BaseResponse } from "../../../types/common/BaseResponse.model";
import { ProgressNoteReadResponse } from "../../../types/patient-detail/ProgressNoteReadResponse.model";
import { formatProgressNoteList } from "../../../utils/patient-detail/progress-note/ProgressNote.helper";
import {
  convertUTCtoLocalDateWithNoFormat,
  formatDateBeforeSendToServer,
} from "../../../utils/helpers";
import dayjs from "dayjs";

interface NoteSectionProps {
  activeTab: TabFilterPatientDetail;
  setActiveTab: React.Dispatch<React.SetStateAction<TabFilterPatientDetail>>;
  data: PatientDetailEntity;
  progressNoteFilterDate?: Date;
  setProgressNoteFilterDate: React.Dispatch<
    React.SetStateAction<Date | undefined>
  >;
  currentProgressNote: ProgressNoteEntity | undefined;
  setCurrentProgressNote: React.Dispatch<
    React.SetStateAction<ProgressNoteEntity | undefined>
  >;
}

const NoteSection = ({
  activeTab,
  setActiveTab,
  data,
  progressNoteFilterDate,
  setProgressNoteFilterDate,
  currentProgressNote,
  setCurrentProgressNote,
}: NoteSectionProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const getClassNameByActiveTab = (currentTab: TabFilterPatientDetail) => {
    if (currentTab === activeTab) {
      return styles["active"];
    }

    return "";
  };

  const renderContentByActiveTab = (currentTab: TabFilterPatientDetail) => {
    switch (currentTab) {
      case TabFilterPatientDetail.ADMISSION:
        return <AdmissionInfo data={data} />;
      case TabFilterPatientDetail.DETAIL:
        return <PatientDetailInfo data={data} />;
      default:
        return (
          <ProgressNote
            progressNoteFilterDate={progressNoteFilterDate}
            setProgressNoteFilterDate={setProgressNoteFilterDate}
            admissionRecordId={data.id_admission_record}
            currentProgressNote={currentProgressNote}
            setCurrentProgressNote={setCurrentProgressNote}
          />
        );
    }
  };

  const onClickTab = async (tab: TabFilterPatientDetail) => {
    if (activeTab !== tab) {
      setActiveTab(tab);

      if (tab === TabFilterPatientDetail.PROGRESS_NOTE) {
        const targetDate = progressNoteFilterDate
          ? formatDateBeforeSendToServer(
              `${dayjs(progressNoteFilterDate).format(
                DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
              )} 00:00`,
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
            )
          : undefined;
        const response = (await dispatch(
          getProgressNoteList({
            admissionRecordId: data.id_admission_record,
            date: targetDate,
          })
        )) as PayloadAction<BaseResponse<ProgressNoteReadResponse[]>>;

        if (currentProgressNote === undefined && response.payload?.data) {
          setCurrentProgressNote(
            formatProgressNoteList(response.payload.data)[0]
          );
        }

        if (
          progressNoteFilterDate === undefined &&
          response.payload.data.length > 0
        ) {
          setProgressNoteFilterDate(
            convertUTCtoLocalDateWithNoFormat(
              response.payload.data[0].created_at
            ).toDate()
          );
        } else {
          setProgressNoteFilterDate(progressNoteFilterDate);
        }

        return;
      }

      dispatch(getPatientById(Number(data.id_admission_record)));
    }
  };

  return (
    <Flex className={styles.noteSection} gap={9}>
      <div className={styles.groupTabs}>
        <button
          className={`${styles.admissionTab} ${getClassNameByActiveTab(
            TabFilterPatientDetail.ADMISSION
          )}`}
          onClick={() => onClickTab(TabFilterPatientDetail.ADMISSION)}
        >
          {t("Admission infomation")}
        </button>
        <button
          className={`${styles.detailTab} ${getClassNameByActiveTab(
            TabFilterPatientDetail.DETAIL
          )}`}
          onClick={() => onClickTab(TabFilterPatientDetail.DETAIL)}
        >
          {t("Patient detail")}
        </button>
        <button
          className={`${styles.progressNoteTab} ${getClassNameByActiveTab(
            TabFilterPatientDetail.PROGRESS_NOTE
          )}`}
          onClick={() => onClickTab(TabFilterPatientDetail.PROGRESS_NOTE)}
        >
          {t("Progress note")}
        </button>
      </div>

      <div className={styles.noteDetail}>
        {renderContentByActiveTab(activeTab)}
      </div>
    </Flex>
  );
};

export default NoteSection;
