import { useEffect, useRef, useState } from "react";
import { ExaminationResultTableRow } from "../../../../types/examination-result/ExaminationResultTableData.model";
import { Input, InputRef } from "antd";
import "./ExaminationResultEditableCell.scss";
import { isEqual } from "lodash";

interface ExaminationResultEditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof ExaminationResultTableRow;
  record: ExaminationResultTableRow;
  id: number;
  createdAt: string;
  typeId: number;
  handleUpdateData: (id: number, value: string | null, typeId: number) => void;
  dataType: string;
}

export const ExaminationResultEditableCell: React.FC<
  React.PropsWithChildren<ExaminationResultEditableCellProps>
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleUpdateData,
  id,
  createdAt,
  typeId,
  dataType,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const [inputValue, setInputValue] = useState<string | null>(() => {
    if (record?.[dataIndex] !== undefined && record?.[dataIndex] !== null) {
      return record[dataIndex].toString();
    }

    return null;
  });
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (record !== undefined && !isEqual(record[dataIndex], inputValue)) {
      setInputValue(
        record[dataIndex] !== null && record[dataIndex] !== undefined
          ? record[dataIndex].toString()
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleSaveData = (value: string) => {
    const newValue = value === "" ? null : value;

    setInputValue(value === "" ? null : value);
    if (
      (!record[dataIndex] && newValue !== null) ||
      (!!record[dataIndex] && newValue === null) ||
      (!!record[dataIndex] &&
        newValue !== null &&
        newValue !== record[dataIndex])
    ) {
      handleUpdateData(id, newValue, typeId);
    }
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div>
        <Input
          ref={inputRef}
          onBlur={(e) => handleSaveData(e.target.value)}
          value={inputValue ?? undefined}
          rootClassName="latest-exam-result-cell-input"
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={(e: any) => {
            if (e.keyCode === 13) {
              inputRef.current?.blur();
            }
          }}
        />
      </div>
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
