import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import { getPatientDetail } from "../../services/patient/patientService";
import { formatPatientDetail } from "../../utils/patient-detail/PatientDetail.helper";
import { getProgressNotes } from "../../services/progress-note/progressNoteService";
import { ProgressNoteReadRequest } from "../../types/patient-detail/ProgressNoteReadRequest.model";
import { formatProgressNoteList } from "../../utils/patient-detail/progress-note/ProgressNote.helper";
import { ProgressNoteEntity } from "../../types/patient-detail/ProgressNote.model";

interface PatientState {
  updateInfoTime: string | undefined;
  patientInfo: PatientDetailEntity | undefined;
  progressNoteList: ProgressNoteEntity[];
  loading: boolean;
}

export const getPatientById = createAsyncThunk(
  "patient/getById",
  async (admissionRecordId: number) => {
    try {
      const response = await getPatientDetail(admissionRecordId);

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getProgressNoteList = createAsyncThunk(
  "patient/getProgressNoteList",
  async (params: ProgressNoteReadRequest) => {
    try {
      const response = await getProgressNotes(
        params.admissionRecordId,
        params.date
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  }
);

const initialState: PatientState = {
  updateInfoTime: undefined,
  patientInfo: undefined,
  progressNoteList: [],
  loading: false,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setUpdateInfoTime(state, action) {
      state.updateInfoTime = action.payload;
    },
    setPatientLoading(state, action) {
      state.loading = action.payload;
    },
    setProgressNoteList(state, action) {
      state.progressNoteList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPatientById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPatientById.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.patientInfo = formatPatientDetail(action.payload.data);
        }
        state.loading = false;
      })
      .addCase(getPatientById.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getProgressNoteList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProgressNoteList.fulfilled, (state, action) => {
        if (action.payload?.data) {
          state.progressNoteList = formatProgressNoteList(action.payload.data);
        }
        state.loading = false;
      })
      .addCase(getProgressNoteList.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setUpdateInfoTime, setPatientLoading, setProgressNoteList } =
  patientSlice.actions;

export const selectUpdateInfoTime = (state: RootState) =>
  state.patient.updateInfoTime;

export const selectPatientLoading = (state: RootState) => state.patient.loading;
export const selectPatientInfo = (state: RootState) =>
  state.patient.patientInfo;
export const selectProgressNoteList = (state: RootState) =>
  state.patient.progressNoteList;

export default patientSlice.reducer;
