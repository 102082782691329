// define api endpoint here
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const API_LOGIN = "/login";
export const API_REFRESH_TOKEN = "/refresh_token";

export const API_GET_PATIENT_LIST = "/patient/list";
export const API_PATCH_PATIENT_DETAIL = "/patient";
export const API_REGISTER_PATIENT = "/patient";
export const API_SEARCH_PATIENT = "/patient/search";
export const API_GET_PATIENT_DETAIL = "/patient";

export const API_GET_MEDICAL_DATA = "/medical-data";

export const API_GET_PROBLEM_LIST = "/problem-list";
export const API_CREATE_PROBLEM_ITEM = "/problem-list";
export const API_UPDATE_PROBLEM_ITEM = "/problem-list";
export const API_DELETE_PROBLEM_ITEM = "/problem-list";

export const API_GET_PROGRESS_NOTE = "/progress-note";
export const API_CREATE_PROGRESS_NOTE = "/progress-note";
export const API_UPDATE_PROGRESS_NOTE = "/progress-note";

export const API_GET_LATEST_EXAMINATION_RESULT = "/examination-result/latest";
export const API_GET_EXAMINATION_RESULT = "/examination-result/list";
export const API_PUT_EXAMINATION_RESULT = "/examination-result";
export const API_UPDATE_EXAMINATION_RESULT = "/examination-result";
export const API_CREATE_EXAMINATION_RESULT_OCR = "/examination-result";

export const API_GET_VITAL_SIGN = "/vital-sign/list";
export const API_UPDATE_VITAL_SIGN = "/vital-sign";
export const API_PUT_VITAL_SIGN = "/vital-sign";
export const API_CREATE_VITAL_SIGN_OCR = "/vital-sign";

export const API_GET_VENTILATOR_SETTING = "/ventilator-setting/list";
export const API_UPDATE_VENTILATOR_SETTING = "/ventilator-setting";
export const API_PUT_VENTILATOR_SETTING = "/ventilator-setting";
export const API_CREATE_VENTILATOR_SETTING_OCR = "/ventilator-setting";
