import { Button } from "antd";
import React, { ReactNode } from "react";
import { ButtonHTMLType } from "antd/es/button";
import {
  BACKGROUND_COLOR,
  COLOR,
} from "../../../constants/common/color.constant";
import "./IcuButton.scss";

interface IcuButtonProps {
  children: ReactNode;
  width?: number;
  height?: number;
  icon?: ReactNode;
  backgroundColor?: keyof typeof COLOR | keyof typeof BACKGROUND_COLOR;
  color?: keyof typeof COLOR;
  loading?: boolean;
  htmlType?: ButtonHTMLType;
  onClick?: React.MouseEventHandler<HTMLElement>;
  extraClassName?: string;
  formId?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
}

const IcuButton = ({
  children,
  width,
  height,
  icon,
  backgroundColor = "primary-2",
  color = "light",
  loading,
  htmlType,
  onClick,
  extraClassName = "",
  disabled,
  formId,
  href,
  target,
}: IcuButtonProps) => {
  const customBackgroundColor = backgroundColor ? `bg-${backgroundColor}` : "";
  const customColor = color ? `text-${color}` : "";

  return (
    <Button
      type="primary"
      className={`${customBackgroundColor} ${customColor} ${extraClassName} icu-button`}
      icon={icon}
      style={{
        width,
        height,
      }}
      loading={loading}
      htmlType={htmlType}
      onClick={onClick}
      form={formId}
      disabled={disabled}
      href={href}
      target={target}
    >
      {children}
    </Button>
  );
};

export default IcuButton;
