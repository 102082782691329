import React, { ReactNode } from "react";
import styles from "./ErrorMessage.module.scss";

interface ErrorMessageProps {
  children: ReactNode;
  extraClassName?: string;
}

const ErrorMessage = ({
  children = "",
  extraClassName = "",
}: ErrorMessageProps) => {
  return (
    <div className={`${styles.errorMsg} ${extraClassName}`}>{children}</div>
  );
};

export default ErrorMessage;
