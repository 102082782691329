import { useEffect, useRef, useState } from "react";
import { InputNumber } from "antd";
import { VitalSignTableRow } from "../../../../types/vital-sign/VitalSignTableRow.model";
import "./VitalSignEditableCell.scss";
import { isEqual } from "lodash";

interface VitalSignEditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof VitalSignTableRow;
  record: VitalSignTableRow;
  id: number;
  typeId: number;
  handleUpdateData: (
    id: number,
    typeId: number,
    value: number | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => void;
}

export const VitalSignEditableCell: React.FC<
  React.PropsWithChildren<VitalSignEditableCellProps>
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  id,
  typeId,
  handleUpdateData,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<number>(() => {
    if (record?.[dataIndex] !== undefined && record?.[dataIndex] !== null) {
      return Number(record[dataIndex]);
    }

    return NaN;
  });
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (
      record !== undefined &&
      !isEqual(Number(record[dataIndex]), inputValue)
    ) {
      setInputValue(Number(record[dataIndex]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleSaveData = (value: number) => {
    const newValue = isNaN(value) ? null : value;
    const newRowData = {
      ...record,
      [dataIndex]: isNaN(value) ? "-" : value,
    };

    setInputValue(isNaN(value) ? NaN : value);
    if (
      (isNaN(inputValue) && !isNaN(value)) ||
      (!isNaN(inputValue) && isNaN(value)) ||
      (!isNaN(inputValue) && !isNaN(value) && value !== inputValue)
    ) {
      handleUpdateData(id, typeId, newValue, newRowData, dataIndex);
    }
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <InputNumber
        ref={inputRef}
        onBlur={(e) => handleSaveData(e.target.valueAsNumber)}
        controls={false}
        type="number"
        value={inputValue}
        className="vital-sign-input-number"
        onPressEnter={(e: any) => {
          if (e.keyCode === 13) {
            inputRef.current?.blur();
          }
        }}
      />
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
