import React from "react";
import styles from "./PatientItem.module.scss";
import { Flex } from "antd";
import IcuButton from "../../common/button/IcuButton";
import arrowRightRectangle from "../../../assets/icons/arrow-right-rectangle.svg";
import noteBook from "../../../assets/icons/note-book.svg";
import PersonalInfo from "../../common/personal-info/PersonalInfo";
import TreatmentInfo from "../treatment-info/TreatmentInfo";
import "./PatientItem.scss";
import { PersonalInfoEntity } from "../../../types/patient-list/PersonalInfo.model";
import { TreatmentInfoEntity } from "../../../types/patient-list/TreatmentInfoEntity.model";
import { TabFilterPatientList } from "../../../types/patient-list/TabFilter.model";
import filledReader from "../../../assets/icons/filled-reader.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import pencil from "../../../assets/icons/pencil.svg";
import { LOCAL_STORAGE_KEY } from "./../../../constants/common/localStorageKey.constant";

interface PatientItemProps {
  orderNumber: number;
  personalInfo: PersonalInfoEntity;
  treatmentInfo: TreatmentInfoEntity;
  activeTab: TabFilterPatientList;
  onMovePatient: (admissionRecordId: number) => void;
  onClickEdit: (
    personalInfo: PersonalInfoEntity,
    treatmentInfo: TreatmentInfoEntity
  ) => void;
}

const PatientItem = ({
  orderNumber,
  personalInfo,
  treatmentInfo,
  activeTab,
  onMovePatient,
  onClickEdit,
}: PatientItemProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClickDetailBtn = (id: number) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.patientOrderNumber,
      orderNumber.toString()
    );
    navigate(`/patient-detail/${id}`);
  };

  return (
    <Flex className={styles.patientItem}>
      <div className={styles.orderNumber}>{orderNumber}</div>
      <div>
        <PersonalInfo
          data={personalInfo}
          enableEllipsis
          containerClassName="patient-list-personal-info"
        />
      </div>
      <div className={styles.treatmentInfoSection}>
        <TreatmentInfo data={treatmentInfo} />
      </div>

      <Flex
        vertical
        align="flex-end"
        className={
          activeTab === TabFilterPatientList.ADMITTED
            ? styles.admittedGroupBtn
            : styles.dischargedGroupBtn
        }
        gap={10}
        justify={
          activeTab === TabFilterPatientList.ADMITTED ? "normal" : "center"
        }
      >
        <IcuButton
          backgroundColor="success"
          color="grey-9"
          width={117}
          height={34}
          icon={<img src={noteBook} alt="detail icon" />}
          extraClassName="patient-list-detail-btn"
          onClick={() => handleClickDetailBtn(personalInfo.id_admission_record)}
        >
          {t("Detail")}
        </IcuButton>

        <IcuButton
          backgroundColor="primary-2"
          color="grey-9"
          width={117}
          height={34}
          icon={<img src={pencil} alt="edit icon" />}
          extraClassName="patient-list-edit-btn"
          onClick={() => onClickEdit(personalInfo, treatmentInfo)}
        >
          {t("Edit")}
        </IcuButton>

        {activeTab === TabFilterPatientList.ADMITTED ? (
          <IcuButton
            backgroundColor="danger"
            color="grey-9"
            width={117}
            height={34}
            icon={<img src={arrowRightRectangle} alt="discharged icon" />}
            extraClassName="patient-list-discharge-btn"
            onClick={() => onMovePatient(personalInfo.id_admission_record)}
            disabled={treatmentInfo.is_discharged}
          >
            {t("Discharge")}
          </IcuButton>
        ) : (
          <IcuButton
            backgroundColor="primary-2"
            color="grey-9"
            width={117}
            height={34}
            icon={<img src={filledReader} alt="readmit icon" />}
            extraClassName="patient-list-readmit-btn"
            onClick={() => onMovePatient(personalInfo.id_admission_record)}
          >
            {t("Re-Admit")}
          </IcuButton>
        )}
      </Flex>
    </Flex>
  );
};

export default PatientItem;
