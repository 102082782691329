import { useEffect, useRef, useState } from "react";
import { Input, InputRef } from "antd";
import "./VentilatorEditableCell.scss";
import { VitalSignTableRow } from "../../../../types/vital-sign/VitalSignTableRow.model";
import { isEqual } from "lodash";

interface VentilatorEditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof VitalSignTableRow;
  record: VitalSignTableRow;
  id: number;
  typeId: number;
  handleUpdateData: (
    id: number,
    typeId: number,
    value: string | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => void;
}

export const VentilatorEditableCell: React.FC<
  React.PropsWithChildren<VentilatorEditableCellProps>
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  id,
  typeId,
  handleUpdateData,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const [inputValue, setInputValue] = useState<string | null>(() => {
    if (record?.[dataIndex] !== undefined && record?.[dataIndex] !== null) {
      return record[dataIndex].toString();
    }

    return null;
  });
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if (record !== undefined && !isEqual(record[dataIndex], inputValue)) {
      setInputValue(
        record[dataIndex] !== null && record[dataIndex] !== undefined
          ? record[dataIndex].toString()
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const handleSaveData = (value: string) => {
    const newValue = value === "" ? null : value;
    const newRowData = {
      ...record,
      [dataIndex]: value === "" ? "-" : value,
    };

    setInputValue(value === "" ? null : value);

    if (
      (!record[dataIndex] && newValue !== null) ||
      (!!record[dataIndex] && newValue === null) ||
      (!!record[dataIndex] &&
        newValue !== null &&
        newValue !== record[dataIndex])
    ) {
      handleUpdateData(id, typeId, newValue, newRowData, dataIndex);
    }
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Input
        ref={inputRef}
        onBlur={(e) => handleSaveData(e.target.value)}
        value={inputValue ?? undefined}
        className="ventilator-input-number"
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={(e: any) => {
          if (e.keyCode === 13) {
            inputRef.current?.blur();
          }
        }}
      />
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
