import styles from "./PatientDetailInfo.module.scss";
import { Flex, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import { IcuDateInput } from "../../../common/date-input/IcuDateInput";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PatientTreatmentInfo } from "../../../../types/patient-detail/PatientTreatmentInfo.model";
import { IcuTextInput } from "../../../common/text-input/IcuTextInput";
import * as yup from "yup";
import dayjs, { Dayjs } from "dayjs";
import { DATE_FORMAT } from "../../../../constants/common/dateFormat.constant";
import "./PatientDetailInfo.scss";
import ErrorMessage from "../../../common/error-message/ErrorMessage";
import { PatientDetailEntity } from "../../../../types/common/PatientDetail.model";
import {
  getPatientById,
  setUpdateInfoTime,
} from "../../../../redux/slices/patientSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { patchPatientDetail } from "../../../../services/patient/patientService";
import { formatDateBeforeSendToServer } from "../../../../utils/helpers";
import { useState } from "react";

interface PatientDetailInfoProps {
  data: PatientDetailEntity;
}

const PatientDetailInfo = ({ data }: PatientDetailInfoProps) => {
  const { t } = useTranslation();

  const [physicalData, setPhysicalData] = useState({
    height: data.height,
    weight: data.weight,
    bsa: data.bsa,
  });

  const schema = yup.object({
    hospital_admission_date: yup.string().test({
      name: "admissionTime",
      test: (value, context) => {
        if (value === "") return true;

        return (
          !context.parent.hospital_discharge_date ||
          dayjs(value).isBefore(dayjs(context.parent.hospital_discharge_date))
        );
      },
      message: t("Admission time must be before Discharge time"),
    }),
    pre_admission_route: yup.string(),
    icu_admission_date: yup.string().test({
      name: "icuAdmissionTime",
      test: (value, context) => {
        if (value === "") return true;

        return (
          !context.parent.icu_discharge_date ||
          dayjs(value).isBefore(dayjs(context.parent.icu_discharge_date))
        );
      },
      message: t("Icu admission time must be before Icu discharge time"),
    }),
    hospital_discharge_date: yup.string().test({
      name: "dischargeTime",
      test: (value, context) => {
        if (value === "") return true;

        return dayjs(value).isAfter(
          dayjs(context.parent.hospital_admission_date)
        );
      },
      message: t("Discharge time must be after Admission time"),
    }),
    hospital_discharge_outcome: yup.string(),
    icu_discharge_date: yup.string().test({
      name: "icuDischargeTime",
      test: (value, context) => {
        if (value === "") return true;
        return dayjs(value).isAfter(dayjs(context.parent.icu_admission_date));
      },
      message: t("ICU discharge time must be after ICU admission time"),
    }),
    icu_discharge_outcome: yup.string(),
  });

  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    setError,
    watch,
    resetField,
  } = useForm<PatientTreatmentInfo>({
    defaultValues: data,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const dispatch = useDispatch<AppDispatch>();

  const currentAdmissionTime = watch("hospital_admission_date");
  const currentIcuTime = watch("icu_admission_date");

  const handleChangeAdmissionTime = async (
    value: Dayjs,
    field: ControllerRenderProps<
      PatientTreatmentInfo,
      "hospital_admission_date"
    >
  ) => {
    if (value === null) {
      resetField("hospital_admission_date");
      return;
    }

    const currentDischargeTime = getValues("hospital_discharge_date");

    if (
      dayjs(value).isBefore(dayjs(currentDischargeTime)) &&
      (errors.hospital_admission_date || errors.hospital_discharge_date)
    ) {
      clearErrors("hospital_admission_date");
      clearErrors("hospital_discharge_date");
    } else if (
      currentDischargeTime &&
      (dayjs(value).isAfter(dayjs(currentDischargeTime)) ||
        dayjs(value).isSame(dayjs(currentDischargeTime))) &&
      !errors.hospital_admission_date
    ) {
      setError("hospital_admission_date", {
        message: t("Admission time must be before Discharge time"),
      });

      field.onChange(value);
      dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

      return;
    }

    field.onChange(value);
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    if (
      (dayjs(value).isBefore(dayjs(currentDischargeTime)) &&
        data.is_discharged) ||
      !data.is_discharged
    ) {
      await patchPatientDetail(data.id_admission_record, {
        hospital_admission_date: formatDateBeforeSendToServer(value.toString()),
        hospital_discharge_date: formatDateBeforeSendToServer(
          currentDischargeTime.toString()
        ),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleChangeIcuAdmissionTime = async (
    value: Dayjs,
    field: ControllerRenderProps<PatientTreatmentInfo, "icu_admission_date">
  ) => {
    if (value === null) {
      resetField("icu_discharge_date");
      return;
    }

    const currentIcuDischargeTime = getValues("icu_discharge_date");

    if (
      dayjs(value).isBefore(dayjs(currentIcuDischargeTime)) &&
      (errors.icu_admission_date || errors.icu_discharge_date)
    ) {
      clearErrors("icu_admission_date");
      clearErrors("icu_discharge_date");
    } else if (
      currentIcuDischargeTime &&
      (dayjs(value).isAfter(dayjs(currentIcuDischargeTime)) ||
        dayjs(value).isSame(dayjs(currentIcuDischargeTime))) &&
      !errors.icu_admission_date
    ) {
      setError("icu_admission_date", {
        message: t("Icu admission time must be before Icu discharge time"),
      });

      field.onChange(value);
      dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

      return;
    }

    field.onChange(value);
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    if (
      (data.is_discharged &&
        dayjs(value).isBefore(dayjs(currentIcuDischargeTime))) ||
      !data.is_discharged
    ) {
      await patchPatientDetail(data.id_admission_record, {
        icu_admission_date: formatDateBeforeSendToServer(value.toString()),
        icu_discharge_date: formatDateBeforeSendToServer(
          currentIcuDischargeTime.toString()
        ),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleUpdateAutoSaveTime = (value: any, field: any) => {
    field.onChange(value);
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));
  };

  const handleBlurAdmissionRoute = async () => {
    const currentAdmissionRoute = watch("pre_admission_route");

    if (currentAdmissionRoute !== data.pre_admission_route) {
      await patchPatientDetail(data.id_admission_record, {
        pre_admission_route: currentAdmissionRoute,
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleBlurWeight = async (value: string) => {
    const currentWeight = Number(value);

    if (isNaN(currentWeight)) {
      return;
    }

    if (currentWeight <= 0) {
      setError("weight", {
        message: t("Weight must be greater than 0"),
      });

      setPhysicalData({
        ...physicalData,
        weight: currentWeight,
      });

      return;
    }

    setError("weight", {
      message: undefined,
    });

    setPhysicalData({
      ...physicalData,
      weight: currentWeight,
    });

    if (currentWeight !== data.weight && currentWeight > 0) {
      await patchPatientDetail(data.id_admission_record, {
        weight: currentWeight.toString(),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleBlurHeight = async (value: string) => {
    const currentHeight = Number(value);

    if (isNaN(currentHeight)) {
      return;
    }

    if (currentHeight <= 0) {
      setError("height", {
        message: t("Height must be greater than 0"),
      });

      setPhysicalData({
        ...physicalData,
        height: currentHeight,
      });

      return;
    }

    setError("height", {
      message: undefined,
    });

    setPhysicalData({
      ...physicalData,
      height: currentHeight,
    });

    if (currentHeight !== data.height && currentHeight > 0) {
      await patchPatientDetail(data.id_admission_record, {
        height: currentHeight.toString(),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleBlurBsa = async (value: string) => {
    const currentBsa = Number(value);

    if (isNaN(currentBsa)) {
      return;
    }

    if (currentBsa <= 0) {
      setError("bsa", {
        message: t("Bsa must be greater than 0"),
      });

      setPhysicalData({
        ...physicalData,
        bsa: currentBsa,
      });

      return;
    }

    setError("bsa", {
      message: undefined,
    });

    setPhysicalData({
      ...physicalData,
      bsa: currentBsa,
    });

    if (currentBsa !== data.bsa && currentBsa > 0) {
      await patchPatientDetail(data.id_admission_record, {
        bsa: currentBsa.toString(),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleChangeDischargeTime = async (value: any, field: any) => {
    const currentAdmittedDate = watch("hospital_admission_date");

    if (
      dayjs(value).isAfter(dayjs(currentAdmittedDate)) &&
      (errors.hospital_admission_date || errors.hospital_discharge_date)
    ) {
      clearErrors("hospital_admission_date");
      clearErrors("hospital_discharge_date");
    } else if (
      (dayjs(value).isBefore(dayjs(currentAdmittedDate)) ||
        dayjs(value).isSame(dayjs(currentAdmittedDate))) &&
      !errors.hospital_discharge_date
    ) {
      setError("hospital_discharge_date", {
        message: t("Discharge time must be after Admission time"),
      });

      field.onChange(value);
      dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

      return;
    }

    field.onChange(value);
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    await patchPatientDetail(data.id_admission_record, {
      hospital_discharge_date: formatDateBeforeSendToServer(value.toString()),
      hospital_admission_date: formatDateBeforeSendToServer(
        currentAdmittedDate.toString()
      ),
    });

    dispatch(getPatientById(data.id_admission_record));
  };

  const handleBlurDischargeOutcome = async () => {
    const currentDischargedOutcome = watch("hospital_discharge_outcome");

    if (currentDischargedOutcome !== data.hospital_discharge_outcome) {
      await patchPatientDetail(data.id_admission_record, {
        hospital_discharge_outcome: currentDischargedOutcome.toString(),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  const handleChangeIcuDischargeDate = async (value: any, field: any) => {
    const currentIcuAdmittedDate = watch("icu_admission_date");

    if (
      dayjs(value).isAfter(dayjs(currentIcuAdmittedDate)) &&
      (errors.icu_admission_date || errors.icu_discharge_date)
    ) {
      clearErrors("icu_admission_date");
      clearErrors("icu_discharge_date");
    } else if (
      (dayjs(value).isBefore(dayjs(currentIcuAdmittedDate)) ||
        dayjs(value).isSame(dayjs(currentIcuAdmittedDate))) &&
      !errors.icu_discharge_date
    ) {
      setError("icu_discharge_date", {
        message: t("ICU discharge time must be after ICU admission time"),
      });

      field.onChange(value);
      dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

      return;
    }

    field.onChange(value);
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));

    await patchPatientDetail(data.id_admission_record, {
      icu_discharge_date: formatDateBeforeSendToServer(value.toString()),
      icu_admission_date: formatDateBeforeSendToServer(
        currentIcuAdmittedDate.toString()
      ),
    });

    dispatch(getPatientById(data.id_admission_record));
  };

  const handleBlurIcuDischargeOutcome = async () => {
    const currentIcuDischargeOutcome = watch("icu_discharge_outcome");
    if (currentIcuDischargeOutcome !== data.icu_discharge_outcome) {
      await patchPatientDetail(data.id_admission_record, {
        icu_discharge_outcome: currentIcuDischargeOutcome.toString(),
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  return (
    <form className={styles.patientTreatmentForm}>
      <Flex gap={30} className="patient-treatment-form-container">
        <div>
          <div>
            <div className={styles.admissionTimeLabel}>
              {t("Admission time")}
            </div>
            <Controller
              name="hospital_admission_date"
              control={control}
              render={({ field }) => {
                return (
                  <IcuDateInput
                    width={522}
                    height={42}
                    format={DATE_FORMAT.YYYYMMDDHHmm}
                    showTime={{ format: DATE_FORMAT.HHmm }}
                    maxDate={dayjs(new Date())}
                    allowClear={false}
                    placeholder=""
                    customRootClassName="admission-time-input"
                    {...field}
                    onChange={(value) =>
                      handleChangeAdmissionTime(value, field)
                    }
                  />
                );
              }}
            />
            {errors.hospital_admission_date?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.hospital_admission_date?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.admissionRouteLabel}>
              {t("Admitted from")}
            </div>
            <Controller
              name="pre_admission_route"
              control={control}
              render={({ field }) => {
                return (
                  <IcuTextInput
                    width={522}
                    height={42}
                    id="admitted-from-input"
                    customRootClassName="admission-route-input"
                    {...field}
                    onChange={(value) => handleUpdateAutoSaveTime(value, field)}
                    onBlur={handleBlurAdmissionRoute}
                    onPressEnter={(e: any) => {
                      if (e.keyCode === 13) {
                        document.getElementById("admitted-from-input")?.blur();
                      }
                    }}
                  />
                );
              }}
            />
          </div>
          <div>
            <div className={styles.icuAdmissionTimeLabel}>
              {t("ICU admission time")}
            </div>
            <Controller
              name="icu_admission_date"
              control={control}
              render={({ field }) => {
                return (
                  <IcuDateInput
                    width={522}
                    height={42}
                    format={DATE_FORMAT.YYYYMMDDHHmm}
                    showTime={{ format: DATE_FORMAT.HHmm }}
                    maxDate={dayjs(new Date())}
                    allowClear={false}
                    placeholder=""
                    customRootClassName="icu-admission-time-input"
                    {...field}
                    onChange={(value) =>
                      handleChangeIcuAdmissionTime(value, field)
                    }
                  />
                );
              }}
            />
            {errors.icu_admission_date?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.icu_admission_date?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.weightLabel}>{t("Weight")}</div>
            <div className="patient-detail-weight-input">
              <InputNumber
                value={physicalData.weight}
                className="bg-light border-none patient-detail-weight-input"
                controls={false}
                id="weight-input"
                onChange={() => {
                  dispatch(
                    setUpdateInfoTime(
                      dayjs(new Date()).format(DATE_FORMAT.HHmm)
                    )
                  );
                }}
                onBlur={(e) => handleBlurWeight(e.target.value)}
                onPressEnter={(e: any) => {
                  document.getElementById("weight-input")?.blur();
                }}
              />
            </div>
            {errors.weight?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.weight?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.heightLabel}>{t("Height")}</div>
            <div className="patient-detail-height-input">
              <InputNumber
                value={physicalData.height}
                className="bg-light border-none patient-detail-height-input"
                controls={false}
                id="height-input"
                onChange={() => {
                  dispatch(
                    setUpdateInfoTime(
                      dayjs(new Date()).format(DATE_FORMAT.HHmm)
                    )
                  );
                }}
                onBlur={(e) => handleBlurHeight(e.target.value)}
                onPressEnter={(e: any) => {
                  document.getElementById("height-input")?.blur();
                }}
              />
            </div>
            {errors.height?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.height?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.bsaLabel}>{t("BSA")}</div>
            <div className="patient-detail-bsa-input">
              <InputNumber
                value={physicalData.bsa}
                className="bg-light border-none patient-detail-bsa-num-input"
                controls={false}
                id="bsa-input"
                onChange={() => {
                  dispatch(
                    setUpdateInfoTime(
                      dayjs(new Date()).format(DATE_FORMAT.HHmm)
                    )
                  );
                }}
                onBlur={(e) => handleBlurBsa(e.target.value)}
                onPressEnter={() => {
                  document.getElementById("bsa-input")?.blur();
                }}
              />
              <span className="bsa-unit">
                m<sup>2</sup>
              </span>
            </div>
            {errors.bsa?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.bsa?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div>
          <div>
            <div className={styles.dischargeTimeLabel}>
              {t("Discharge time")}
            </div>
            <Controller
              name="hospital_discharge_date"
              control={control}
              render={({ field }) => {
                return (
                  <IcuDateInput
                    width={522}
                    height={42}
                    format={DATE_FORMAT.YYYYMMDDHHmm}
                    showTime={{ format: DATE_FORMAT.HHmm }}
                    minDate={dayjs(currentAdmissionTime)}
                    disabled={!data.is_discharged}
                    placeholder=""
                    allowClear={false}
                    customRootClassName="discharge-time-input"
                    {...field}
                    onChange={(value) =>
                      handleChangeDischargeTime(value, field)
                    }
                  />
                );
              }}
            />
            {errors.hospital_discharge_date?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.hospital_discharge_date?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.postDischargeConditionLabel}>
              {t("Outcome at the hospital discharge")}
            </div>
            <Controller
              name="hospital_discharge_outcome"
              control={control}
              render={({ field }) => {
                return (
                  <IcuTextInput
                    width={522}
                    height={42}
                    disabled={!data.is_discharged}
                    id="outcome-hospital-discharge-input"
                    customRootClassName="post-discharge-condition-input"
                    {...field}
                    onChange={(value) => handleUpdateAutoSaveTime(value, field)}
                    onBlur={handleBlurDischargeOutcome}
                    onPressEnter={(e: any) => {
                      if (e.keyCode === 13) {
                        document
                          .getElementById("outcome-hospital-discharge-input")
                          ?.blur();
                      }
                    }}
                  />
                );
              }}
            />
          </div>
          <div>
            <div className={styles.icuDischargeTimeLabel}>
              {t("ICU discharge time")}
            </div>
            <Controller
              name="icu_discharge_date"
              control={control}
              render={({ field }) => {
                return (
                  <IcuDateInput
                    width={522}
                    height={42}
                    format={DATE_FORMAT.YYYYMMDDHHmm}
                    showTime={{ format: DATE_FORMAT.HHmm }}
                    minDate={dayjs(currentIcuTime)}
                    disabled={!data.is_discharged}
                    placeholder=""
                    allowClear={false}
                    customRootClassName="icu-discharge-time-input"
                    {...field}
                    onChange={(value) =>
                      handleChangeIcuDischargeDate(value, field)
                    }
                  />
                );
              }}
            />
            {errors.icu_discharge_date?.message ? (
              <ErrorMessage extraClassName="mt-6">
                {t(errors.icu_discharge_date?.message)}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div className={styles.icuPostDischargeConditionLabel}>
              {t("Outcome at the ICU discharge")}
            </div>
            <Controller
              name="icu_discharge_outcome"
              control={control}
              render={({ field }) => {
                return (
                  <IcuTextInput
                    width={522}
                    height={42}
                    disabled={!data.is_discharged}
                    customRootClassName="icu-post-discharge-condition-input"
                    id="outcome-icu-discharge-input"
                    {...field}
                    onChange={(value) => handleUpdateAutoSaveTime(value, field)}
                    onBlur={handleBlurIcuDischargeOutcome}
                    onPressEnter={(e: any) => {
                      if (e.keyCode === 13) {
                        document
                          .getElementById("outcome-icu-discharge-input")
                          ?.blur();
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
      </Flex>
    </form>
  );
};

export default PatientDetailInfo;
