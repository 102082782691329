import { Select, Tag } from "antd";
import React, { ForwardedRef, forwardRef } from "react";
import type { RefSelectProps, SelectProps } from "antd";
import "./IcuSelect.scss";
import { BACKGROUND_COLOR_VALUE } from "../../../constants/common/color.constant";
import { FieldValues, Path } from "react-hook-form";
import { CompoundIcuSelectProps } from "../../../types/common/IcuSelect.model";
import { preventPressEnter } from "../../../utils/helpers";

type TagRender = SelectProps["tagRender"];

const tagRender: TagRender = (props) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      color={BACKGROUND_COLOR_VALUE.secondary}
      onMouseDown={onPreventMouseDown}
      closable={props.closable}
      onClose={props.onClose}
      className="select-tag"
    >
      {props.label}
    </Tag>
  );
};

function IcuSelectInner<T extends FieldValues, K extends Path<T>>(
  {
    width,
    height,
    mode,
    onChange,
    options,
    defaultValue,
    extraClassName = "",
    maxCount,
    allowClear = true,
    ...fieldProps
  }: CompoundIcuSelectProps<T, K>,
  ref: ForwardedRef<RefSelectProps>
) {
  return (
    <Select
      mode={mode}
      style={{ width, height }}
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
      className={`icu-select ${extraClassName}`}
      maxTagCount="responsive"
      tagRender={tagRender}
      maxCount={maxCount}
      optionFilterProp="label"
      onKeyDown={preventPressEnter}
      allowClear={allowClear}
      {...fieldProps}
      ref={ref}
    />
  );
}

export const IcuSelect = forwardRef(IcuSelectInner);
