import { useEffect, useState } from "react";
import { ConfigProvider, Table } from "antd";
import { VitalSignTableRow } from "../../../types/vital-sign/VitalSignTableRow.model";
import { VitalSignEditableCell } from "./editable-cell/VitalSignEditableCell";
import "./VitalSignTable.scss";
import { isEqual } from "lodash";
import { VITAL_SIGN_TABLE_CLASSNAME } from "../../../constants/vital-sign/VitalSignTable.constant";
import { generateRandomNumber } from "../../../utils/helpers";

interface VitalSignTableProps {
  data: VitalSignTableRow[];
  colHeaders: string[];
  onUpdateCell?: (
    id: number,
    typeId: number,
    value: number | null,
    createdAt: string | number
  ) => void;
}

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const VitalSignTable = ({
  data,
  colHeaders,
  onUpdateCell,
}: VitalSignTableProps) => {
  const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    if (!isEqual(data, dataSource)) {
      setDataSource(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dynamicColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
  })[] = colHeaders.map((header) => {
    return {
      align: "center",
      dataIndex: header,
      editable: true,
      width: "80px",
      id: `${header}Id`,
      render(value) {
        return <div>{value ?? "-"}</div>;
      },
    };
  });

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
    id?: string;
  })[] = [
    {
      dataIndex: "name",
      align: "center",
      className: "vital-sign-table-first-col",
      width: "108px",
      fixed: "left",
      render: (_, record) => {
        return <div>{record.name}</div>;
      },
    },
    ...dynamicColumns,
  ];

  const handleUpdateData = (
    id: number,
    typeId: number,
    value: number | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => {
    const updatedDataSource = data.map((item) => {
      if (item.id === newRowData.id) {
        return newRowData;
      }

      return item;
    });

    setDataSource(updatedDataSource);

    onUpdateCell?.(
      id,
      typeId,
      typeof value === "number" ? value : null,
      createdAt
    );
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        id: col.id ? record[col.id] : undefined,
        typeId: record.typeId,
        handleUpdateData,
      }),
    };
  });

  const components = {
    body: {
      cell: VitalSignEditableCell,
    },
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <Table
        dataSource={dataSource}
        pagination={false}
        bordered
        className={VITAL_SIGN_TABLE_CLASSNAME}
        columns={columns as ColumnTypes}
        components={components}
        rowHoverable={false}
        showHeader={false}
        rowKey={(record) => `${record.typeId}-${generateRandomNumber()}`}
        scroll={{
          x: 109,
        }}
      />
    </ConfigProvider>
  );
};

export default VitalSignTable;
