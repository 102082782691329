import { Department } from "../../../types/patient-list/Department.model";
import { Doctor } from "../../../types/patient-list/Doctor.model";

export function filterFreeDoctorOptions(
  allDepartments: Department[],
  currentSelectedDoctorIds: string[]
) {
  const allDepartmentDoctorIds: string[] = [];

  const freeDoctorOptions: {
    label: string;
    value: string;
  }[] = [];

  allDepartments.forEach(({ doctors }) => {
    doctors?.forEach((item) => allDepartmentDoctorIds.push(item.id.toString()));
  });

  currentSelectedDoctorIds.forEach((doctorId) => {
    if (!allDepartmentDoctorIds.includes(doctorId)) {
      freeDoctorOptions.push({
        label: doctorId,
        value: doctorId,
      });
    }
  });

  return freeDoctorOptions;
}

export function getNewDoctorSelectOptions(
  allDepartments: Department[],
  selectedDepartmentIds: string[],
  currentSelectedDoctorIds: string[],
  allFreeDoctors?: Doctor[]
) {
  const selectedDepartments = allDepartments.filter((department) =>
    selectedDepartmentIds.includes(department.id.toString())
  );

  let newDoctorOptions: {
    label: string;
    value: string;
  }[] = [];

  selectedDepartments.forEach(({ doctors }) => {
    doctors?.forEach((item) => {
      newDoctorOptions.push({
        label: item.name,
        value: item.id.toString(),
      });
    });
  });

  if (currentSelectedDoctorIds.length > 0) {
    let freeDoctorOptions = filterFreeDoctorOptions(
      allDepartments,
      currentSelectedDoctorIds
    );

    if (allFreeDoctors?.length && allFreeDoctors?.length > 0) {
      freeDoctorOptions = allFreeDoctors
        .filter(
          (freeDoctor) =>
            freeDoctorOptions.findIndex(
              (option) => option.value === freeDoctor.id.toString()
            ) !== -1
        )
        .map((item) => ({
          label: item.name,
          value: item.id.toString(),
        }));
    }

    newDoctorOptions = newDoctorOptions.concat(freeDoctorOptions);
  }

  return newDoctorOptions;
}
