import dayjs from "dayjs";
import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import { TimeSeriesExamTableRow } from "../../types/time-series-exam-table/TimeSeriesExamTableRow.model";
import { TimeSeriesExamResultReadResponse } from "../../types/time-series-exam-table/TimeSeriesExamResultReadResponse.model";

export const formatTimeSeriesExamData = (
  data: TimeSeriesExamResultReadResponse[]
) => {
  if (data.length > 0) {
    data.sort((a, b) => a.id - b.id);
    let tableData: TimeSeriesExamTableRow[] = [];
    const columnHeaderSet = new Set<string>();

    data.forEach((item) => {
      let rowData = {
        resultId: item.id,
        name: item.name,
        unit: item.unit,
        reference: `${item.reference_min ?? ""}-${item.reference_max ?? ""}`,
        minVal: item.reference_min,
        maxVal: item.reference_max,
      };

      rowData = item.result.reduce((prev, current) => {
        columnHeaderSet.add(
          dayjs(current.created_at).format(DATE_FORMAT.YYYYMMDDHHmm)
        );
        return {
          ...prev,
          [`${dayjs(current.created_at).format(DATE_FORMAT.YYYYMMDDHHmm)}`]:
            current.value,
          [`${dayjs(current.created_at).format(DATE_FORMAT.YYYYMMDDHHmm)}Id`]:
            current.id,
        };
      }, rowData);

      tableData.push(rowData);
    });

    const colHeaders = Array.from(columnHeaderSet);

    colHeaders.sort((a, b) => b.localeCompare(a));

    return {
      colHeaders,
      tableData,
    };
  }

  return {
    colHeaders: [],
    tableData: [],
  };
};
