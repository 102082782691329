export const DOCUMENT_HEADER = {
  patientList: {
    title: "Patient List",
    icon: "/fav-icon/patient-list.svg",
  },
  patientDetail: {
    title: "Patient Detail",
    icon: "/fav-icon/patient-detail.svg",
  },
  examResult: {
    title: "Examination Result",
    icon: "/fav-icon/exam-result.svg",
  },
  timeSeries: {
    title: "Time Series Examination Result",
    icon: "/fav-icon/time-series.svg",
  },
  vitalSign: {
    title: "Vital Signs & Ventilator Settings",
    icon: "/fav-icon/vital-sign.svg",
  },
};
