import { InputNumber } from "antd";
import React, { ForwardedRef, forwardRef } from "react";
import "./IcuNumberInput.scss";
import { FieldValues, Path } from "react-hook-form";
import { CompoundIcuNumberInputProps } from "../../../types/common/IcuNumberInput.model";
import { preventPressEnter } from "../../../utils/helpers";

function IcuNumberInputInner<T extends FieldValues, K extends Path<T>>(
  {
    defaultValue,
    min,
    max,
    id,
    width,
    height,
    onPressEnter = preventPressEnter,
    ...fieldProps
  }: CompoundIcuNumberInputProps<T, K>,
  ref: ForwardedRef<HTMLInputElement>
) {
  return (
    <InputNumber
      style={{
        width,
        height,
      }}
      defaultValue={defaultValue}
      min={min}
      max={max}
      className="bg-light border-none icu-number-input"
      id={id}
      controls={false}
      onKeyDown={onPressEnter}
      {...fieldProps}
      ref={ref}
    ></InputNumber>
  );
}

export const IcuNumberInput = forwardRef(IcuNumberInputInner);
