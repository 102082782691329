import { DatePicker } from "antd";
import React, { ForwardedRef, forwardRef } from "react";
import "./IcuDateInput.scss";
import { FieldValues, Path } from "react-hook-form";
import { CompoundIcuDateInputProps } from "../../../types/common/IcuDateInput.model";
import type { PickerRef } from "rc-picker";
import { preventPressEnter } from "../../../utils/helpers";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";

function IcuDateInputInner<T extends FieldValues, K extends Path<T>>(
  {
    id,
    width,
    height,
    placeholder,
    format = DATE_FORMAT.YYYYMMDD,
    showTime = false,
    minDate,
    maxDate,
    allowClear = true,
    customRootClassName = "",
    ...fieldProps
  }: CompoundIcuDateInputProps<T, K>,
  ref: ForwardedRef<PickerRef>
) {
  return (
    <DatePicker
      style={{
        width,
        height,
      }}
      id={id}
      placeholder={placeholder}
      className="bg-light border-none icu-date-input"
      format={format}
      onKeyDown={preventPressEnter}
      showTime={showTime}
      minDate={minDate}
      maxDate={maxDate}
      allowClear={allowClear}
      rootClassName={customRootClassName}
      {...fieldProps}
      ref={ref}
    />
  );
}

export const IcuDateInput = forwardRef(IcuDateInputInner);
