import { Modal } from "antd";
import React from "react";
import IcuButton from "../button/IcuButton";
import styles from "./IcuErrorModal.module.scss";
import "./IcuErrorModal.scss";

interface ErrorModalProps {
  title: string;
  content: string;
  isOpen: boolean;
  handleCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  extraClassName?: string;
}

const IcuErrorModal = ({
  extraClassName,
  handleCancel,
  isOpen,
  title,
  content,
}: ErrorModalProps) => {
  return (
    <Modal
      title={<div className={styles.errorTitle}>{title}</div>}
      open={isOpen}
      onCancel={handleCancel}
      footer={
        <IcuButton onClick={handleCancel} width={92} height={44}>
          Confirm
        </IcuButton>
      }
      width={578}
      centered
      className={`${extraClassName} icu-error-modal`}
      maskClosable={false}
    >
      <div className={styles.errorContent}>{content}</div>
    </Modal>
  );
};

export default IcuErrorModal;
