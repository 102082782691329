export const PATIENT_REGISTER_ERROR_MESSAGE = {
  duplicateId: {
    params: "(patient_id)",
    content: "Patient (patient_id) has already existed",
  },
};

export const ERROR_CODE = {
  unauthorised: 401,
};
