import {
  API_CREATE_VENTILATOR_SETTING_OCR,
  API_GET_VENTILATOR_SETTING,
  API_PUT_VENTILATOR_SETTING,
  API_UPDATE_VENTILATOR_SETTING,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { VentilatorReadResponse } from "../../types/vital-sign/VentilatorReadResponse.model";

export const getAllVentilatorSettings = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<VentilatorReadResponse[]>> => {
  return networkAdapter.get(
    `${API_GET_VENTILATOR_SETTING}/${admissionRecordId}`,
    {
      date,
    }
  );
};

export const updateVentilatorSetting = (id: number, value: string | null) => {
  return networkAdapter.patch(API_UPDATE_VENTILATOR_SETTING, {
    id,
    value,
  });
};

export const putVentilatorSetting = (
  admissionRecordId: number,
  typeId: number,
  value: string | null,
  createdAt: string
) => {
  return networkAdapter.put(API_PUT_VENTILATOR_SETTING, {
    id_admission_record: admissionRecordId,
    id_type: typeId,
    value: value,
    created_at: createdAt,
  });
};

export const createVentilatorSettingOCR = (
  admissionRecordId: string,
  image: string
) => {
  return networkAdapter.post(API_CREATE_VENTILATOR_SETTING_OCR, {
    id_admission_record: admissionRecordId,
    image,
  });
};
