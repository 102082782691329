import React, { Suspense, useEffect } from "react";
import { LoadingOverlay } from "./components/common/loading/LoadingOverlay";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import "./i18n";
import { checkLogin } from "./utils/helpers";
import { HOME_PATH, LOGIN_PATH, PATIENT_LIST_PATH } from "./configs/routerPath";

function App() {
  useEffect(() => {
    if (
      !checkLogin() &&
      ![LOGIN_PATH, HOME_PATH].includes(window.location.pathname)
    ) {
      window.location.href = LOGIN_PATH;
      localStorage.clear();
    } else if (
      checkLogin() &&
      [LOGIN_PATH, HOME_PATH].includes(window.location.pathname)
    ) {
      window.location.href = PATIENT_LIST_PATH;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkLogin()]);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Manrope, Poppins, sans-serif",
        },
      }}
    >
      <Suspense fallback={<LoadingOverlay />}>
        <Provider store={store}>
          <RouterProvider router={router} />
        </Provider>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
