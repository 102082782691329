import { Flex, Tag, Tooltip } from "antd";
import React from "react";
import styles from "./TreatmentInfo.module.scss";
import plusRectangle from "../../../assets/icons/plus-rectangle.svg";
import star from "../../../assets/icons/star.svg";
import userPolygon from "../../../assets/icons/user-polygon.svg";
import bed from "../../../assets/icons/bed.svg";
import apartment from "../../../assets/icons/apartment.svg";
import browser from "../../../assets/icons/browser.svg";
import { BACKGROUND_COLOR_VALUE } from "../../../constants/common/color.constant";
import "./TreatmentInfo.scss";
import { TreatmentInfoEntity } from "../../../types/patient-list/TreatmentInfoEntity.model";
import { useTranslation } from "react-i18next";

interface TreatmentInfoProps {
  data: TreatmentInfoEntity;
}

const TreatmentInfo = ({ data }: TreatmentInfoProps) => {
  const { t } = useTranslation();
  return (
    <Flex className={styles.treatmentInfo} gap={32} align="center">
      <Flex>
        <Flex vertical gap={10}>
          <img src={bed} className={styles.icon} alt="room icon" />
          <img src={apartment} className={styles.icon} alt="ward icon" />
        </Flex>
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("Room")}</div>
          <div>{t("Ward")}</div>
        </Flex>
        <Flex vertical className={styles.valueColumn} gap={10}>
          <Tooltip title={data.room}>
            <div className={styles.roomValue}>: {data.room}</div>
          </Tooltip>
          <Tooltip title={data.ward} placement="topLeft">
            <div className={styles.wardValue}>: {data.ward}</div>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex>
        <Flex
          vertical
          gap={10}
          className={styles.dateIconSection}
          justify="start"
        >
          <img
            src={browser}
            alt="admission date icon"
            className={styles.admissionDateIcon}
          />
          <img
            src={browser}
            alt="Date of discharge icon"
            className={!data.dischargeDate ? "visible-hidden" : ""}
          />
        </Flex>
        <Flex vertical gap={10} className={styles.label}>
          <div>{t("Date of admission")}</div>

          <div className={!data.dischargeDate ? "visible-hidden" : ""}>
            {t("Date of discharge")}
          </div>
        </Flex>
        <Flex vertical gap={10} className={styles.valueColumn}>
          <Tooltip title={data.admissionDate}>
            <div className={styles.admissionDateValue}>
              : {data.admissionDate ?? ""}
            </div>
          </Tooltip>

          <Tooltip
            title={data.admissionDate}
            className={!data.dischargeDate ? "visible-hidden" : ""}
          >
            <div className={styles.admissionDateValue}>
              : {data.dischargeDate}
            </div>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex>
        <Flex vertical gap={10}>
          <img src={star} className={styles.icon} alt="star icon" />

          <img src={plusRectangle} className={styles.icon} alt="illness icon" />
        </Flex>
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("Length of hospital stay")}</div>

          <div>{t("Diagnosis")}</div>
        </Flex>
        <Flex vertical gap={10} className={styles.valueColumn}>
          <Tooltip title={data.dayStay}>
            <div className={styles.dayStayValue}>
              : {data.dayStay} {data.dayStay > 1 ? "days" : "day"}
            </div>
          </Tooltip>
          <Tooltip title={data.diagnosis} placement="topLeft">
            <div className={styles.illnessValue}>: {data.diagnosis}</div>
          </Tooltip>
        </Flex>
      </Flex>

      <Flex>
        <Flex vertical gap={20}>
          <img
            src={userPolygon}
            className={`${styles.icon} ${styles.doctorIcon}`}
            alt="doctor icon"
          />
          <img
            src={browser}
            className={`${styles.icon} ${styles.departmentIcon}`}
            alt="department icon"
          />
        </Flex>
        <Flex vertical gap={20}>
          <div className={styles.doctorLabel}>{t("Doctor")}</div>
          <div className={styles.departmentLabel}>{t("Department")}</div>
        </Flex>
        <Flex vertical gap={20}>
          <div>
            <span>:</span>
            <span className={styles.tagList}>
              {data.doctor.map((doctor) => (
                <Tag
                  className="doctor-tag"
                  color={BACKGROUND_COLOR_VALUE.secondary}
                  key={doctor.id}
                >
                  {doctor.name}
                </Tag>
              ))}
            </span>
          </div>
          <div>
            <span>:</span>
            <span className={styles.tagList}>
              {data.department.map((department) => (
                <Tag
                  className="department-tag"
                  color={BACKGROUND_COLOR_VALUE.secondary}
                  key={department.id}
                >
                  {department.name}
                </Tag>
              ))}
            </span>
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TreatmentInfo;
