import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import styles from "./PatientSearchModal.module.scss";
import { Flex, Modal, Radio } from "antd";
import IcuButton from "../../common/button/IcuButton";
import "./PatientSearchModal.scss";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IcuTextInput } from "../../common/text-input/IcuTextInput";
import { IcuNumberInput } from "../../common/number-input/IcuNumberInput";
import IcuRadio from "../../common/radio/IcuRadio";
import { IcuSelect } from "../../common/select/IcuSelect";
import { PatientSearchForm } from "../../../types/patient-list/PatientSearchForm.model";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import { MedicalDataReadResponse } from "../../../types/patient-list/MedicalDataReadResponse.model";
import { PATIENT_SEX_VALUE } from "../../../constants/patient-list/patientList.constant";
import { DefaultOptionType } from "antd/es/select";
import { PatientListTabData } from "../../../types/patient-list/PatientListTabData.model";
import { TabFilterPatientList } from "../../../types/patient-list/TabFilter.model";
import {
  filterFreeDoctorOptions,
  getNewDoctorSelectOptions,
} from "../../../utils/patient-detail/patient-register-modal/PatientRegisterModal.helper";
import { IcuDateInput } from "../../common/date-input/IcuDateInput";
import dayjs, { Dayjs } from "dayjs";

interface PatientSearchModalProps {
  initialValues: PatientSearchForm;
  setTabData: React.Dispatch<SetStateAction<PatientListTabData>>;
  medicalData: MedicalDataReadResponse;
  onSubmitSearchPatientForm: (formValues: PatientSearchForm) => void;
  activeTab: TabFilterPatientList;
}

const PatientSearchModal = ({
  initialValues,
  setTabData,
  medicalData,
  onSubmitSearchPatientForm,
  activeTab,
}: PatientSearchModalProps) => {
  const { t } = useTranslation();

  const wardOptions = useMemo(() => {
    return medicalData.ward.map((ward) => ({
      label: ward.name,
      value: ward.id,
    }));
  }, [medicalData.ward]);

  const [roomOptions, setRoomOptions] = useState<DefaultOptionType[]>();

  const [doctorOptions, setDoctorOptions] = useState<DefaultOptionType[]>();

  const departmentOptions = useMemo(() => {
    return medicalData.department.map((ward) => ({
      label: ward.name,
      value: ward.id.toString(),
    }));
  }, [medicalData.department]);

  const {
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<PatientSearchForm>({
    defaultValues: initialValues,
  });

  useEffect(() => {
    const newRoomOptions =
      medicalData.ward
        .find((ward) => ward.id === initialValues.ward)
        ?.rooms.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];

    const currentSelectedDoctorIds = getValues("doctor") ?? [];

    const initDoctorOptions = getNewDoctorSelectOptions(
      medicalData.department,
      initialValues.department ?? [],
      currentSelectedDoctorIds
    );

    setRoomOptions(newRoomOptions);
    setDoctorOptions(initDoctorOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResetForm = () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        searchValues: {
          id: undefined,
          age: undefined,
          department: undefined,
          room: undefined,
          sex: undefined,
          ward: undefined,
          doctor: undefined,
          name: undefined,
          startDate: undefined,
          endDate: undefined,
        },
      },
    }));

    setValue("id", undefined);
    setValue("age", undefined);
    setValue("department", undefined);
    setValue("room", undefined);
    setValue("sex", undefined);
    setValue("ward", undefined);
    setValue("doctor", undefined);
    setValue("name", undefined);
    setValue("startDate", undefined);
    setValue("endDate", undefined);

    clearErrors();

    setRoomOptions(undefined);
    setDoctorOptions(undefined);
  };

  const handleFormSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    const {
      age,
      department,
      id,
      room,
      sex,
      ward,
      doctor,
      name,
      startDate,
      endDate,
    } = getValues();

    if (age !== undefined && age !== null && age < 0) {
      setError("age", {
        message: t("Please enter a valid number for the age"),
      });

      return;
    }

    if (errors.startDate) {
      return;
    }

    const formValues: PatientSearchForm = {
      age,
      department,
      id,
      room,
      sex,
      ward,
      doctor,
      name,
      startDate,
      endDate,
    };

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        searchValues: formValues,
        isShowSearchModal: false,
      },
    }));

    onSubmitSearchPatientForm(formValues);
  };

  const onAgeInputChange = (
    value: number,
    fieldHelper: ControllerRenderProps<PatientSearchForm, "age">
  ) => {
    if (value === null || value === undefined || value >= 0) {
      clearErrors("age");
    }

    fieldHelper.onChange(value);
  };

  const onCancel = () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowSearchModal: false,
      },
    }));
  };

  const onWardChange = (
    value: number,
    fieldHelper: ControllerRenderProps<PatientSearchForm, "ward">
  ) => {
    const newRoomOptions =
      medicalData.ward
        .find((ward) => ward.id === value)
        ?.rooms.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];

    setRoomOptions(newRoomOptions);
    setValue("room", undefined);

    fieldHelper.onChange(value);
  };

  const onDepartmentChange = async (
    value: string[],
    fieldHelper: ControllerRenderProps<PatientSearchForm, "department">
  ) => {
    if (value.length > 0) {
      const currentSelectedDoctorIds = getValues("doctor") ?? [];

      const newDoctorOptions = getNewDoctorSelectOptions(
        medicalData.department,
        value,
        currentSelectedDoctorIds
      );

      if (currentSelectedDoctorIds.length > 0 && newDoctorOptions.length) {
        const newSelectedDoctor = newDoctorOptions
          .filter((option) => currentSelectedDoctorIds.includes(option.value))
          .map((option) => option.value);

        setValue("doctor", newSelectedDoctor);
      }

      setDoctorOptions(newDoctorOptions);
    } else {
      let currentSelectedDoctors = getValues("doctor") ?? [];

      if (currentSelectedDoctors.length > 0) {
        const freeDoctorOptions = filterFreeDoctorOptions(
          medicalData.department,
          currentSelectedDoctors
        );

        currentSelectedDoctors = freeDoctorOptions.map((item) => item.value);

        setValue("doctor", currentSelectedDoctors);
        setDoctorOptions(freeDoctorOptions);
      } else {
        setValue("doctor", []);
        setDoctorOptions(undefined);
      }
    }

    fieldHelper.onChange(value);
  };

  const onStartDateInputChange = (
    startDate: Dayjs,
    fieldHelper: ControllerRenderProps<PatientSearchForm, "startDate">
  ) => {
    if (
      startDate === null ||
      startDate === undefined ||
      watch("endDate") === null ||
      startDate.isBefore(dayjs(watch("endDate"))) ||
      startDate.isSame(dayjs(watch("endDate")))
    ) {
      clearErrors("startDate");
    } else if (
      startDate &&
      watch("endDate") &&
      startDate.isAfter(dayjs(watch("endDate")))
    ) {
      setError("startDate", {
        message: t("The start date must be before the end date"),
      });
    }

    fieldHelper.onChange(startDate);
  };

  const onEndDateInputChange = (
    endDate: Dayjs,
    fieldHelper: ControllerRenderProps<PatientSearchForm, "endDate">
  ) => {
    if (
      endDate === null ||
      endDate === undefined ||
      watch("startDate") === null ||
      endDate.isAfter(dayjs(watch("startDate"))) ||
      endDate.isSame(dayjs(watch("startDate")))
    ) {
      clearErrors("startDate");
    } else if (
      endDate &&
      watch("startDate") &&
      endDate.isBefore(dayjs(watch("startDate")))
    ) {
      setError("startDate", {
        message: t("The start date must be before the end date"),
      });
    }

    fieldHelper.onChange(endDate);
  };

  return (
    <Modal
      title={
        <div className={styles.patientSearchTitle}>{t("Patient search")}</div>
      }
      open={true}
      onCancel={onCancel}
      footer={
        <Flex justify="flex-end" gap={10}>
          <IcuButton
            onClick={(e) => onResetForm()}
            width={92}
            height={44}
            backgroundColor="light"
            color="grey-3"
            extraClassName="patient-search-reset-btn"
          >
            {t("Reset")}
          </IcuButton>
          <IcuButton
            onClick={handleFormSubmit}
            width={92}
            height={44}
            htmlType="submit"
            formId="patient-search-form"
          >
            {t("Confirm")}
          </IcuButton>
        </Flex>
      }
      width={751}
      centered
      className="patient-search-modal"
      maskClosable={false}
    >
      <form className={styles.patientSearchForm} id="patient-search-form">
        <Flex vertical align="center">
          <div className={styles.formSection}>
            <div>
              <div className={styles.userNameLabel}>
                <span>{t("ID")} </span>
              </div>
              <Controller
                name="id"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuTextInput
                      width={691}
                      height={42}
                      {...field}
                      value={watch("id")}
                    />
                  );
                }}
              />
            </div>

            <div>
              <div className={styles.nameLabel}>
                <span>{t("Name")} </span>
              </div>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuTextInput
                      width={691}
                      height={42}
                      {...field}
                      value={watch("name")}
                    />
                  );
                }}
              />
            </div>

            <div>
              <div className={styles.sexLabel}>
                <span>{t("Sex")} </span>
              </div>
              <Controller
                name="sex"
                control={control}
                render={({ field }) => {
                  return (
                    <Radio.Group {...field} value={watch("sex")}>
                      <IcuRadio value={PATIENT_SEX_VALUE.male}>
                        {t("Male")}
                      </IcuRadio>
                      <IcuRadio value={PATIENT_SEX_VALUE.female}>
                        {t("Female")}
                      </IcuRadio>
                      <IcuRadio value={PATIENT_SEX_VALUE.other}>
                        {t("Other")}
                      </IcuRadio>
                    </Radio.Group>
                  );
                }}
              />
            </div>

            <div>
              <div className={styles.ageLabel}>
                <span>{t("Age")} </span>
              </div>
              <Controller
                name="age"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuNumberInput
                      width={691}
                      height={42}
                      {...field}
                      onChange={(value) => onAgeInputChange(value, field)}
                      value={watch("age")}
                    />
                  );
                }}
              />
              {errors.age?.message && (
                <ErrorMessage extraClassName="age-error-message">
                  {errors.age?.message}
                </ErrorMessage>
              )}
            </div>

            <div>
              <div className={styles.wardLabel}>
                <span>{t("Ward")} </span>
              </div>
              <Controller
                name="ward"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuSelect
                      width={691}
                      height={42}
                      options={wardOptions}
                      {...field}
                      value={watch("ward")}
                      onChange={(value) => onWardChange(value, field)}
                    />
                  );
                }}
              />
            </div>
            <div>
              <div className={styles.roomLabel}>
                <span>{t("Room")} </span>
              </div>
              <Controller
                name="room"
                control={control}
                render={({ field }) => {
                  return (
                    <Controller
                      name="room"
                      control={control}
                      render={({ field }) => {
                        return (
                          <IcuSelect
                            width={691}
                            height={42}
                            options={roomOptions}
                            {...field}
                            value={watch("room")}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </div>
            <div>
              <div className={styles.departmentLabel}>
                <span>{t("Department")} </span>
              </div>
              <Controller
                name="department"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuSelect
                      width={691}
                      height={42}
                      options={departmentOptions}
                      mode="multiple"
                      {...field}
                      onChange={(value) => onDepartmentChange(value, field)}
                      value={watch("department")}
                    />
                  );
                }}
              />
            </div>
            <div>
              <div className={styles.doctorLabel}>
                <span>{t("Doctor")} </span>
              </div>
              <Controller
                name="doctor"
                control={control}
                render={({ field }) => {
                  return (
                    <IcuSelect
                      width={691}
                      height={42}
                      options={doctorOptions}
                      mode="multiple"
                      {...field}
                      value={watch("doctor")}
                    />
                  );
                }}
              />
            </div>

            <Flex gap={32} justify="space-between">
              <div className={styles.dateInput}>
                <div className={styles.startDateLabel}>
                  <span>{t("Start date")} </span>
                </div>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuDateInput
                        height={42}
                        {...field}
                        value={watch("startDate")}
                        customRootClassName="start-date-input"
                        placeholder=""
                        onChange={(value) =>
                          onStartDateInputChange(value, field)
                        }
                      />
                    );
                  }}
                />
              </div>

              <div className={styles.dateInput}>
                <div className={styles.endDateLabel}>
                  <span>{t("End date")} </span>
                </div>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuDateInput
                        height={42}
                        {...field}
                        value={watch("endDate")}
                        customRootClassName="end-date-input"
                        placeholder=""
                        onChange={(value) => onEndDateInputChange(value, field)}
                      />
                    );
                  }}
                />
              </div>
            </Flex>
            {errors.startDate?.message && (
              <ErrorMessage extraClassName="age-error-message">
                {errors.startDate?.message}
              </ErrorMessage>
            )}
          </div>
        </Flex>
      </form>
    </Modal>
  );
};

export default PatientSearchModal;
