import { useEffect, useState } from "react";
import styles from "./VitalSignGraph.module.scss";
import { Checkbox, Flex } from "antd";
import "./VitalSignGraph.scss";
import { Line } from "react-chartjs-2";
import {
  calculateGraphContentWidthNoScroll,
  calculateGraphContentWidthWithScroll,
} from "../../../utils/vital-sign/VitalSign.helper";
import {
  LinearScale,
  CategoryScale,
  Chart,
  PointElement,
  LineElement,
  Tooltip,
  Point,
  ChartMeta,
} from "chart.js";
import { isEqual, min } from "lodash";
import { useTranslation } from "react-i18next";
import { ChartDataEntity } from "../../../types/vital-sign/ChartDataEntity.model";
import {
  DEFAULT_GRAPH_CONTAINER_STYLE,
  DEFAULT_GRAPH_CONTENT_WIDTH,
  GRAPH_CONTAINER_STYLE_WITH_SCROLL,
  HORIZONTAL_SEGMENT_LINE_ID,
  HORIZONTAL_SEGMENT_LINE_INITIAL_Y_COORDINATES,
  HORIZONTAL_SEGMENT_LINE_SPACING,
  HORIZONTAL_SEGMENT_SPACE_NUMBER_BETWEEN,
  MAX_LABEL_WITH_NO_SCROLL_BY_SCREEN_WIDTH,
  X_SCALE_TYPE,
} from "../../../constants/vital-sign/VitalSignGraph.constants";
import { VitalSignRangeEntity } from "../../../types/vital-sign/VitalSignRangeEntity.model";
import { INITIAL_SHOW_VITAL_SIGN_NAMES } from "../../../constants/vital-sign/InitialShowVitalSignNames.constant";

Chart.register([
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
]);
interface VitalSignGraphProps {
  inputData: ChartDataEntity;
  vitalSignRangeDataSource: VitalSignRangeEntity[];
}

const VitalSignGraph = ({
  inputData,
  vitalSignRangeDataSource,
}: VitalSignGraphProps) => {
  const { t } = useTranslation();

  const [graphContentWidth, setGraphContentWidth] = useState(
    DEFAULT_GRAPH_CONTENT_WIDTH
  );
  const [graphContainerStyle, setGraphContainerStyle] = useState(
    DEFAULT_GRAPH_CONTAINER_STYLE
  );

  const [chartData, setChartData] = useState({
    labels: inputData.labels,
    datasets: inputData.datasets,
  });

  const [hiddenVitalSignNames, setHiddenVitalSignNames] = useState<string[]>(
    () =>
      vitalSignRangeDataSource
        .filter((item) => !INITIAL_SHOW_VITAL_SIGN_NAMES.includes(item.name))
        .map((item) => item.name)
  );

  useEffect(() => {
    const totalLabels = inputData.labels.length;

    let newGraphContentWidth = calculateGraphContentWidthNoScroll(totalLabels);

    if (
      totalLabels >
      MAX_LABEL_WITH_NO_SCROLL_BY_SCREEN_WIDTH[
        `screenWidth${window.innerWidth}`
      ]
    ) {
      newGraphContentWidth = calculateGraphContentWidthWithScroll(totalLabels);
      setGraphContainerStyle(GRAPH_CONTAINER_STYLE_WITH_SCROLL);
    } else {
      setGraphContainerStyle(DEFAULT_GRAPH_CONTAINER_STYLE);
    }

    setGraphContentWidth(`${newGraphContentWidth}px`);
  }, [inputData.labels.length]);

  useEffect(() => {
    if (!isEqual(inputData, chartData)) {
      let newChartDatasets = inputData.datasets;

      if (hiddenVitalSignNames.length > 0) {
        newChartDatasets = inputData.datasets.filter(
          (item) => !hiddenVitalSignNames.includes(item.label)
        );
      }

      setChartData({
        labels: inputData.labels,
        datasets: newChartDatasets,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  useEffect(() => {
    let newChartDatasets = inputData.datasets;

    if (hiddenVitalSignNames.length > 0) {
      newChartDatasets = inputData.datasets.filter(
        (item) => !hiddenVitalSignNames.includes(item.label)
      );
    }

    setChartData((chartData) => ({
      ...chartData,
      datasets: newChartDatasets,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenVitalSignNames.length]);

  const chartOption = {
    responsive: true,
    scales: inputData.scaleOption,
    maintainAspectRatio: false,
  };

  const handleChangeVitalSignCheckbox = (
    value: boolean,
    vitalSignName: string
  ) => {
    if (value) {
      const newHiddenVitalSignNames = hiddenVitalSignNames.filter(
        (name) => name !== vitalSignName
      );

      setHiddenVitalSignNames(newHiddenVitalSignNames);

      return;
    }

    setHiddenVitalSignNames([...hiddenVitalSignNames, vitalSignName]);
  };

  // config to draw horizontal dashed segment lines equally in chart
  const horizontalSegmentLine = {
    id: HORIZONTAL_SEGMENT_LINE_ID,
    beforeDatasetsDraw(chart: any) {
      const {
        ctx,
        scales: { x },
      } = chart;

      ctx.save();

      if (x.type === X_SCALE_TYPE) {
        for (
          let index = HORIZONTAL_SEGMENT_LINE_INITIAL_Y_COORDINATES;
          index <=
          HORIZONTAL_SEGMENT_LINE_INITIAL_Y_COORDINATES +
            HORIZONTAL_SEGMENT_LINE_SPACING *
              HORIZONTAL_SEGMENT_SPACE_NUMBER_BETWEEN;
          index = index + HORIZONTAL_SEGMENT_LINE_SPACING
        ) {
          const yCoor = index;
          const xStart = x.getPixelForValue(0);
          const xEnd = x.getPixelForValue(x.ticks.length - 1);

          ctx.beginPath();
          ctx.setLineDash([3, 1]);
          ctx.lineWidth = 1.5;
          ctx.strokeStyle = "rgba(0,0,0,0.4)";
          ctx.moveTo(xStart, yCoor);
          ctx.lineTo(xEnd, yCoor);
          ctx.stroke();

          ctx.setLineDash([]);
        }
      }
    },
  };

  const getAllPointCoordinates = (
    totalLine: number,
    totalPointPerLine: number,
    chart: Chart<"line", (number | Point | null)[], unknown>
  ) => {
    const coordinateResults: {
      coordinates: Point[];
      order: number;
    }[] = [];

    for (
      let dataVisibleIndex = 0;
      dataVisibleIndex < totalLine;
      dataVisibleIndex++
    ) {
      coordinateResults.push({
        order: chart.getDatasetMeta(dataVisibleIndex).index,
        coordinates: [],
      });

      for (let pointIndex = 0; pointIndex < totalPointPerLine; pointIndex++) {
        const pointInfo = chart.getDatasetMeta(dataVisibleIndex).data[
          pointIndex
        ] as PointElement;

        coordinateResults[dataVisibleIndex].coordinates.push({
          x: pointInfo.x,
          y: pointInfo.y,
        });
      }
    }

    return coordinateResults;
  };

  const checkDataPointShouldShow = (
    coordinateList: {
      coordinates: Point[];
      order: number;
    }[],
    targetPoint: ChartMeta,
    pointIndex: number
  ) => {
    let ocurrentCount = 0;
    let orderLayers: number[] = [];

    const targetPointCoordinate = {
      x: targetPoint.data[pointIndex].x,
      y: targetPoint.data[pointIndex].y,
    };

    coordinateList.forEach((item) => {
      item.coordinates.forEach((coordinate) => {
        if (isEqual(targetPointCoordinate, coordinate)) {
          ocurrentCount++;
          orderLayers.push(item.order);
        }
      });
    });

    if (
      ocurrentCount === 1 ||
      (ocurrentCount > 1 && min(orderLayers) === targetPoint.index)
    ) {
      return true;
    }

    return false;
  };

  const dataPointTracker = {
    id: "dataPointTracker",
    afterDatasetsDraw(chart: Chart<"line">) {
      const {
        ctx,
        scales: { x },
      } = chart;

      function showDataPoint(
        text: string,
        dataVisibleIndex: number,
        x: number,
        y: number
      ) {
        ctx.font = "10px sans-serif";
        ctx.fillStyle = "rgba(0,0,0,0.6)";
        ctx.textAlign = "right";
        ctx.textBaseline = "bottom";
        ctx.fillText(
          text,
          chart.getDatasetMeta(dataVisibleIndex).data[x].x + 5,
          chart.getDatasetMeta(dataVisibleIndex).data[y].y - 10
        );
      }

      const coordinateList = getAllPointCoordinates(
        chart.getVisibleDatasetCount(),
        x.ticks.length,
        chart
      );

      for (
        let dataVisibleIndex = 0;
        dataVisibleIndex < chart.getVisibleDatasetCount();
        dataVisibleIndex++
      ) {
        for (let pointIndex = 0; pointIndex < x.ticks.length; pointIndex++) {
          if (
            checkDataPointShouldShow(
              coordinateList,
              chart.getDatasetMeta(dataVisibleIndex),
              pointIndex
            )
          ) {
            showDataPoint(
              (
                (
                  chart.getDatasetMeta(dataVisibleIndex).data[
                    pointIndex
                  ] as PointElement
                ).parsed.y ?? ""
              ).toString(),
              dataVisibleIndex,
              pointIndex,
              pointIndex
            );
          }
        }
      }
    },
  };

  return (
    <div className={styles.graphSectionContainer}>
      <div className={styles.vitalSignHeader}>{t("Vital signs graph")}</div>
      <Flex className={styles.graphSection}>
        <Flex className={styles.rangeTableContainer}>
          {vitalSignRangeDataSource.map((item) => {
            return (
              <div key={item.id} className={styles.rangeCol}>
                <Flex
                  vertical
                  justify="center"
                  className={`${item.name}-name ${styles.vitalSignCheckbox}`}
                  align="center"
                >
                  <Checkbox
                    defaultChecked={INITIAL_SHOW_VITAL_SIGN_NAMES.includes(
                      item.name
                    )}
                    className="vital-sign-check-input"
                    onChange={(e) =>
                      handleChangeVitalSignCheckbox(e.target.checked, item.name)
                    }
                  />
                  <div
                    style={{
                      color: item.color,
                    }}
                  >
                    {item.name}
                  </div>
                </Flex>
                <div className={styles.rangeValues}>
                  {item.ranges.map((rangeItem) => (
                    <div
                      key={rangeItem}
                      className={
                        !hiddenVitalSignNames.includes(item.name)
                          ? styles.rangeValue
                          : ""
                      }
                    >
                      <span
                        style={{
                          color: item.color,
                        }}
                      >
                        {!hiddenVitalSignNames.includes(item.name)
                          ? rangeItem
                          : ""}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </Flex>

        <div
          className={styles.graphContainer}
          id="graph-container"
          style={graphContainerStyle}
        >
          {chartData.datasets.flatMap((item) => item.data).length > 0 ? (
            <div
              className={styles.graphContent}
              style={{
                width: graphContentWidth,
              }}
            >
              <Line
                data={chartData}
                options={chartOption}
                plugins={[horizontalSegmentLine, dataPointTracker]}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Flex>
    </div>
  );
};

export default VitalSignGraph;
