import dayjs from "dayjs";
import { PatientDetailReadResponse } from "../../types/patient-detail/PatientDetailReadResponse.model";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import {
  convertSexValueToString,
  convertUTCtoLocalDateWithNoFormat,
} from "../helpers";

export const formatPatientDetail = (
  data: PatientDetailReadResponse
): PatientDetailEntity => {
  return {
    id: data.id,
    id_patient: data.id_patient,
    id_admission_record: data.id_admission_record,
    name: data.name,
    age: dayjs().diff(dayjs(data.date_of_birth), "year"),
    sex: convertSexValueToString(data.sex.toString()),
    history_of_present_illness: data.history_of_present_illness,
    past_medical_history: data.past_medical_history,
    medication_history: data.medication_history,
    post_admission_care: data.post_admission_care,
    hospital_admission_date: convertUTCtoLocalDateWithNoFormat(
      data.hospital_admission_date
    ),
    hospital_discharge_date: data.hospital_discharge_date
      ? convertUTCtoLocalDateWithNoFormat(data.hospital_discharge_date)
      : "",
    pre_admission_route: data.pre_admission_route,
    hospital_discharge_outcome: data.hospital_discharge_outcome,
    icu_admission_date: convertUTCtoLocalDateWithNoFormat(
      data.icu_admission_date
    ),
    icu_discharge_date: data.icu_discharge_date
      ? convertUTCtoLocalDateWithNoFormat(data.icu_discharge_date)
      : "",
    icu_discharge_outcome: data.icu_discharge_outcome,
    weight: data.weight,
    height: data.height,
    bsa: data.bsa,
    is_discharged: data.is_discharged,
    room: data.room,
    departments: data.departments,
    ward: data.ward,
  };
};
