import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL, API_REFRESH_TOKEN } from "./endpoints";
import { ErrorEntity } from "../../types/common/ErrorEntity.model";
import { LOCAL_STORAGE_KEY } from "../../constants/common/localStorageKey.constant";
import { LOGIN_PATH } from "../routerPath";
import { refreshToken } from "../../services/auth/authService";
import { ERROR_CODE } from "../../constants/common/errorMessage.constant";

export const axiosInstance: any = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

/**
 * get request headers
 * @returns
 */

axiosInstance.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    const token = localStorage.getItem(
      config.url === API_REFRESH_TOKEN
        ? LOCAL_STORAGE_KEY.refreshToken
        : LOCAL_STORAGE_KEY.accessToken
    );
    if (token && config.headers) {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(new Error(error));
  }
);

// define error handle
axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    const { data } = response;

    // handle error code
    if (data.error_code) {
      console.log("error", data.error_code);
    }
    return response.data;
  },
  async (error: AxiosError<ErrorEntity>) => {
    const { response, config } = error;

    if (
      config &&
      config.url === API_REFRESH_TOKEN &&
      response?.data.status === ERROR_CODE.unauthorised
    ) {
      window.location.href = LOGIN_PATH;
      localStorage.clear();
      return;
    }

    if (
      config &&
      config?.url !== LOGIN_PATH &&
      response?.data.status === ERROR_CODE.unauthorised
    ) {
      const response = await refreshToken();

      if (response.access_token) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.accessToken,
          response.access_token
        );

        config.headers.Authorization = "Bearer " + response.access_token;

        return (await axios.request(config)).data;
      }
    }

    return response?.data.message ? response.data.message : "";
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /**
   * post request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  post: (endpoint: string, data: any, params: any = {}) => {
    return axiosInstance.post(endpoint, data, {
      params,
    });
  },

  /**
   * put
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  put: (endpoint: string, data: any) => {
    return axiosInstance.put(endpoint, data, {});
  },

  /**
   * get request
   * @param {string} endpoint
   * @param {*} params All parameter need to pass for server
   */
  get: (endpoint: string, params: any = {}) => {
    return axiosInstance.get(endpoint, {
      params,
    });
  },

  /**
   * delete request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  delete: (endpoint: string, data?: any) => {
    return axiosInstance.delete(endpoint, {
      data,
    });
  },

  /**
   * patch request
   * @param {string} endpoint
   * @param {*} data All parameter need to pass for server
   */
  patch: (endpoint: string, data: any) => {
    return axiosInstance.patch(endpoint, data);
  },
};
