import { useEffect, useState } from "react";
import styles from "./FilterSection.module.scss";
import { Dropdown, Flex, MenuProps } from "antd";
import IcuButton from "../../common/button/IcuButton";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import sortIcon from "../../../assets/icons/arrow-sort.svg";
import { TabFilterPatientList } from "../../../types/patient-list/TabFilter.model";
import "./FilterSection.scss";
import { useTranslation } from "react-i18next";
import { MenuInfo } from "rc-menu/es/interface";
import { PatientListTabData } from "../../../types/patient-list/PatientListTabData.model";
import { SORTING_OPTION } from "../../../constants/patient-list/patientList.constant";
import CustomDatePicker from "../../common/custom-date-picker/CustomDatePicker";
import dayjs from "dayjs";

interface FilterSectionProps {
  activeTab: TabFilterPatientList;
  handleChangeTab: (tab: TabFilterPatientList) => void;
  onShowSearchModal: () => void;
  isSearchingPatient: boolean;
  onShowRegisterModal: () => void;
  tabData: PatientListTabData;
  handleSortPatient: (menuInfo: MenuInfo) => void;
  handleDateChange: (date: string) => void;
}

const FilterSection = ({
  activeTab,
  handleChangeTab,
  onShowSearchModal,
  isSearchingPatient,
  onShowRegisterModal,
  handleSortPatient,
  tabData,
  handleDateChange,
}: FilterSectionProps) => {
  const { t } = useTranslation();

  const menuItems: MenuProps["items"] = [
    {
      key: `${
        activeTab === TabFilterPatientList.ADMITTED
          ? SORTING_OPTION.admissionDateDesc
          : SORTING_OPTION.dischargeDateDesc
      }`,
      label: (
        <span>
          {t(
            `${
              activeTab === TabFilterPatientList.ADMITTED
                ? t("Admitted")
                : t("Discharged")
            } ${t("Date (newest first)")}`
          )}
        </span>
      ),
    },
    {
      key: `${
        activeTab === TabFilterPatientList.ADMITTED
          ? SORTING_OPTION.admissionDateAsc
          : SORTING_OPTION.dischargeDateAsc
      }`,
      label: (
        <span>
          {t(
            `${
              activeTab === TabFilterPatientList.ADMITTED
                ? t("Admitted")
                : t("Discharged")
            } ${t("Date (oldest first)")}`
          )}
        </span>
      ),
    },
  ];

  const [selectedSortOptions, setSelectedSortOptions] = useState<string[]>([
    SORTING_OPTION.admissionDateDesc,
    SORTING_OPTION.dischargeDateDesc,
  ]);

  useEffect(() => {
    const newSelectedSortOptions = [];

    if (tabData.admitted.sortBy) {
      newSelectedSortOptions.push(tabData.admitted.sortBy);
    }

    if (tabData.discharged.sortBy) {
      newSelectedSortOptions.push(tabData.discharged.sortBy);
    }

    setSelectedSortOptions(newSelectedSortOptions);
  }, [tabData.admitted.sortBy, tabData.discharged.sortBy]);

  return (
    <div>
      <Flex justify="space-between" gap={4}>
        <button
          className={`${styles.filteredTab} ${
            activeTab === TabFilterPatientList.ADMITTED ? styles["active"] : ""
          }`}
          onClick={() => handleChangeTab(TabFilterPatientList.ADMITTED)}
        >
          {t("Admitted")}
        </button>
        <button
          className={`${styles.filteredTab} ${
            activeTab === TabFilterPatientList.DISCHARGED
              ? styles["active"]
              : ""
          }`}
          onClick={() => handleChangeTab(TabFilterPatientList.DISCHARGED)}
        >
          {t("Discharged")}
        </button>
      </Flex>

      <Flex justify="space-between" className="patient-list-button-group">
        <Flex gap={20}>
          <IcuButton
            width={119}
            height={42}
            icon={<SearchOutlined />}
            backgroundColor={`${
              isSearchingPatient ? "primary-3" : "secondary"
            }`}
            color={`${isSearchingPatient ? "primary-2" : "grey-2"}`}
            extraClassName={`patient-list-search-btn ${
              isSearchingPatient ? "is-searching" : ""
            }`}
            onClick={onShowSearchModal}
          >
            {t("Search")}
          </IcuButton>
          <Dropdown
            menu={{
              items: menuItems,
              selectable: true,
              onClick: handleSortPatient,
              selectedKeys: selectedSortOptions,
              defaultSelectedKeys: [
                SORTING_OPTION.admissionDateDesc,
                SORTING_OPTION.dischargeDateDesc,
              ],
            }}
            className={styles.dropdownMenu}
            trigger={["click"]}
          >
            <IcuButton
              height={42}
              width={101}
              icon={<img src={sortIcon} alt="sort icon"></img>}
              backgroundColor="primary-3"
              color="primary-2"
              extraClassName="patient-list-sort-btn"
            >
              {t("Sort")}
            </IcuButton>
          </Dropdown>
        </Flex>

        <Flex gap={16} className="right-group-btn">
          <IcuButton
            width={154}
            height={42}
            icon={<PlusCircleFilled />}
            backgroundColor="primary-2"
            color="grey-9"
            extraClassName="patient-list-add-btn"
            onClick={onShowRegisterModal}
          >
            {t("New patient")}
          </IcuButton>
          {!isSearchingPatient && (
            <CustomDatePicker
              onDateChange={handleDateChange}
              maxDate={dayjs()}
              defaultValue={tabData[activeTab].selectedDate}
            />
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default FilterSection;
