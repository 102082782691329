import { useEffect, useState } from "react";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import styles from "./VitalSignPage.module.scss";
import PersonalExaminationCard from "../../components/common/personal-examination-card/PersonalExaminationCard";
import { PatientDetailEntity } from "../../types/common/PatientDetail.model";
import VitalSignGraph from "../../components/vital-sign/graph-section/VitalSignGraph";
import VitalSignTable from "../../components/vital-sign/vital-sign-table/VitalSignTable";
import {
  transformResponseToVentilatorTableData,
  transformResponseToVitalSignRangeTable,
  transformResponseToVitalSignTableData,
  transformVitalSignToGraphData,
} from "../../utils/vital-sign/VitalSign.helper";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../constants/common/dateFormat.constant";
import VentilatorTable from "../../components/vital-sign/ventilator-table/VentilatorTable";
import { LOCAL_STORAGE_KEY } from "../../constants/common/localStorageKey.constant";
import {
  createVitalSignOCR,
  getAllVitalSigns,
  putVitalSign,
  updateVitalSign,
} from "../../services/vital-sign/vitalSignService";
import {
  createVentilatorSettingOCR,
  getAllVentilatorSettings,
  putVentilatorSetting,
  updateVentilatorSetting,
} from "../../services/ventilator-setting/ventilatorSettingService";
import CustomDatePicker from "../../components/common/custom-date-picker/CustomDatePicker";
import { Flex } from "antd";
import {
  changeFavicon,
  convertUTCtoLocalDateWithNoFormat,
  formatDateBeforeSendToServer,
} from "../../utils/helpers";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import { VitalSignTableEntity } from "../../types/vital-sign/VitalSignTableEntity.model";
import "./VitalSignPage.scss";
import { ChartDataEntity } from "../../types/vital-sign/ChartDataEntity.model";
import { VitalSignRangeEntity } from "../../types/vital-sign/VitalSignRangeEntity.model";
import { isEqual } from "lodash";
import {
  VENTILATOR_TABLE_CLASSNAME,
  VITAL_SIGN_TABLE_CLASSNAME,
} from "../../constants/vital-sign/VitalSignTable.constant";
import { useParams } from "react-router-dom";
import { getPatientDetail } from "../../services/patient/patientService";
import { formatPatientDetail } from "../../utils/patient-detail/PatientDetail.helper";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import UploadImageButton from "../../components/vital-sign/upload-image-button/UploadImageButton";
import { RcFile } from "antd/es/upload";
import { useUploadImage } from "../../hooks/useUploadImage";
import { UPLOAD_IMAGE_STATUS } from "../../constants/common/uploadImageStatus.constant";

const VitalSignPage = () => {
  const { t } = useTranslation();

  const orderNumber =
    localStorage.getItem(LOCAL_STORAGE_KEY.patientOrderNumber) ?? "";

  const [vitalSignTableValues, setVitalSignTableValues] =
    useState<VitalSignTableEntity>();

  const [chartData, setChartData] = useState<ChartDataEntity>({
    labels: [],
    datasets: [],
  });

  const [targetDate, setTargetDate] = useState(
    dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    )
  );

  const [isLoading, setIsLoading] = useState(false);

  const [ventilatorTableValues, setVentilatorTableValues] =
    useState<VitalSignTableEntity>();

  const [vitalSignRangeDataSource, setVitalSignRangeDataSource] =
    useState<VitalSignRangeEntity[]>();

  const [vitalSignTableHeight, setVitalSignTableHeight] = useState<number>(
    () => {
      const initVitalSignTableElement = document.getElementsByClassName(
        VITAL_SIGN_TABLE_CLASSNAME
      );

      if (initVitalSignTableElement.length > 0) {
        return (initVitalSignTableElement[0] as HTMLDivElement).offsetHeight;
      }

      return 0;
    }
  );

  const [ventilatorTableHeight, setVentilatorTableHeight] = useState<number>(
    () => {
      const initVentilatorTableElement = document.getElementsByClassName(
        VENTILATOR_TABLE_CLASSNAME
      );

      if (initVentilatorTableElement.length > 0) {
        return (initVentilatorTableElement[0] as HTMLDivElement).offsetHeight;
      }

      return 0;
    }
  );

  const [patientInfo, setPatientInfo] = useState<PatientDetailEntity>();

  const { id: admissionRecordId } = useParams();

  const { contextHolder, handleUploadImageCallback } = useUploadImage();

  const fetchPatientInfo = async () => {
    if (admissionRecordId) {
      const response = await getPatientDetail(Number(admissionRecordId));

      setPatientInfo(formatPatientDetail(response.data));
    }
  };

  const fetchAllVitalSigns = async (
    admissionRecordId: number,
    date?: string
  ) => {
    setIsLoading(true);

    const response = await getAllVitalSigns(admissionRecordId, date);

    if (response) {
      setVitalSignTableValues(
        transformResponseToVitalSignTableData(response.data)
      );
      setChartData(transformVitalSignToGraphData(response.data));
      setVitalSignRangeDataSource(
        transformResponseToVitalSignRangeTable(response.data)
      );
    }

    setIsLoading(false);

    if (response.data.length > 0) {
      const vitalSignResults = response.data.flatMap((item) => item.result);

      if (vitalSignResults.length > 0) {
        return convertUTCtoLocalDateWithNoFormat(
          vitalSignResults[0].created_at
        );
      }
    }

    return dayjs(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
  };

  const fetchAllVentilatorSettings = async (
    admissionRecordId: number,
    date?: string
  ) => {
    setIsLoading(true);

    const response = await getAllVentilatorSettings(admissionRecordId, date);

    if (response) {
      setVentilatorTableValues(
        transformResponseToVentilatorTableData(response.data)
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.vitalSign.title);
    changeFavicon(DOCUMENT_HEADER.vitalSign.icon);

    fetchPatientInfo();

    fetchAllVitalSigns(Number(admissionRecordId)).then(
      (latestDateWithVitalSignResult) => {
        setTargetDate(latestDateWithVitalSignResult);
      }
    );

    fetchAllVentilatorSettings(Number(admissionRecordId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const graphContainer = document.querySelector("#graph-container");
    const vitalSignTable = document.querySelector(
      ".vital-sign-table .ant-table-content"
    );

    function select_scroll_1() {
      if (graphContainer && vitalSignTable)
        vitalSignTable.scrollLeft = graphContainer.scrollLeft;
    }
    function select_scroll_2() {
      if (graphContainer && vitalSignTable)
        graphContainer.scrollLeft = vitalSignTable.scrollLeft;
    }

    if (graphContainer && vitalSignTable) {
      graphContainer.addEventListener("scroll", select_scroll_1, false);
      vitalSignTable.addEventListener("scroll", select_scroll_2, false);
    }

    return () => {
      if (graphContainer) {
        graphContainer.removeEventListener("scroll", select_scroll_1, false);
      }

      if (vitalSignTable) {
        vitalSignTable.removeEventListener("scroll", select_scroll_2, false);
      }
    };
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(VITAL_SIGN_TABLE_CLASSNAME),
        vitalSignTableHeight
      ) &&
      document.getElementsByClassName(VITAL_SIGN_TABLE_CLASSNAME).length > 0
    ) {
      setVitalSignTableHeight(
        (
          document.getElementsByClassName(
            VITAL_SIGN_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  useEffect(() => {
    if (
      !isEqual(
        document.getElementsByClassName(VENTILATOR_TABLE_CLASSNAME),
        vitalSignTableHeight
      ) &&
      document.getElementsByClassName(VENTILATOR_TABLE_CLASSNAME).length > 0
    ) {
      setVentilatorTableHeight(
        (
          document.getElementsByClassName(
            VENTILATOR_TABLE_CLASSNAME
          )[0] as HTMLDivElement
        ).offsetHeight
      );
    }
  });

  const handleUpdateVitalSignCell = async (
    id: number | undefined,
    typeId: number,
    value: number | null,
    createdAt: string | number
  ) => {
    setIsLoading(true);

    if (id !== undefined) {
      await updateVitalSign(id, value);
    } else {
      await putVitalSign(
        Number(admissionRecordId),
        typeId,
        value,
        formatDateBeforeSendToServer(
          `${targetDate.format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} ${createdAt}:00`
        )
      );
    }

    const response = await getAllVitalSigns(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        targetDate.toDate(),
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    setVitalSignTableValues(
      transformResponseToVitalSignTableData(response.data)
    );
    setChartData(transformVitalSignToGraphData(response.data));
    setIsLoading(false);
  };

  const handleUpdateVentilatorCell = async (
    id: number | undefined,
    typeId: number,
    value: string | null,
    createdAt: string | number
  ) => {
    setIsLoading(true);

    if (id !== undefined) {
      await updateVentilatorSetting(id, value);
    } else {
      await putVentilatorSetting(
        Number(admissionRecordId),
        typeId,
        value,
        formatDateBeforeSendToServer(
          `${targetDate.format(
            DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
          )} ${createdAt}:00`
        )
      );
    }

    const response = await getAllVentilatorSettings(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        targetDate.toDate(),
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    setVentilatorTableValues(
      transformResponseToVentilatorTableData(response.data)
    );
    setIsLoading(false);
  };

  const handleDateChange = (date: string) => {
    fetchAllVitalSigns(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        date,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    fetchAllVentilatorSettings(
      Number(admissionRecordId),
      formatDateBeforeSendToServer(
        date,
        DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
      )
    );

    setTargetDate(dayjs(date));
  };

  const handleUploadVitalSignImage = (file: RcFile) => {
    setIsLoading(true);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      const vitalSignBase64String = event.target?.result
        ?.toString()
        .split(",")[1];

      if (vitalSignBase64String && admissionRecordId) {
        const vitalSignOcrResponse = await createVitalSignOCR(
          admissionRecordId.toString(),
          vitalSignBase64String
        );

        if (typeof vitalSignOcrResponse !== "string") {
          const vitalSignsDataResponse = await getAllVitalSigns(
            Number(admissionRecordId),
            formatDateBeforeSendToServer(
              targetDate.toDate(),
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
            )
          );

          setVitalSignTableValues(
            transformResponseToVitalSignTableData(vitalSignsDataResponse.data)
          );
          setChartData(
            transformVitalSignToGraphData(vitalSignsDataResponse.data)
          );

          setIsLoading(false);

          handleUploadImageCallback(UPLOAD_IMAGE_STATUS.success);
        } else {
          handleUploadImageCallback(UPLOAD_IMAGE_STATUS.failed);
        }
        setIsLoading(false);
      }
    };

    return false;
  };

  const handleUploadVetilatorSettingImage = (file: RcFile) => {
    setIsLoading(true);

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = async function (event) {
      const ventilatorSettingBase64String = event.target?.result
        ?.toString()
        .split(",")[1];

      if (ventilatorSettingBase64String && admissionRecordId) {
        const ventilatorSettingOcrResponse = await createVentilatorSettingOCR(
          admissionRecordId.toString(),
          ventilatorSettingBase64String
        );

        if (typeof ventilatorSettingOcrResponse !== "string") {
          const ventilatorSettingsResponse = await getAllVentilatorSettings(
            Number(admissionRecordId),
            formatDateBeforeSendToServer(
              targetDate.toDate(),
              DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
            )
          );

          setVentilatorTableValues(
            transformResponseToVentilatorTableData(
              ventilatorSettingsResponse.data
            )
          );

          handleUploadImageCallback(UPLOAD_IMAGE_STATUS.success);
        } else {
          handleUploadImageCallback(UPLOAD_IMAGE_STATUS.failed);
        }
        setIsLoading(false);
      }
    };

    return false;
  };

  return (
    <>
      {contextHolder}
      <IcuHeader />
      <Wrapper>
        <div className={styles.vitalSignPage}>
          {patientInfo ? (
            <PersonalExaminationCard
              data={patientInfo}
              customClassName="vital-sign-personal-card"
              customOrderNumber={orderNumber}
              dropdownButton={
                <UploadImageButton
                  onUploadVitalSignImage={handleUploadVitalSignImage}
                  onUploadVetilatorSettingImage={
                    handleUploadVetilatorSettingImage
                  }
                />
              }
            />
          ) : (
            <></>
          )}

          <Flex justify="flex-end" className={styles.datePicker}>
            <CustomDatePicker
              onDateChange={handleDateChange}
              maxDate={dayjs()}
              defaultValue={targetDate}
            />
          </Flex>

          {!!(
            vitalSignRangeDataSource?.length &&
            vitalSignRangeDataSource.length > 0
          ) && (
            <VitalSignGraph
              inputData={chartData}
              vitalSignRangeDataSource={vitalSignRangeDataSource}
            />
          )}

          {vitalSignTableValues &&
          (vitalSignTableValues.tableData.length ?? 0) > 0 &&
          (vitalSignTableValues.colHeaders.length ?? 0) > 0 ? (
            <div
              className={styles.vitalSignTableSection}
              style={{
                height: vitalSignTableHeight,
              }}
            >
              <div
                className={styles.vitalSignHeader}
                style={{
                  width: vitalSignTableHeight,
                  top: vitalSignTableHeight,
                }}
              >
                {t("Vital signs")}
              </div>
              <VitalSignTable
                data={vitalSignTableValues.tableData}
                colHeaders={vitalSignTableValues.colHeaders}
                onUpdateCell={handleUpdateVitalSignCell}
              />
            </div>
          ) : (
            <></>
          )}

          {ventilatorTableValues &&
          (ventilatorTableValues.tableData.length ?? 0) > 0 &&
          (ventilatorTableValues.colHeaders.length ?? 0) > 0 ? (
            <div
              className={styles.vetilatorTableSection}
              style={{
                height: ventilatorTableHeight,
              }}
            >
              <div
                className={styles.ventilatorHeader}
                style={{
                  width: ventilatorTableHeight,
                  top: ventilatorTableHeight,
                }}
              >
                {t("Ventilator settings")}
              </div>
              <VentilatorTable
                data={ventilatorTableValues.tableData}
                colHeaders={ventilatorTableValues.colHeaders}
                onUpdateCell={handleUpdateVentilatorCell}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Wrapper>
      <IcuFooter />
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default VitalSignPage;
