import {
  API_GET_PATIENT_DETAIL,
  API_GET_PATIENT_LIST,
  API_PATCH_PATIENT_DETAIL,
  API_REGISTER_PATIENT,
  API_SEARCH_PATIENT,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { PatientDetailPatchRequest } from "../../types/patient-detail/PatientDetailPatchRequest.model";
import { PatientDetailReadResponse } from "../../types/patient-detail/PatientDetailReadResponse.model";
import { PatientListReadRequest } from "../../types/patient-list/PatientListReadRequest.model";
import { PatientListReadResponse } from "../../types/patient-list/PatientListReadResponse.model";
import { PatientRegisterRequest } from "../../types/patient-list/PatientRegisterRequest.model";
import { PatientSearchRequest } from "../../types/patient-list/PatientSearchRequest.model";

export const getPatientList = (
  params: PatientListReadRequest
): Promise<PatientListReadResponse> => {
  return networkAdapter.get(API_GET_PATIENT_LIST, params);
};

export const patchPatientDetail = (
  admissionRecordId: number,
  data: PatientDetailPatchRequest
) => {
  return networkAdapter.patch(
    `${API_PATCH_PATIENT_DETAIL}/${admissionRecordId}`,
    data
  );
};

export const registerPatient = (params: PatientRegisterRequest) => {
  return networkAdapter.post(API_REGISTER_PATIENT, params);
};

export const searchPatient = (
  body: PatientSearchRequest,
  params: PatientListReadRequest
): Promise<PatientListReadResponse> => {
  return networkAdapter.post(API_SEARCH_PATIENT, body, params);
};

export const getPatientDetail = (
  admissionRecordId: number
): Promise<BaseResponse<PatientDetailReadResponse>> => {
  return networkAdapter.get(`${API_GET_PATIENT_DETAIL}/${admissionRecordId}`);
};
