import { Flex } from "antd";
import { useTranslation } from "react-i18next";
import noteBook from "../../../assets/icons/note-book.svg";
import textAreaIcon from "../../../assets/icons/white-text-area.svg";
import IcuButton from "../../common/button/IcuButton";
import PersonalInfo from "../../common/personal-info/PersonalInfo";
import styles from "./PersonalCard.module.scss";
import "./PersonalCard.scss";
import { PatientDetailEntity } from "../../../types/common/PatientDetail.model";
import { LOCAL_STORAGE_KEY } from "../../../constants/common/localStorageKey.constant";
import {
  EXAMINATION_RESULT_PATH,
  PATIENT_LIST_PATH,
  VITAL_SIGN_PATH,
} from "../../../configs/routerPath";
import todoIcon from "../../../assets/icons/todo-icon.svg";
import { useBeforeUnload, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  addOpenedTabByAdmissionRecordId,
  closeAllTabsByAdmissionRecordId,
} from "../../../redux/slices/tabSlice";

interface PersonalCardProps {
  data: PatientDetailEntity;
}

const PersonalCard = ({ data }: PersonalCardProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const closeAllRelativeTabs = () => {
    dispatch(
      closeAllTabsByAdmissionRecordId({
        id: data.id_admission_record,
      })
    );
  };

  useBeforeUnload(() => {
    closeAllRelativeTabs();
  });

  useEffect(() => {
    window.addEventListener("popstate", () => closeAllRelativeTabs());

    return () => {
      window.removeEventListener("popstate", () => closeAllRelativeTabs());
    };
  });

  const openExamResultPage = () => {
    const examResultTab = window.open(
      `${window.location.origin}${EXAMINATION_RESULT_PATH}`.replaceAll(
        ":id",
        data.id_admission_record.toString()
      ),
      "_blank"
    );

    dispatch(
      addOpenedTabByAdmissionRecordId({
        id: data.id_admission_record,
        tab: examResultTab,
      })
    );
  };

  const openVitalSignTab = () => {
    const vitalSignTab = window.open(
      `${window.location.origin}${VITAL_SIGN_PATH}`.replaceAll(
        ":id",
        data.id_admission_record.toString()
      ),
      "_blank"
    );

    dispatch(
      addOpenedTabByAdmissionRecordId({
        id: data.id_admission_record,
        tab: vitalSignTab,
      })
    );
  };

  const orderNumber =
    localStorage.getItem(LOCAL_STORAGE_KEY.patientOrderNumber) ?? "";

  const navigatePatientList = () => {
    navigate(PATIENT_LIST_PATH, {
      replace: true,
    });

    dispatch(
      closeAllTabsByAdmissionRecordId({
        id: data.id_admission_record,
      })
    );
  };

  return (
    <Flex className={styles.patientItem}>
      <div className={styles.orderNumber}>{orderNumber}</div>
      <div>
        <PersonalInfo
          data={data}
          hidePrefixIcon
          gap={104}
          containerClassName="patient-detail-personal-info"
        />
      </div>
      <Flex
        vertical
        align="flex-end"
        className={styles.examinationGroupBtn}
        gap={10}
      >
        <div>
          <IcuButton
            width={165}
            height={34}
            icon={<img src={textAreaIcon} alt="examination result icon" />}
            extraClassName="patient-detail-examination-btn"
            onClick={openExamResultPage}
          >
            {t("Examination result")}
          </IcuButton>
          <IcuButton
            width={119}
            height={34}
            icon={<img src={todoIcon} alt="patient list icon" />}
            extraClassName="patient-detail-list-btn"
            onClick={navigatePatientList}
            backgroundColor="primary-3"
            color="primary-2"
          >
            {t("Patient list")}
          </IcuButton>
        </div>
        <IcuButton
          width={294}
          height={34}
          icon={<img src={noteBook} alt="detail icon" />}
          extraClassName="patient-detail-ventilator-btn"
          onClick={openVitalSignTab}
        >
          {t("Vital signs & ventilator settings")}
        </IcuButton>
      </Flex>
    </Flex>
  );
};

export default PersonalCard;
