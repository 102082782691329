import React, { useRef } from "react";
import styles from "./AdmissionCard.module.scss";
import { Card, Input } from "antd";
import { useTranslation } from "react-i18next";
import "./AdmissionCard.scss";
import { useDispatch } from "react-redux";
import { setUpdateInfoTime } from "../../../../../redux/slices/patientSlice";
import { DATE_FORMAT } from "../../../../../constants/common/dateFormat.constant";
import dayjs from "dayjs";
import { TextAreaRef } from "antd/es/input/TextArea";

interface AdmissionCardProps {
  title: string;
  defaultContent?: string;
  onBlur: (value: string) => void;
}

const AdmissionCard = ({
  title,
  defaultContent,
  onBlur,
}: AdmissionCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const admissionDataRef = useRef<TextAreaRef>(null);

  const handleAdmissionChange = () => {
    dispatch(setUpdateInfoTime(dayjs(new Date()).format(DATE_FORMAT.HHmm)));
  };

  return (
    <Card
      title={title}
      bordered={false}
      className={`${styles.addmissionCard} addmission-card`}
    >
      <Input.TextArea
        className={`${styles.admissionCardInput} admission-card-input`}
        placeholder={t("Detail")}
        defaultValue={defaultContent}
        onBlur={(e) => onBlur(e.target.value)}
        onChange={handleAdmissionChange}
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && e.ctrlKey) {
            admissionDataRef.current?.blur();
          }
        }}
        ref={admissionDataRef}
      />
    </Card>
  );
};

export default AdmissionCard;
