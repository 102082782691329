import { configureStore } from "@reduxjs/toolkit";
import patientReducer from "./slices/patientSlice";
import tabReducer from "./slices/tabSlice";

const rootReducer = {
  patient: patientReducer,
  tab: tabReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
