import React from "react";
import coverImage from "../../../assets/img/cover-img.png";
import styles from "./CoverImage.module.scss";

const CoverImage = () => {
  return (
    <div className={styles.container}>
      <img src={coverImage} alt="" className={styles.coverImg} />
    </div>
  );
};

export default CoverImage;
