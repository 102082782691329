import React, { useState } from "react";
import LoginForm from "../../components/login/login-form/LoginForm";
import { Flex } from "antd";
import styles from "./LoginPage.module.scss";
import CoverImage from "../../components/login/cover-image/CoverImage";
import { LoginFormEntity } from "../../types/login/LoginForm.model";
import IcuErrorModal from "../../components/common/error-modal/IcuErrorModal";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { LOCAL_STORAGE_KEY } from "../../constants/common/localStorageKey.constant";
import { useNavigate } from "react-router-dom";
import { PATIENT_LIST_PATH } from "../../configs/routerPath";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import { login } from "../../services/auth/authService";

const LoginPage = () => {
  const { t } = useTranslation();

  const initialLoginValues = {
    username: "",
    password: "",
  };

  const [loginErrorMessage, setLoginErrorMessage] = useState<
    string | undefined
  >();

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (formValues: LoginFormEntity) => {
    setIsLoginLoading(true);

    const privateKey = process.env.REACT_APP_BACKEND_ENCRYPTED_KEY ?? "";

    const encryptedLoginCredential = CryptoJS.AES.encrypt(
      JSON.stringify(formValues),
      privateKey
    ).toString();

    const response = await login({
      data: encryptedLoginCredential,
    });

    setIsLoginLoading(false);

    if (typeof response === "string") {
      setLoginErrorMessage(t("Error! Please try again."));
      return;
    }

    if (response.access_token && response.refresh_token) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY.accessToken,
        response.access_token
      );
      localStorage.setItem(
        LOCAL_STORAGE_KEY.refreshToken,
        response.refresh_token
      );

      const encryptedUsername = CryptoJS.AES.encrypt(
        formValues.username,
        process.env.REACT_APP_FRONTEND_ENCRYPTED_KEY ?? ""
      ).toString();

      localStorage.setItem(LOCAL_STORAGE_KEY.userName, encryptedUsername);

      navigate(PATIENT_LIST_PATH, {
        replace: true,
      });
    }
  };

  const handleCancel = () => {
    setLoginErrorMessage(undefined);
  };

  return (
    <>
      <Flex className={styles.loginPage}>
        <LoginForm initialValues={initialLoginValues} onLogin={handleLogin} />
        <CoverImage />
      </Flex>
      {!!loginErrorMessage && (
        <IcuErrorModal
          content={t(loginErrorMessage)}
          title={t("Error")}
          handleCancel={handleCancel}
          isOpen={!!loginErrorMessage}
        />
      )}
      {isLoginLoading && <LoadingOverlay />}
    </>
  );
};

export default LoginPage;
