import styles from "./AdmissionInfo.module.scss";
import { Col, Row } from "antd";
import AdmissionCard from "./admission-card/AdmissionCard";
import { useTranslation } from "react-i18next";
import { PatientDetailEntity } from "../../../../types/common/PatientDetail.model";
import { patchPatientDetail } from "../../../../services/patient/patientService";
import { useDispatch } from "react-redux";
import { getPatientById } from "../../../../redux/slices/patientSlice";
import { AppDispatch } from "../../../../redux/store";

interface AdmissionInfoProps {
  data: PatientDetailEntity;
}

const AdmissionInfo = ({ data }: AdmissionInfoProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const handleBlurIllnessHistory = async (value: string) => {
    if (value !== data.history_of_present_illness) {
      await patchPatientDetail(data.id_admission_record, {
        history_of_present_illness: value,
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };
  const handleBlurMedicalHistory = async (value: string) => {
    if (value !== data.medication_history) {
      await patchPatientDetail(data.id_admission_record, {
        medication_history: value,
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };
  const handleBlurPastMedicalHistory = async (value: string) => {
    if (value !== data.past_medical_history) {
      await patchPatientDetail(data.id_admission_record, {
        past_medical_history: value,
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };
  const handleBlurPostAdmissionCare = async (value: string) => {
    if (value !== data.post_admission_care) {
      await patchPatientDetail(data.id_admission_record, {
        post_admission_care: value,
      });

      dispatch(getPatientById(data.id_admission_record));
    }
  };

  return (
    <Row gutter={[9, 5]} className={styles.admissionContent}>
      <Col span={12} className={styles.illnessHistory}>
        <AdmissionCard
          title={t("History of Present Illness")}
          onBlur={handleBlurIllnessHistory}
          defaultContent={data.history_of_present_illness}
        />
      </Col>
      <Col span={12} className={styles.postAdmissionCare}>
        <AdmissionCard
          title={t("Post-admission care")}
          onBlur={handleBlurPostAdmissionCare}
          defaultContent={data.post_admission_care}
        />
      </Col>

      <Col span={12} className={styles.pastMedicalHistory}>
        <AdmissionCard
          title={t("Past medical history")}
          onBlur={handleBlurPastMedicalHistory}
          defaultContent={data.past_medical_history}
        />
      </Col>
      <Col span={12} className={styles.medicalHistory}>
        <AdmissionCard
          title={t("Medical history")}
          onBlur={handleBlurMedicalHistory}
          defaultContent={data.medication_history}
        />
      </Col>
    </Row>
  );
};

export default AdmissionInfo;
