import React from "react";
import notFoundImg from "../../assets/img/not-found.png";
import styles from "./NotFoundPage.module.scss";
import { Flex } from "antd";

const NotFoundPage = () => {
  return (
    <Flex align="center" justify="center" className={styles.container}>
      <img src={notFoundImg} alt="not found img" />
    </Flex>
  );
};

export default NotFoundPage;
