import { ProgressNoteEntity } from "../../../types/patient-detail/ProgressNote.model";
import { ProgressNoteReadResponse } from "../../../types/patient-detail/ProgressNoteReadResponse.model";

export const formatProgressNoteList = (
  data: ProgressNoteReadResponse[]
): ProgressNoteEntity[] => {
  return data.map((item) => {
    return {
      id: item.id,
      idAdmissionRecord: item.id_admission_record,
      assessment: item.assessment,
      createdAt: item.created_at,
      updatedAt: item.update_at,
      createdBy: item.created_by,
    };
  });
};
