import { Radio } from "antd";
import React, { ReactNode } from "react";
import "./IcuRadio.scss";
import { preventPressEnter } from "../../../utils/helpers";

interface IcuRadioProps {
  value: string;
  children: ReactNode;
  id?: string;
}

const IcuRadio = ({ value, children, id }: IcuRadioProps) => {
  return (
    <Radio
      value={value}
      id={id}
      className="icu-radio"
      onKeyDown={preventPressEnter}
    >
      {children}
    </Radio>
  );
};

export default IcuRadio;
