import { API_LOGIN, API_REFRESH_TOKEN } from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { RefreshTokenResponse } from "../../types/auth/RefreshTokenResponse.model";
import { LoginRequest } from "../../types/login/LoginRequest.model";
import { LoginResponse } from "../../types/login/LoginResponse.model";

export const login = (
  loginCredential: LoginRequest
): Promise<LoginResponse | string> => {
  return networkAdapter.post(API_LOGIN, loginCredential);
};

export const refreshToken = (): Promise<RefreshTokenResponse> => {
  return networkAdapter.post(API_REFRESH_TOKEN, {});
};
