export const DEFAULT_PATIENT_LIST_PAGE = 1;
export const DEFAULT_PATIENT_LIST_LIMIT = 10;

export const LIMIT_PATIENT_LIST_OPTIONS = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 40, label: "40" },
  { value: 50, label: "50" },
];

export const PATIENT_SEX_VALUE = {
  male: "1",
  female: "2",
  other: "3",
};

export const SORTING_OPTION = {
  admissionDateAsc: "icu_admission_date.asc",
  admissionDateDesc: "icu_admission_date.desc",
  dischargeDateAsc: "icu_discharge_date.asc",
  dischargeDateDesc: "icu_discharge_date.desc",
};
