import { useEffect, useState } from "react";
import FilterSection from "../../components/patient-list/filter-section/FilterSection";
import styles from "./PatientListPage.module.scss";
import PatientItem from "../../components/patient-list/patient-item/PatientItem";
import IcuPagination from "../../components/patient-list/pagination/PatientListPagination";
import {
  DEFAULT_PATIENT_LIST_LIMIT,
  DEFAULT_PATIENT_LIST_PAGE,
  LIMIT_PATIENT_LIST_OPTIONS,
  SORTING_OPTION,
  PATIENT_SEX_VALUE,
} from "../../constants/patient-list/patientList.constant";
import { TabFilterPatientList } from "../../types/patient-list/TabFilter.model";
import PatientSearchModal from "../../components/patient-list/patient-search-modal/PatientSearchModal";
import { PatientSearchForm } from "../../types/patient-list/PatientSearchForm.model";
import PatientRegisterModal from "../../components/patient-list/patient-register-modal/PatientRegisterModal";
import { PatientRegisterForm } from "../../types/patient-list/PatientRegisterForm.model";
import IcuHeader from "../../components/common/header/IcuHeader";
import Wrapper from "../../components/common/wrapper/Wrapper";
import IcuFooter from "../../components/common/footer/IcuFooter";
import {
  getPatientList,
  patchPatientDetail,
  registerPatient,
  searchPatient,
} from "../../services/patient/patientService";
import { transformPatientListData } from "../../utils/patient-list/PatientList.helper";
import { LoadingOverlay } from "../../components/common/loading/LoadingOverlay";
import { getMedicalData } from "../../services/medical/medicalService";
import { MedicalReadResponse } from "../../types/patient-list/MedicalReadResponse.model";
import dayjs from "dayjs";
import { DATE_FORMAT_SEND_TO_SERVER } from "../../constants/common/dateFormat.constant";
import { PatientSearchRequest } from "../../types/patient-list/PatientSearchRequest.model";
import { PatientListTabData } from "../../types/patient-list/PatientListTabData.model";
import { PatientListReadResponse } from "../../types/patient-list/PatientListReadResponse.model";
import { PATIENT_REGISTER_ERROR_MESSAGE } from "../../constants/common/errorMessage.constant";
import { useTranslation } from "react-i18next";
import { MenuInfo } from "rc-menu/es/interface";
import { LOCAL_STORAGE_KEY } from "./../../constants/common/localStorageKey.constant";
import { SortOption } from "../../types/patient-list/SortOption.model";
import { PatientEditForm } from "../../types/patient-list/PatientEditForm.model";
import { PersonalInfoEntity } from "../../types/patient-list/PersonalInfo.model";
import { TreatmentInfoEntity } from "../../types/patient-list/TreatmentInfoEntity.model";
import { PatientSex } from "../../types/patient-list/PatientSex.model";
import PatientEditModal from "../../components/patient-list/patient-edit-modal/PatientEditModal";
import IcuErrorModal from "../../components/common/error-modal/IcuErrorModal";
import "./PatientListPage.scss";
import { DOCUMENT_HEADER } from "../../constants/common/documentHeader.constant";
import {
  changeFavicon,
  formatDateBeforeSendToServer,
} from "../../utils/helpers";

const PatientListPage = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabFilterPatientList>(
    TabFilterPatientList.ADMITTED
  );

  const [tabData, setTabData] = useState<PatientListTabData>({
    admitted: {
      searchValues: {
        id: undefined,
        age: undefined,
        department: undefined,
        room: undefined,
        sex: undefined,
        ward: undefined,
        doctor: undefined,
        name: undefined,
        startDate: undefined,
        endDate: undefined,
      },
      pageInfo: {
        currentPage: DEFAULT_PATIENT_LIST_PAGE,
        limit: DEFAULT_PATIENT_LIST_LIMIT,
      },
      patientList: undefined,
      isShowRegisterModal: false,
      isShowSearchModal: false,
      sortBy: SORTING_OPTION.admissionDateDesc as SortOption,
      isShowEditModal: false,
      selectedDate: dayjs(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
    },
    discharged: {
      searchValues: {
        id: undefined,
        age: undefined,
        department: undefined,
        room: undefined,
        sex: undefined,
        ward: undefined,
        doctor: undefined,
      },
      pageInfo: {
        currentPage: DEFAULT_PATIENT_LIST_PAGE,
        limit: DEFAULT_PATIENT_LIST_LIMIT,
      },
      patientList: undefined,
      isShowRegisterModal: false,
      isShowSearchModal: false,
      sortBy: SORTING_OPTION.dischargeDateDesc as SortOption,
      isShowEditModal: false,
      selectedDate: dayjs(
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ),
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const [medicalData, setMedicalData] = useState<MedicalReadResponse>();

  const [registerErrorMessage, setRegisterErrorMessage] = useState<
    string | undefined
  >();

  const [editErrorMessage, setEditErrorMessage] = useState<
    string | undefined
  >();

  const [initialEditFormValues, setInitialEditFormValues] =
    useState<PatientEditForm>({
      id: undefined,
      name: undefined,
      sex: undefined,
      birthday: undefined,
      diagnosis: undefined,
      ward: undefined,
      room: undefined,
      admissionRecordId: undefined,
      department: [],
      doctor: [],
    });

  const [noPatientPage, setNoPatientEmptyPage] = useState(false);

  const [reAdmitErrorMessage, setReAdmitErrorMessage] = useState<
    string | undefined
  >();

  const fetchInitData = async (
    page = DEFAULT_PATIENT_LIST_PAGE,
    limit = DEFAULT_PATIENT_LIST_LIMIT,
    is_discharged = false,
    currentTab = activeTab,
    date = formatDateBeforeSendToServer(
      tabData[activeTab].selectedDate.toDate()
    )
  ) => {
    setIsLoading(true);

    let response = await getPatientList({
      page,
      limit,
      is_discharged,
      sort_by: tabData[currentTab].sortBy?.split(".")[0],
      sort_order: tabData[currentTab].sortBy?.split(".")[1],
      date,
    });

    const medicalDataRes = await getMedicalData();

    if (!medicalDataRes || !response) {
      setNoPatientEmptyPage(true);
    } else {
      setNoPatientEmptyPage(false);
    }

    setTabData((tabData) => ({
      ...tabData,
      [currentTab]: {
        ...tabData[currentTab],
        patientList: response ? transformPatientListData(response) : undefined,
      },
    }));

    setMedicalData(medicalDataRes.data);

    setIsLoading(false);
  };

  useEffect(() => {
    document.title = t(DOCUMENT_HEADER.patientList.title);
    changeFavicon(DOCUMENT_HEADER.patientList.icon);

    localStorage.removeItem(LOCAL_STORAGE_KEY.patientOrderNumber);
    fetchInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSearchingPatient = (currentTab: TabFilterPatientList) => {
    if (
      tabData[currentTab].searchValues.id === undefined &&
      tabData[currentTab].searchValues.age === undefined &&
      (tabData[currentTab].searchValues.department === undefined ||
        tabData[currentTab].searchValues.department?.length === 0) &&
      tabData[currentTab].searchValues.room === undefined &&
      tabData[currentTab].searchValues.sex === undefined &&
      tabData[currentTab].searchValues.ward === undefined &&
      tabData[currentTab].searchValues.name === undefined &&
      tabData[currentTab].searchValues.startDate === undefined &&
      tabData[currentTab].searchValues.endDate === undefined
    ) {
      return false;
    }

    return true;
  };

  const initialRegisterValues: PatientRegisterForm = {
    id: undefined,
    name: undefined,
    sex: undefined,
    birthday: undefined,
    diagnosis: undefined,
    ward: undefined,
    room: undefined,
    department: [],
    doctor: [],
  };

  const buildSearchParams = (
    currentTab: TabFilterPatientList,
    page: number,
    limit: number,
    formValues: PatientSearchForm,
    sortBy: string | undefined,
    date: dayjs.Dayjs
  ) => {
    let formattedFormValues: PatientSearchRequest = {};

    if (formValues.id !== undefined) {
      formattedFormValues.id = formValues.id;
    }
    if (formValues.sex !== undefined) {
      formattedFormValues.sex = Number(formValues.sex);
    }
    if (formValues.age !== undefined) {
      formattedFormValues.age = formValues.age;
    }
    if (formValues.ward !== undefined) {
      formattedFormValues.id_ward = formValues.ward;
    }
    if (formValues.room !== undefined) {
      formattedFormValues.id_room = formValues.room;
    }
    if (
      formValues.department !== undefined &&
      formValues.department.length > 0
    ) {
      formattedFormValues.ids_department = formValues.department.map(Number);
    }
    if (formValues.doctor !== undefined && formValues.doctor.length > 0) {
      formattedFormValues.ids_doctor = formValues.doctor.map(Number);
    }
    if (formValues.name !== undefined) {
      formattedFormValues.name = formValues.name;
    }
    if (formValues.startDate) {
      formattedFormValues.start_date = formatDateBeforeSendToServer(
        `${dayjs(formValues.startDate).format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        )} 00:00`
      );
    }

    if (formValues.endDate) {
      formattedFormValues.end_date = formatDateBeforeSendToServer(
        `${dayjs(formValues.endDate).format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        )} 00:00`
      );
    }

    const queryParams = {
      page,
      limit,
      is_discharged: currentTab === TabFilterPatientList.DISCHARGED,
      sort_order: sortBy?.split(".")[1] ?? undefined,
      sort_by: sortBy?.split(".")[0] ?? undefined,
      date: formatDateBeforeSendToServer(date.toDate()),
    };

    return {
      formattedFormValues,
      queryParams,
    };
  };

  const handleChangePageLimit = async (limit: number) => {
    setIsLoading(true);

    let response: PatientListReadResponse;

    if (isSearchingPatient(activeTab)) {
      const { formattedFormValues, queryParams } = buildSearchParams(
        activeTab,
        DEFAULT_PATIENT_LIST_PAGE,
        limit,
        tabData[activeTab].searchValues,
        tabData[activeTab].sortBy,
        tabData[activeTab].selectedDate
      );

      response = await searchPatient(formattedFormValues, queryParams);
    } else {
      response = await getPatientList({
        page: DEFAULT_PATIENT_LIST_PAGE,
        limit,
        is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
        sort_order: tabData[activeTab].sortBy?.split(".")[1] ?? undefined,
        sort_by: tabData[activeTab].sortBy?.split(".")[0] ?? undefined,
        date: formatDateBeforeSendToServer(
          tabData[activeTab].selectedDate.toDate()
        ),
      });
    }

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        patientList: transformPatientListData(response),
        pageInfo: { currentPage: DEFAULT_PATIENT_LIST_PAGE, limit },
      },
    }));

    setIsLoading(false);
  };

  const handleChangePageOffset = async (newPage: number) => {
    setIsLoading(true);

    let response: PatientListReadResponse;

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        pageInfo: { ...tabData[activeTab].pageInfo, currentPage: newPage },
      },
    }));

    if (isSearchingPatient(activeTab)) {
      const { formattedFormValues, queryParams } = buildSearchParams(
        activeTab,
        newPage,
        tabData[activeTab].pageInfo.limit,
        tabData[activeTab].searchValues,
        tabData[activeTab].sortBy,
        tabData[activeTab].selectedDate
      );

      response = await searchPatient(formattedFormValues, queryParams);

      setTabData((tabData) => ({
        ...tabData,
        [activeTab]: {
          ...tabData[activeTab],
          patientList: transformPatientListData(response),
          pageInfo: { ...tabData[activeTab].pageInfo, currentPage: newPage },
        },
      }));
    } else {
      response = await getPatientList({
        page: newPage,
        limit: tabData[activeTab].pageInfo.limit,
        is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
        sort_order: tabData[activeTab].sortBy?.split(".")[1] ?? undefined,
        sort_by: tabData[activeTab].sortBy?.split(".")[0] ?? undefined,
        date: formatDateBeforeSendToServer(
          tabData[activeTab].selectedDate.toDate()
        ),
      });
    }

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        patientList: transformPatientListData(response),
        pageInfo: { ...tabData[activeTab].pageInfo, currentPage: newPage },
      },
    }));

    setIsLoading(false);
  };

  const handleChangeTab = async (tab: TabFilterPatientList) => {
    if (tab !== activeTab) {
      setIsLoading(true);

      setActiveTab(tab);

      if (isSearchingPatient(tab)) {
        const { formattedFormValues, queryParams } = buildSearchParams(
          tab,
          tabData[tab].pageInfo.currentPage,
          tabData[tab].pageInfo.limit,
          tabData[tab].searchValues,
          tabData[tab].sortBy,
          tabData[tab].selectedDate
        );

        const response = await searchPatient(formattedFormValues, queryParams);

        setTabData((tabData) => ({
          ...tabData,
          [tab]: {
            ...tabData[tab],
            patientList: transformPatientListData(response),
          },
        }));

        setIsLoading(false);
      } else {
        let response = await getPatientList({
          page: tabData[tab].pageInfo.currentPage,
          limit: tabData[tab].pageInfo.limit,
          is_discharged: tab === TabFilterPatientList.DISCHARGED,
          sort_order: tabData[tab].sortBy?.split(".")[1] ?? undefined,
          sort_by: tabData[tab].sortBy?.split(".")[0] ?? undefined,
          date: formatDateBeforeSendToServer(
            tabData[tab].selectedDate.toDate()
          ),
        });

        setTabData((tabData) => ({
          ...tabData,
          [tab]: {
            ...tabData[tab],
            patientList: transformPatientListData(response),
          },
        }));

        setIsLoading(false);
      }
    }
  };

  const handleMovePatient = async (admissionRecordId: number) => {
    setIsLoading(true);

    if (activeTab === TabFilterPatientList.ADMITTED) {
      await patchPatientDetail(admissionRecordId, {
        is_discharged: true,
      });
    } else {
      const reAdmitResult = await patchPatientDetail(admissionRecordId, {
        is_discharged: false,
      });

      if (typeof reAdmitResult === "string") {
        setReAdmitErrorMessage(reAdmitResult);
        setIsLoading(false);
        return;
      }
    }

    let response: PatientListReadResponse;

    if (isSearchingPatient(activeTab)) {
      const { formattedFormValues, queryParams } = buildSearchParams(
        activeTab,
        DEFAULT_PATIENT_LIST_PAGE,
        tabData[activeTab].pageInfo.limit,
        tabData[activeTab].searchValues,
        tabData[activeTab].sortBy,
        tabData[activeTab].selectedDate
      );

      response = await searchPatient(formattedFormValues, queryParams);
    } else {
      response = await getPatientList({
        page: DEFAULT_PATIENT_LIST_PAGE,
        limit: tabData[activeTab].pageInfo.limit,
        is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
        sort_order: tabData[activeTab].sortBy?.split(".")[1] ?? undefined,
        sort_by: tabData[activeTab].sortBy?.split(".")[0] ?? undefined,
        date: formatDateBeforeSendToServer(
          tabData[activeTab].selectedDate.toDate()
        ),
      });
    }

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        patientList: transformPatientListData(response),
        pageInfo: {
          ...tabData[activeTab].pageInfo,
          currentPage: DEFAULT_PATIENT_LIST_PAGE,
        },
      },
    }));

    setIsLoading(false);
  };

  const handleShowRegisterModal = async () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowRegisterModal: true,
      },
    }));
  };

  const formatRegisterErrorMessage = (errorMsg: string, patientId: string) => {
    if (
      errorMsg &&
      errorMsg === PATIENT_REGISTER_ERROR_MESSAGE.duplicateId.content
    ) {
      return errorMsg.replaceAll(
        PATIENT_REGISTER_ERROR_MESSAGE.duplicateId.params,
        patientId
      );
    }

    return errorMsg;
  };

  const handleRegisterPatient = async (formValues: PatientRegisterForm) => {
    if (
      formValues.id &&
      formValues.name &&
      formValues.sex &&
      formValues.birthday &&
      formValues.diagnosis &&
      formValues.ward &&
      formValues.room &&
      formValues.department &&
      formValues.doctor
    ) {
      setIsLoading(true);

      const doctorIds: number[] = [];
      const newDoctorNames: string[] = [];

      formValues.doctor.forEach((item) => {
        if (isNaN(Number(item))) {
          newDoctorNames.push(item);
        } else {
          doctorIds.push(Number(item));
        }
      });

      const formattedFormValues = {
        patient_id: formValues.id.trim(),
        name: formValues.name,
        sex: Number(formValues.sex),
        date_of_birth: dayjs(formValues.birthday).format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        ),
        diagnosis: formValues.diagnosis,
        id_ward: formValues.ward,
        id_room: formValues.room,
        ids_department: formValues.department?.map((department) =>
          Number(department)
        ),
        ids_doctor: doctorIds,
        new_doctor_name: newDoctorNames,
      };

      const registerResponse = await registerPatient(formattedFormValues);

      if (typeof registerResponse === "string") {
        const formattedRegisterErrorMessage = formatRegisterErrorMessage(
          registerResponse,
          formValues.id
        );

        setRegisterErrorMessage(formattedRegisterErrorMessage);
        setIsLoading(false);

        return;
      }

      const medicalDataRes = await getMedicalData();

      setMedicalData(medicalDataRes.data);

      if (activeTab === TabFilterPatientList.DISCHARGED) {
        setTabData((tabData) => ({
          ...tabData,
          discharged: {
            ...tabData.discharged,
            isShowRegisterModal: false,
          },
        }));

        setIsLoading(false);
        return;
      }

      let response: PatientListReadResponse;

      if (isSearchingPatient(TabFilterPatientList.ADMITTED)) {
        const { formattedFormValues, queryParams } = buildSearchParams(
          TabFilterPatientList.ADMITTED,
          tabData.admitted.pageInfo.currentPage,
          tabData.admitted.pageInfo.limit,
          tabData.admitted.searchValues,
          tabData.admitted.sortBy,
          tabData.admitted.selectedDate
        );

        response = await searchPatient(formattedFormValues, queryParams);
      } else {
        response = await getPatientList({
          page: tabData.admitted.pageInfo.currentPage,
          limit: tabData.admitted.pageInfo.limit,
          is_discharged: false,
          sort_order: tabData.admitted.sortBy?.split(".")[1] ?? undefined,
          sort_by: tabData.admitted.sortBy?.split(".")[0] ?? undefined,
          date: formatDateBeforeSendToServer(
            tabData.admitted.selectedDate.toDate()
          ),
        });
      }

      setTabData((tabData) => ({
        ...tabData,
        admitted: {
          ...tabData.admitted,
          patientList: transformPatientListData(response),
          isShowRegisterModal: false,
        },
      }));

      setIsLoading(false);
    }
  };

  const handleShowSearchModal = async () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowSearchModal: true,
      },
    }));
  };

  const handleSubmitSearchPatientForm = async (
    formValues: PatientSearchForm
  ) => {
    setIsLoading(true);

    if (Object.values(formValues).every((item) => item === undefined)) {
      const patientListResponse = await getPatientList({
        page: DEFAULT_PATIENT_LIST_PAGE,
        limit: DEFAULT_PATIENT_LIST_LIMIT,
        is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
        sort_order: tabData[activeTab].sortBy?.split(".")[1] ?? undefined,
        sort_by: tabData[activeTab].sortBy?.split(".")[0] ?? undefined,
        date: formatDateBeforeSendToServer(
          tabData[activeTab].selectedDate.toDate()
        ),
      });

      setTabData((tabData) => ({
        ...tabData,
        [activeTab]: {
          ...tabData[activeTab],
          patientList: transformPatientListData(patientListResponse),
          pageInfo: {
            limit: DEFAULT_PATIENT_LIST_LIMIT,
            currentPage: DEFAULT_PATIENT_LIST_PAGE,
          },
          isShowSearchModal: false,
        },
      }));

      setIsLoading(false);

      return;
    }

    const { formattedFormValues, queryParams } = buildSearchParams(
      activeTab,
      DEFAULT_PATIENT_LIST_PAGE,
      tabData[activeTab].pageInfo.limit,
      formValues,
      tabData[activeTab].sortBy,
      tabData[activeTab].selectedDate
    );

    let response = await searchPatient(formattedFormValues, queryParams);

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        patientList: transformPatientListData(response),
        pageInfo: {
          ...tabData[activeTab].pageInfo,
          currentPage: DEFAULT_PATIENT_LIST_PAGE,
        },
        isShowSearchModal: false,
      },
    }));

    setIsLoading(false);
  };

  const renderEmptyScreen = () => {
    if (!isLoading) {
      if (!noPatientPage) {
        return (
          <div className={styles.noResultMessage}>
            {t("Cannot find patient!")}
          </div>
        );
      }
      return <></>;
    }

    return <></>;
  };

  const handleSortPatient = async (menuInfo: MenuInfo) => {
    if (menuInfo.key !== tabData[activeTab].sortBy) {
      setIsLoading(true);

      let response: PatientListReadResponse;

      if (isSearchingPatient(activeTab)) {
        const { formattedFormValues, queryParams } = buildSearchParams(
          activeTab,
          DEFAULT_PATIENT_LIST_PAGE,
          tabData[activeTab].pageInfo.limit,
          tabData[activeTab].searchValues,
          menuInfo.key,
          tabData[activeTab].selectedDate
        );

        response = await searchPatient(formattedFormValues, queryParams);
      } else {
        response = await getPatientList({
          page: DEFAULT_PATIENT_LIST_PAGE,
          limit: tabData[activeTab].pageInfo.limit,
          is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
          sort_order: menuInfo.key.split(".")[1],
          sort_by: menuInfo.key.split(".")[0],
          date: formatDateBeforeSendToServer(
            tabData[activeTab].selectedDate.toDate()
          ),
        });
      }

      setTabData((tabData) => ({
        ...tabData,
        [activeTab]: {
          ...tabData[activeTab],
          patientList: transformPatientListData(response),
          pageInfo: {
            ...tabData[activeTab].pageInfo,
            currentPage: DEFAULT_PATIENT_LIST_PAGE,
          },
          sortBy: menuInfo.key,
        },
      }));

      setIsLoading(false);
    }
  };

  const handleClickEditBtn = (
    personalInfo: PersonalInfoEntity,
    treatmentInfo: TreatmentInfoEntity
  ) => {
    setInitialEditFormValues({
      birthday: dayjs(personalInfo.dateOfBirth),
      department: treatmentInfo.department.map((item) => item.id.toString()),
      doctor: treatmentInfo.doctor.map((item) => item.id.toString()),
      id: personalInfo.id,
      diagnosis: treatmentInfo.diagnosis,
      name: personalInfo.name,
      room: treatmentInfo.roomId,
      sex: PATIENT_SEX_VALUE[personalInfo.sex] as PatientSex,
      ward: treatmentInfo.wardId,
      admissionRecordId: personalInfo.id_admission_record,
    });

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowEditModal: true,
      },
    }));
  };

  const handleUpdatePatient = async (formValues: PatientEditForm) => {
    if (
      formValues.id &&
      formValues.name &&
      formValues.sex &&
      formValues.birthday &&
      formValues.diagnosis &&
      formValues.ward &&
      formValues.room &&
      formValues.department &&
      formValues.doctor &&
      formValues.admissionRecordId
    ) {
      setIsLoading(true);

      const doctorIds: number[] = [];
      const newDoctorNames: string[] = [];

      formValues.doctor.forEach((item) => {
        if (isNaN(Number(item))) {
          newDoctorNames.push(item);
        } else {
          doctorIds.push(Number(item));
        }
      });

      const formattedFormValues = {
        patient_id: formValues.id,
        name: formValues.name,
        sex: Number(formValues.sex),
        date_of_birth: dayjs(formValues.birthday).format(
          DATE_FORMAT_SEND_TO_SERVER.YYYYMMDD
        ),
        diagnosis: formValues.diagnosis,
        id_ward: formValues.ward,
        id_room: formValues.room,
        ids_department: formValues.department?.map((department) =>
          Number(department)
        ),
        ids_doctor: doctorIds,
        new_doctor_name: newDoctorNames,
      };

      const updatedResponse = await patchPatientDetail(
        formValues.admissionRecordId,
        formattedFormValues
      );

      if (typeof updatedResponse === "string") {
        const formattedUpdateErrorMessage = formatRegisterErrorMessage(
          updatedResponse,
          formValues.id
        );

        setEditErrorMessage(formattedUpdateErrorMessage);
        setIsLoading(false);

        return;
      }

      const medicalDataRes = await getMedicalData();

      setMedicalData(medicalDataRes.data);

      let response: PatientListReadResponse;

      if (isSearchingPatient(activeTab)) {
        const { formattedFormValues, queryParams } = buildSearchParams(
          activeTab,
          tabData[activeTab].pageInfo.currentPage,
          tabData[activeTab].pageInfo.limit,
          tabData[activeTab].searchValues,
          tabData[activeTab].sortBy,
          tabData[activeTab].selectedDate
        );

        response = await searchPatient(formattedFormValues, queryParams);
      } else {
        response = await getPatientList({
          page: tabData[activeTab].pageInfo.currentPage,
          limit: tabData[activeTab].pageInfo.limit,
          is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
          sort_by: tabData[activeTab].sortBy?.split(".")[0],
          sort_order: tabData[activeTab].sortBy?.split(".")[1],
          date: formatDateBeforeSendToServer(
            tabData[activeTab].selectedDate.toDate()
          ),
        });
      }

      setTabData((tabData) => ({
        ...tabData,
        [activeTab]: {
          ...tabData[activeTab],
          patientList: transformPatientListData(response),
          isShowEditModal: false,
        },
      }));

      setIsLoading(false);
    }
  };

  const handleCancelReAdmitDialog = () => {
    setReAdmitErrorMessage(undefined);
  };

  const handleDateChange = async (date: string) => {
    let response: PatientListReadResponse;

    setIsLoading(true);

    if (isSearchingPatient(activeTab)) {
      const { formattedFormValues, queryParams } = buildSearchParams(
        activeTab,
        DEFAULT_PATIENT_LIST_PAGE,
        tabData[activeTab].pageInfo.limit,
        tabData[activeTab].searchValues,
        tabData[activeTab].sortBy,
        tabData[activeTab].selectedDate
      );

      response = await searchPatient(formattedFormValues, queryParams);
    } else {
      response = await getPatientList({
        page: DEFAULT_PATIENT_LIST_PAGE,
        limit: tabData[activeTab].pageInfo.limit,
        is_discharged: activeTab === TabFilterPatientList.DISCHARGED,
        sort_by: tabData[activeTab].sortBy?.split(".")[0],
        sort_order: tabData[activeTab].sortBy?.split(".")[1],
        date: formatDateBeforeSendToServer(date),
      });
    }

    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        patientList: transformPatientListData(response),
        selectedDate: dayjs(date),
        pageInfo: {
          currentPage: DEFAULT_PATIENT_LIST_PAGE,
          limit: tabData[activeTab].pageInfo.limit,
        },
      },
    }));

    setIsLoading(false);
  };

  return (
    <>
      <IcuHeader />
      <Wrapper customClassName="patient-list-wrapper">
        <div className={styles.patientListPage}>
          <FilterSection
            activeTab={activeTab}
            handleChangeTab={handleChangeTab}
            onShowSearchModal={handleShowSearchModal}
            isSearchingPatient={isSearchingPatient(activeTab)}
            onShowRegisterModal={handleShowRegisterModal}
            handleSortPatient={handleSortPatient}
            tabData={tabData}
            handleDateChange={handleDateChange}
          />
          {(tabData[activeTab].patientList?.data.length ?? 0) > 0
            ? tabData[activeTab].patientList?.data.map((patient, index) => (
                <PatientItem
                  key={patient.personalInfo.id_admission_record}
                  orderNumber={
                    tabData[activeTab].pageInfo.limit *
                      (tabData[activeTab].pageInfo.currentPage - 1) +
                    (index + 1)
                  }
                  personalInfo={patient.personalInfo}
                  treatmentInfo={patient.treatmentInfo}
                  activeTab={activeTab}
                  onMovePatient={handleMovePatient}
                  onClickEdit={handleClickEditBtn}
                />
              ))
            : renderEmptyScreen()}
          {(tabData[activeTab].patientList?.data.length ?? 0) > 0 && (
            <IcuPagination
              totalCount={tabData[activeTab].patientList?.totalData ?? 0}
              options={LIMIT_PATIENT_LIST_OPTIONS}
              onChangePageLimit={handleChangePageLimit}
              onChangePageOffset={handleChangePageOffset}
              pageInfo={tabData[activeTab].pageInfo}
            />
          )}
          {tabData[activeTab].isShowSearchModal && medicalData && (
            <PatientSearchModal
              initialValues={tabData[activeTab].searchValues}
              setTabData={setTabData}
              medicalData={medicalData}
              onSubmitSearchPatientForm={handleSubmitSearchPatientForm}
              activeTab={activeTab}
            />
          )}
          {tabData[activeTab].isShowRegisterModal && medicalData && (
            <PatientRegisterModal
              initialValues={initialRegisterValues}
              medicalData={medicalData}
              setTabData={setTabData}
              onSubmitForm={handleRegisterPatient}
              activeTab={activeTab}
              registerErrorMessage={registerErrorMessage}
              setRegisterErrorMessage={setRegisterErrorMessage}
            />
          )}
          {tabData[activeTab].isShowEditModal && medicalData && (
            <PatientEditModal
              initialValues={initialEditFormValues}
              medicalData={medicalData}
              setTabData={setTabData}
              onSubmitForm={handleUpdatePatient}
              activeTab={activeTab}
              editErrorMessage={editErrorMessage}
              setEditErrorMessage={setEditErrorMessage}
            />
          )}
        </div>
      </Wrapper>
      <IcuFooter />
      {!!reAdmitErrorMessage && (
        <IcuErrorModal
          content={t(reAdmitErrorMessage)}
          title={t("Error")}
          handleCancel={handleCancelReAdmitDialog}
          isOpen={!!reAdmitErrorMessage}
        />
      )}
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default PatientListPage;
