import { Flex, Upload } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import IcuButton from "../../common/button/IcuButton";
import styles from "./ExaminationCard.module.scss";
import { useTranslation } from "react-i18next";
import stopWatch from "../../../assets/icons/stop-watch.svg";
import filePdf from "../../../assets/icons/file-pdf.svg";
import { PatientDetailEntity } from "../../../types/common/PatientDetail.model";
import "./ExaminationCard.scss";
import { TIME_SERIES_EXAM_RESULT_PATH } from "../../../configs/routerPath";
import PatientCardInfo from "../../common/patient-card-info/PatientCardInfo";
import { LOCAL_STORAGE_KEY } from "../../../constants/common/localStorageKey.constant";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { addOpenedTabByAdmissionRecordId } from "../../../redux/slices/tabSlice";
import uploadPhoto from "../../../assets/icons/upload-photo.svg";
import { RcFile } from "antd/es/upload";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../../constants/common/dateFormat.constant";

interface ExaminationCardProps {
  data: PatientDetailEntity;
  onClickExportPdf: (
    event?: unknown,
    content?: () => React.ReactInstance | null
  ) => void;
  onUploadImageCallback?: (file: RcFile) => boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  targetDate: dayjs.Dayjs;
}

const ExaminationCard = ({
  data,
  onClickExportPdf,
  onUploadImageCallback,
  targetDate,
}: ExaminationCardProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();

  const handleClickTimeSeriesExamBtn = () => {
    const timeSeriesTab = window.open(
      `${window.location.origin}${TIME_SERIES_EXAM_RESULT_PATH}${
        targetDate ? `?date=${targetDate.format(DATE_FORMAT.YYYYMMDD)}` : ""
      }`.replaceAll(":id", data.id_admission_record.toString()),
      "_self"
    );

    dispatch(
      addOpenedTabByAdmissionRecordId({
        id: data.id_admission_record,
        tab: timeSeriesTab,
      })
    );
  };

  const orderNumber =
    localStorage.getItem(LOCAL_STORAGE_KEY.patientOrderNumber) ?? "";

  return (
    <Flex
      className={`${styles.patientItem} examination-result-personal-info-container`}
    >
      <div className={styles.orderNumber}>{orderNumber}</div>
      <div className="exam-card-patient-info">
        <PatientCardInfo
          data={data}
          gap={104}
          containerClassName="examination-result-personal-info"
        />
      </div>

      <Flex
        align="flex-end"
        className={`${styles.groupBtn} exam-card-group-btn`}
        gap={10}
        vertical
        justify="center"
      >
        <Flex gap={10}>
          <IcuButton
            width={232}
            height={40}
            icon={
              <img src={stopWatch} alt="Time series examination result icon" />
            }
            extraClassName="examination-result-time-series-btn"
            onClick={handleClickTimeSeriesExamBtn}
          >
            {t("Time series examination result")}
          </IcuButton>
          <Upload showUploadList={false} beforeUpload={onUploadImageCallback}>
            <IcuButton
              width={134}
              height={40}
              icon={<img src={uploadPhoto} alt="Upload photo icon" />}
              backgroundColor="primary-3"
              color="primary-2"
              extraClassName="upload-photo-btn"
            >
              {t("Upload photo")}
            </IcuButton>
          </Upload>
          <IcuButton
            width={40}
            height={40}
            backgroundColor="secondary"
            extraClassName="pdf-export-btn"
          >
            <img src={filePdf} alt="PDF file icon" onClick={onClickExportPdf} />
          </IcuButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExaminationCard;
