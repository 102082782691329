import { Flex } from "antd";
import CustomDatePicker from "../../../../common/custom-date-picker/CustomDatePicker";
import styles from "./ProgressNoteHeader.module.scss";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface ProgressNoteHeaderProps {
  onDateChange: (date: string) => void;
  defaultValue: dayjs.Dayjs;
}

const ProgressNoteHeader = ({
  onDateChange,
  defaultValue,
}: ProgressNoteHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Flex align="center" justify="space-between" className={styles.header}>
      <div>{t("Progress note")}</div>

      <CustomDatePicker
        onDateChange={onDateChange}
        maxDate={dayjs()}
        defaultValue={defaultValue}
      />
    </Flex>
  );
};

export default ProgressNoteHeader;
