import { ProblemListReadResponse } from "../../../types/patient-detail/ProblemListReadResponse.model";
import { ProblemItemEntity } from "../../../types/patient-detail/ProblemItem.model";

export const formatProblemList = (
  data: ProblemListReadResponse[]
): ProblemItemEntity[] => {
  return data.map((item) => {
    return {
      id: item.id,
      suspicionCheck: item.suspicion_check,
      pastMedicalHistoryCheck: item.past_medical_history_check,
      remarkEntry: item.remark_entry,
    };
  });
};
