import { createSlice } from "@reduxjs/toolkit";

interface OpeningTab {
  admissionRecordId: number;
  openedTabs: WindowProxy[];
}

interface TabState {
  tabs: OpeningTab[];
}

const initialState: TabState = {
  tabs: [],
};

const tabSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    addOpenedTabByAdmissionRecordId(state, action) {
      if (state.tabs.length === 0) {
        const newTab = {
          admissionRecordId: action.payload.id,
          openedTabs: [action.payload.tab],
        };

        state.tabs = [newTab];

        return;
      }

      const newTabs = state.tabs.map((item) => {
        if (item.admissionRecordId === action.payload.id) {
          item.openedTabs.push(action.payload.tab);
        }

        return item;
      });

      state.tabs = newTabs;
    },
    closeAllTabsByAdmissionRecordId(state, action) {
      const tabByAdmissionRecordId = state.tabs.find(
        (item) => item.admissionRecordId === action.payload.id
      );

      if (tabByAdmissionRecordId) {
        tabByAdmissionRecordId.openedTabs.forEach((openedTab) => {
          if (openedTab && !openedTab.closed) {
            openedTab.close();
          }
        });

        state.tabs = state.tabs.filter(
          (item) =>
            item.admissionRecordId !== tabByAdmissionRecordId.admissionRecordId
        );
      }
    },
  },
});

export const {
  addOpenedTabByAdmissionRecordId,
  closeAllTabsByAdmissionRecordId,
} = tabSlice.actions;

export default tabSlice.reducer;
