import { ReactNode } from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import {
  EXAMINATION_RESULT_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PATIENT_DETAIL_PATH,
  PATIENT_LIST_PATH,
  TIME_SERIES_EXAM_RESULT_PATH,
  VITAL_SIGN_PATH,
} from "./configs/routerPath";
import PrivateRoute from "./components/common/private-route/PrivateRoute";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import { LOCAL_STORAGE_KEY } from "./constants/common/localStorageKey.constant";
import LoginPage from "./pages/login/LoginPage";
import PatientListPage from "./pages/patient-list/PatientListPage";
import PatientDetailPage from "./pages/patient-detail/PatientDetailPage";
import ExaminationResultPage from "./pages/examination-result/ExaminationResultPage";
import TimeSeriesExamResultPage from "./pages/time-series-exam-result/TimeSeriesExamResultPage";
import VitalSignPage from "./pages/vital-sign/VitalSignPage";

const renderPageWithPersonalInfo = (element: ReactNode) =>
  localStorage.getItem(LOCAL_STORAGE_KEY.patientOrderNumber) ? (
    element
  ) : (
    <Navigate to={PATIENT_LIST_PATH} />
  );

export default createBrowserRouter([
  {
    path: HOME_PATH,
    element: <LoginPage />,
  },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: PATIENT_LIST_PATH,
        element: <PatientListPage />,
      },
      {
        path: PATIENT_DETAIL_PATH,
        element: <PatientDetailPage />,
      },
      {
        path: EXAMINATION_RESULT_PATH,
        element: renderPageWithPersonalInfo(<ExaminationResultPage />),
      },
      {
        path: TIME_SERIES_EXAM_RESULT_PATH,
        element: renderPageWithPersonalInfo(<TimeSeriesExamResultPage />),
      },
      {
        path: VITAL_SIGN_PATH,
        element: renderPageWithPersonalInfo(<VitalSignPage />),
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);
