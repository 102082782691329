import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import nextIcon from "../../../assets/icons/next-icon.svg";
import previousIcon from "../../../assets/icons/previous-icon.svg";
import styles from "./CustomDatePicker.module.scss";
import "./CustomDatePicker.scss";
import { useEffect, useState } from "react";
import { DATE_FORMAT } from "./../../../constants/common/dateFormat.constant";

interface CustomDatePickerProps {
  defaultValue: Dayjs;
  onDateChange: (date: string) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  handleClickPrevDateBtn?: (date: Dayjs) => void;
  handleClickNextDateBtn?: (date: Dayjs) => void;
}

const CustomDatePicker = ({
  defaultValue,
  onDateChange,
  minDate,
  maxDate,
  handleClickPrevDateBtn,
  handleClickNextDateBtn,
}: CustomDatePickerProps) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue.isSame(value)) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onClickPrevDateBtn = () => {
    if (
      minDate &&
      value.format(DATE_FORMAT.YYYYMMDD) ===
        minDate.format(DATE_FORMAT.YYYYMMDD)
    ) {
      return;
    }
    const newDate = value.subtract(1, "day");

    if (handleClickPrevDateBtn) {
      handleClickPrevDateBtn(newDate);

      return;
    }

    setValue(newDate);

    onDateChange(newDate.format(DATE_FORMAT.YYYYMMDD));
  };

  const onClickNextDateBtn = () => {
    if (
      maxDate &&
      value.format(DATE_FORMAT.YYYYMMDD) ===
        maxDate.format(DATE_FORMAT.YYYYMMDD)
    ) {
      return;
    }

    const newDate = value.add(1, "day");

    if (handleClickNextDateBtn) {
      handleClickNextDateBtn(newDate);

      return;
    }

    setValue(newDate);

    onDateChange(newDate.format(DATE_FORMAT.YYYYMMDD));
  };

  const handleChangeDate = (date: dayjs.Dayjs) => {
    setValue(date);

    onDateChange(date.toISOString());
  };

  return (
    <div
      className={`${styles.progressNoteDatePickerContainer} icu-custom-date-picker`}
    >
      <img
        src={previousIcon}
        alt="previous icon"
        className={`${styles.prevDateBtn} ${
          minDate &&
          value.format(DATE_FORMAT.YYYYMMDD) ===
            minDate.format(DATE_FORMAT.YYYYMMDD)
            ? styles.notAllowCursor
            : ""
        }`}
        onClick={onClickPrevDateBtn}
      />
      <DatePicker
        suffixIcon={<span></span>}
        allowClear={false}
        variant="borderless"
        className="progress-note-date-picker"
        value={value}
        minDate={minDate}
        maxDate={maxDate}
        format={DATE_FORMAT.YYYYMMDD}
        onChange={handleChangeDate}
      />
      <img
        src={nextIcon}
        alt="next icon"
        className={`${styles.nextDateBtn} ${
          maxDate &&
          value.format(DATE_FORMAT.YYYYMMDD) ===
            maxDate.format(DATE_FORMAT.YYYYMMDD)
            ? styles.notAllowCursor
            : ""
        }`}
        onClick={onClickNextDateBtn}
      />
    </div>
  );
};

export default CustomDatePicker;
