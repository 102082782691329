import { Flex, Tag } from "antd";
import styles from "./PatientCardInfo.module.scss";
import { useTranslation } from "react-i18next";
import { BACKGROUND_COLOR_VALUE } from "../../../constants/common/color.constant";
import "./PatientCardInfo.scss";
import { PatientDetailEntity } from "../../../types/common/PatientDetail.model";

interface PatientCardInfoProps {
  gap?: number;
  containerClassName?: string;
  data: PatientDetailEntity;
}

const PatientCardInfo = ({
  data,
  gap = 32,
  containerClassName = "",
}: PatientCardInfoProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      className={`${styles.personalInfo} ${containerClassName}`}
      gap={gap}
      align="center"
    >
      <Flex>
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("Name")}</div>
          <div>{t("Ward")}</div>
        </Flex>
        <Flex vertical gap={10}>
          <div>: {data.name}</div>
          <div>: {data.ward.name}</div>
        </Flex>
      </Flex>

      <Flex>
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("Department")}</div>
          <div>{t("Room")}</div>
        </Flex>
        <Flex vertical gap={10}>
          <div>
            :{" "}
            {data.departments.map((department) => (
              <Tag
                className="department-tag"
                color={BACKGROUND_COLOR_VALUE.secondary}
                key={department.id}
              >
                {department.name}
              </Tag>
            ))}
          </div>
          <div>: {data.room.name}</div>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PatientCardInfo;
