import { Flex, Tooltip } from "antd";
import React from "react";
import simCard from "../../../assets/icons/sim-card.svg";
import user from "../../../assets/icons/user.svg";
import agenda from "../../../assets/icons/agenda.svg";
import styles from "./PersonalInfo.module.scss";
import { PersonalInfoEntity } from "../../../types/patient-list/PersonalInfo.model";
import { useTranslation } from "react-i18next";

interface PersonalInfoProps {
  data: PersonalInfoEntity;
  hidePrefixIcon?: boolean;
  gap?: number;
  containerClassName?: string;
  enableEllipsis?: boolean;
}

const PersonalInfo = ({
  data,
  hidePrefixIcon = false,
  gap = 32,
  containerClassName = "",
  enableEllipsis = false,
}: PersonalInfoProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      className={`${styles.personalInfo} ${containerClassName}`}
      gap={gap}
      align="center"
    >
      <Flex>
        {!hidePrefixIcon && (
          <Flex vertical gap={10}>
            <img src={simCard} className={styles.icon} alt="id icon" />
            <img src={user} className={styles.icon} alt="name icon" />
          </Flex>
        )}
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("ID")}</div>
          <div>{t("Name")}</div>
        </Flex>
        <Flex className={styles.valueColumn} vertical gap={10}>
          {enableEllipsis ? (
            <>
              <Tooltip title={data.id} placement="topLeft">
                <div className={styles.idValue}>: {data.id}</div>
              </Tooltip>
              <Tooltip title={data.name} placement="topLeft">
                <div className={styles.nameValue}>: {data.name}</div>
              </Tooltip>
            </>
          ) : (
            <>
              <div>: {data.id}</div>
              <div>: {data.name}</div>
            </>
          )}
        </Flex>
      </Flex>

      <Flex>
        {!hidePrefixIcon && (
          <Flex vertical gap={10}>
            <img src={user} className={styles.icon} alt="sex icon" />
            <img src={agenda} className={styles.icon} alt="agenda icon" />
          </Flex>
        )}
        <Flex vertical className={styles.label} gap={10}>
          <div>{t("Sex")}</div>
          <div>{t("Age")}</div>
        </Flex>
        <Flex vertical className={styles.valueColumn} gap={10}>
          {enableEllipsis ? (
            <>
              <Tooltip title={data.sex}>
                <div className={styles.sexValue}>: {data.sex}</div>
              </Tooltip>
              <Tooltip title={data.age}>
                <div className={styles.ageValue}>: {data.age}</div>
              </Tooltip>
            </>
          ) : (
            <>
              <div>: {data.sex}</div>
              <div>: {data.age}</div>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PersonalInfo;
