export enum ExaminationCategory {
  BIOCHEMISTRY = "biochemistry",
  BLOOD_COUNT = "bloodCount",
  COAGULATION = "coagulation",
  BLOOD_GAS_ANALYSIS = "bloodGasAnalysis",
  URINARY_ANALYSIS = "urinaryAnalysis",
}

export const EXAMINATION_CATEGORY_VALUE = {
  biochemistry: 1,
  bloodCount: 2,
  coagulation: 3,
  bloodGasAnalysis: 4,
  urinaryAnalysis: 5,
};
