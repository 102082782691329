export const COLOR = {
  "primary-2": "primary-2",
  "primary-3": "primary-3",
  light: "light",
  body: "body",
  "grey-6": "grey-6",
  danger: "danger",
  success: "success",
  title: "title",
  grey: "grey",
  abp: "abp",
  "grey-2": "grey-2",
  "grey-9": "grey-9",
  "grey-3": "grey-3",
  "grey-7": "grey-7",
  "primary-1": "primary-1",
  "grey-1": "grey-1",
  "grey-8": "grey-8",
  border: "border",
  hr: "hr",
  rr: "rr",
  nibp: "nibp",
  spo2: "spo2",
  "grey-4": "grey-4",
};

export const BACKGROUND_COLOR = {
  light: "light",
  secondary: "secondary",
};

export const BACKGROUND_COLOR_VALUE = {
  secondary: "#E4E6EF",
  grey3: "#80808F",
};

export const COLOR_VALUE = {
  grey1: "#1B283F",
};
