import React from "react";
import RequiredField from "../../common/required-field/RequiredField";
import IcuButton from "../../common/button/IcuButton";
import Logo from "../../../assets/img/txp-medical-logo.png";
import styles from "./LoginForm.module.scss";
import { IcuTextInput } from "../../common/text-input/IcuTextInput";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormEntity } from "../../../types/login/LoginForm.model";
import "./LoginForm.scss";
import { Flex, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  PASSWORD_VALIDATION_REGEX,
  USERNAME_VALIDATION_REGEX,
} from "../../../constants/login/login.constant";
import { preventPressEnter } from "../../../utils/helpers";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";

interface LoginFormProps {
  initialValues: LoginFormEntity;
  onLogin: (formValues: LoginFormEntity) => void;
}

const schema = yup.object({
  username: yup
    .string()
    .required()
    .test({
      name: "username",
      test: (value) => {
        return !USERNAME_VALIDATION_REGEX.test(value);
      },
    }),
  password: yup.string().required().min(9).matches(PASSWORD_VALIDATION_REGEX),
});

const renderEyeIcon = (visible: boolean) =>
  visible ? <EyeFilled /> : <EyeInvisibleFilled />;

const LoginForm = ({ initialValues, onLogin }: LoginFormProps) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<LoginFormEntity>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const currentUserName = watch("username");
  const currentPassword = watch("password");

  const isSubmitBtnDisabled = !currentUserName || !currentPassword;

  const handleLogin = async (formValues: LoginFormEntity) => {
    try {
      onLogin(formValues);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className={styles.loginForm} onSubmit={handleSubmit(handleLogin)}>
      <Flex vertical align="center">
        <div className={styles.logo}>
          <img src={Logo} alt="txp medical logo" />
        </div>

        <div className={styles.headingSection}>
          <div className={styles.heading}>{t("Welcome to TXP Medical")}</div>
          <div className={styles.subHeading}>{t("Log In to your account")}</div>
        </div>

        <div className={styles.formSection}>
          <div className="login-username-container">
            <div className={styles.userNameLabel}>
              <span>{t("Username")} </span>
              <RequiredField />
            </div>
            <Controller
              name="username"
              control={control}
              render={({ field }) => {
                return (
                  <IcuTextInput
                    width={500}
                    height={42}
                    {...field}
                    customRootClassName="login-username-input"
                  />
                );
              }}
            />
          </div>

          <div className="login-password-container">
            <div className={styles.passwordLabel}>
              <span>{t("Password")} </span>
              <RequiredField />
            </div>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input.Password
                  className="bg-light border-none login-password-input"
                  onKeyDown={preventPressEnter}
                  visibilityToggle
                  iconRender={renderEyeIcon}
                  {...field}
                />
              )}
            />
            {errors.username || errors.password ? (
              <ErrorMessage extraClassName="login-error-msg">
                {t("Username or password is incorrect!")}
              </ErrorMessage>
            ) : (
              <></>
            )}
          </div>

          <div className={`${styles.loginBtn} login-btn`}>
            <IcuButton
              width={500}
              height={42}
              loading={isSubmitting}
              disabled={isSubmitBtnDisabled}
              htmlType="submit"
              extraClassName="login-submit-btn"
            >
              {t("Log In")}
            </IcuButton>
          </div>
        </div>
      </Flex>
    </form>
  );
};

export default LoginForm;
