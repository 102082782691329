import { Dropdown, Flex, Upload } from "antd";
import styles from "./UploadImageButton.module.scss";
import "./UploadImageButton.scss";
import uploadPhoto from "../../../assets/icons/upload-photo.svg";
import { RcFile } from "antd/es/upload";
import IcuButton from "../../common/button/IcuButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface UploadImageButtonProps {
  onUploadVitalSignImage: (file: RcFile) => boolean;
  onUploadVetilatorSettingImage: (file: RcFile) => boolean;
}

const UploadImageButton = ({
  onUploadVitalSignImage,
  onUploadVetilatorSettingImage,
}: UploadImageButtonProps) => {
  const { t } = useTranslation();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  const renderDropdown = () => (
    <div className={styles.dropdownContainer}>
      <Flex vertical align="center" gap={16}>
        <Upload showUploadList={false} beforeUpload={onUploadVitalSignImage}>
          <IcuButton
            width={162}
            height={34}
            icon={<img src={uploadPhoto} alt="Vital signs icon" />}
            backgroundColor="primary-3"
            color="primary-2"
            extraClassName="vital-sign-upload-btn"
            onClick={toggleDropdown}
          >
            {t("Vital signs")}
          </IcuButton>
        </Upload>

        <Upload
          showUploadList={false}
          beforeUpload={onUploadVetilatorSettingImage}
        >
          <IcuButton
            width={162}
            height={34}
            icon={<img src={uploadPhoto} alt="Ventilator settings icon" />}
            backgroundColor="primary-3"
            color="primary-2"
            extraClassName="ventilator-setting-upload-btn"
            onClick={toggleDropdown}
          >
            {t("Ventilator settings")}
          </IcuButton>
        </Upload>
      </Flex>
    </div>
  );

  return (
    <Dropdown
      className={styles.dropdownMenu}
      trigger={["click"]}
      dropdownRender={renderDropdown}
      open={isOpenDropdown}
    >
      <IcuButton
        width={134}
        height={34}
        icon={<img src={uploadPhoto} alt="Upload photo icon" />}
        backgroundColor="primary-3"
        color="primary-2"
        extraClassName="upload-photo-btn"
        onClick={toggleDropdown}
      >
        {t("Upload photo")}
      </IcuButton>
    </Dropdown>
  );
};

export default UploadImageButton;
