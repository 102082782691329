import { Divider, Flex, Modal, Radio } from "antd";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PatientRegisterForm } from "../../../types/patient-list/PatientRegisterForm.model";
import IcuButton from "../../common/button/IcuButton";
import { IcuDateInput } from "../../common/date-input/IcuDateInput";
import IcuRadio from "../../common/radio/IcuRadio";
import { IcuSelect } from "../../common/select/IcuSelect";
import { IcuTextInput } from "../../common/text-input/IcuTextInput";
import styles from "./PatientRegisterModal.module.scss";
import "./PatientRegisterModal.scss";
import { MedicalDataReadResponse } from "../../../types/patient-list/MedicalDataReadResponse.model";
import {
  filterFreeDoctorOptions,
  getNewDoctorSelectOptions,
} from "../../../utils/patient-detail/patient-register-modal/PatientRegisterModal.helper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATIENT_SEX_VALUE } from "../../../constants/patient-list/patientList.constant";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import IcuErrorModal from "../../common/error-modal/IcuErrorModal";
import { DefaultOptionType } from "antd/es/select";
import { PatientListTabData } from "../../../types/patient-list/PatientListTabData.model";
import { TabFilterPatientList } from "../../../types/patient-list/TabFilter.model";
import dayjs from "dayjs";

interface PatientRegisterModalProps {
  initialValues: PatientRegisterForm;
  medicalData: MedicalDataReadResponse;
  onSubmitForm: (formValues: PatientRegisterForm) => void;
  setTabData: Dispatch<SetStateAction<PatientListTabData>>;
  activeTab: TabFilterPatientList;
  registerErrorMessage: string | undefined;
  setRegisterErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

const PatientRegisterModal = ({
  initialValues,
  setTabData,
  medicalData,
  onSubmitForm,
  activeTab,
  registerErrorMessage,
  setRegisterErrorMessage,
}: PatientRegisterModalProps) => {
  const { t } = useTranslation();

  const schema = yup.object({
    id: yup.string().required(t("ID is required")),
    name: yup.string().required(t("Name is required")),
    sex: yup.string().required(t("Sex is required")),
    birthday: yup.string().required(t("Birthday is required")),
    diagnosis: yup.string().required(t("Diagnosis is required")),
    ward: yup.number().required(t("Ward is required")),
    room: yup.number().required(t("Room is required")),
    department: yup.array().min(1, t("Department is required")),
    doctor: yup.array().min(1, t("Doctor is required")),
  });

  const wardOptions = useMemo(() => {
    return medicalData.ward.map((ward) => ({
      label: ward.name,
      value: ward.id,
    }));
  }, [medicalData.ward]);

  const [roomOptions, setRoomOptions] = useState<DefaultOptionType[]>();

  const departmentOptions = useMemo(() => {
    return medicalData.department.map((department) => ({
      label: department.name,
      value: department.id.toString(),
    }));
  }, [medicalData.department]);

  const [doctorOptions, setDoctorOptions] = useState<DefaultOptionType[]>();

  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<PatientRegisterForm>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onResetForm = () => {
    reset();
    setRoomOptions(undefined);
  };

  const onCancel = () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowRegisterModal: false,
      },
    }));
  };

  const onWardChange = (
    value: number,
    fieldHelper: ControllerRenderProps<PatientRegisterForm, "ward">
  ) => {
    const newRoomOptions =
      medicalData.ward
        .find((ward) => ward.id === value)
        ?.rooms.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];

    setRoomOptions(newRoomOptions);
    setValue("room", undefined);

    fieldHelper.onChange(value);
  };

  const onDepartmentChange = async (
    value: string[],
    fieldHelper: ControllerRenderProps<PatientRegisterForm, "department">
  ) => {
    if (value.length > 0) {
      clearErrors("department");
      const currentSelectedDoctorIds = getValues("doctor") ?? [];

      const newDoctorOptions = getNewDoctorSelectOptions(
        medicalData.department,
        value,
        currentSelectedDoctorIds
      );

      if (currentSelectedDoctorIds.length > 0 && newDoctorOptions.length) {
        const newSelectedDoctor = newDoctorOptions
          .filter((option) => currentSelectedDoctorIds.includes(option.value))
          .map((option) => option.value);

        setValue("doctor", newSelectedDoctor);
      }

      setDoctorOptions(newDoctorOptions);
    } else {
      let currentSelectedDoctors = getValues("doctor") ?? [];

      if (currentSelectedDoctors.length > 0) {
        const freeDoctorOptions = filterFreeDoctorOptions(
          medicalData.department,
          currentSelectedDoctors
        );

        currentSelectedDoctors = freeDoctorOptions.map((item) => item.value);

        if (currentSelectedDoctors.length === 0) {
          errors.department = {
            message: t("Department is required"),
          };
          errors.doctor = {
            message: t("Doctor is required"),
          };
        } else {
          errors.department = {
            message: t("Department is required"),
          };
        }

        setValue("doctor", currentSelectedDoctors);
        setDoctorOptions(freeDoctorOptions);
      } else {
        setValue("doctor", []);
        setDoctorOptions(undefined);
      }
    }

    fieldHelper.onChange(value);
  };

  const onDoctorChange = (
    selectedValue: string[],
    fieldHelper: ControllerRenderProps<PatientRegisterForm, "doctor">
  ) => {
    const currentSelectedDepartment = getValues("department") ?? [];
    const currentSelectedDoctor = getValues("doctor") ?? [];

    if (selectedValue.length > 0) {
      clearErrors("doctor");
    } else {
      errors.doctor = {
        message: t("Doctor is required"),
      };
    }

    if (selectedValue.length === 0 && currentSelectedDepartment.length === 0) {
      setDoctorOptions(undefined);
    }
    const freeDoctorOptions = filterFreeDoctorOptions(
      medicalData.department,
      currentSelectedDoctor
    );

    if (freeDoctorOptions.length > 0) {
      const notSelectedFreeDoctorOptions = freeDoctorOptions
        .filter((item) => !selectedValue.includes(item.value))
        .map((item) => item.value);

      const newDoctorOptions = doctorOptions?.filter(
        (option) =>
          typeof option.value === "string" &&
          !notSelectedFreeDoctorOptions.includes(option.value)
      );

      setDoctorOptions(newDoctorOptions);
    }

    fieldHelper.onChange(selectedValue);
  };

  const handleCloseErrorModal = () => {
    setRegisterErrorMessage(undefined);
  };

  const currentId = watch("id");
  const currentName = watch("name");
  const currentSex = watch("sex");
  const currentBirthday = watch("birthday");
  const currentIllness = watch("diagnosis");
  const currentWard = watch("ward");
  const currentRoom = watch("room");
  const currentDepartment = watch("department");
  const currentDoctor = watch("doctor");

  const isSubmitBtnDisabled =
    !currentId ||
    !currentName ||
    !currentSex ||
    !currentBirthday ||
    !currentIllness ||
    !currentWard ||
    !currentRoom ||
    currentDepartment?.length === 0 ||
    currentDoctor?.length === 0;

  return (
    <>
      <Modal
        title={
          <div className={styles.patientRegisterTitle}>
            {t("Patient Register")}
          </div>
        }
        open={true}
        onCancel={onCancel}
        width={751}
        centered
        className="patient-register-modal"
        maskClosable={false}
      >
        <form className={styles.patientSearchForm} id="patient-register-form">
          <Flex justify="space-between">
            <div className={styles.personalSection}>
              <div>
                <div className={styles.idLabel}>
                  <span>{t("ID")} </span>
                </div>
                <Controller
                  name="id"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.id?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.id?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.nameLabel}>
                  <span>{t("Name")} </span>
                </div>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.name?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.name?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.sexLabel}>
                  <span>{t("Sex")} </span>
                </div>
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Radio.Group {...field}>
                        <IcuRadio value={PATIENT_SEX_VALUE.male}>
                          {t("Male")}
                        </IcuRadio>
                        <IcuRadio value={PATIENT_SEX_VALUE.female}>
                          {t("Female")}
                        </IcuRadio>
                        <IcuRadio value={PATIENT_SEX_VALUE.other}>
                          {t("Other")}
                        </IcuRadio>
                      </Radio.Group>
                    );
                  }}
                />
                {errors.sex?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.sex?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.birthdayLabel}>
                  <span>{t("Birthday")} </span>
                </div>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuDateInput
                        width={313.5}
                        height={42}
                        placeholder="yyyy/MM/dd"
                        maxDate={dayjs()}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.birthday?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.birthday?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <Divider
              type="vertical"
              className="patient-register-divider"
              dashed
            />

            <div className={styles.treatmentSection}>
              <div>
                <div className={styles.illnessLabel}>
                  <span>{t("Diagnosis")} </span>
                </div>
                <Controller
                  name="diagnosis"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.diagnosis?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.diagnosis?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.wardLabel}>
                  <span>{t("Ward")} </span>
                </div>
                <Controller
                  name="ward"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={wardOptions}
                        {...field}
                        onChange={(value) => onWardChange(value, field)}
                      />
                    );
                  }}
                />
                {errors.ward?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.ward?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.roomLabel}>
                  <span>{t("Room")} </span>
                </div>
                <Controller
                  name="room"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={roomOptions}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.room?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.room?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.departmentLabel}>
                  <span>{t("Department")} </span>
                </div>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={departmentOptions}
                        {...field}
                        onChange={(value) => onDepartmentChange(value, field)}
                        mode="multiple"
                        maxCount={3}
                      />
                    );
                  }}
                />
                {errors.department?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.department?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.departmentLabel}>
                  <span>{t("Doctor")} </span>
                </div>
                <Controller
                  name="doctor"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={doctorOptions}
                        {...field}
                        onChange={(value) => onDoctorChange(value, field)}
                        mode="tags"
                        maxCount={3}
                        value={watch("doctor")}
                      />
                    );
                  }}
                />
                {errors.doctor?.message ? (
                  <ErrorMessage extraClassName="patient-register-error-msg">
                    {t(errors.doctor?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Flex>
          <Divider className={styles.footerDivider} />
          <Flex justify="flex-end" gap={10}>
            <IcuButton
              onClick={() => onResetForm()}
              width={92}
              height={44}
              backgroundColor="light"
              color="grey-3"
              extraClassName="patient-register-reset-btn"
            >
              {t("Reset")}
            </IcuButton>
            <IcuButton
              onClick={handleSubmit(onSubmitForm)}
              width={92}
              height={44}
              htmlType="submit"
              disabled={isSubmitBtnDisabled}
            >
              {t("Confirm")}
            </IcuButton>
          </Flex>
        </form>
      </Modal>
      {registerErrorMessage !== undefined && (
        <IcuErrorModal
          content={t(registerErrorMessage)}
          title="Error"
          handleCancel={handleCloseErrorModal}
          isOpen={registerErrorMessage !== undefined}
        />
      )}
    </>
  );
};

export default PatientRegisterModal;
