import { DATE_FORMAT } from "../../constants/common/dateFormat.constant";
import { PatientListReadResponse } from "../../types/patient-list/PatientListReadResponse.model";
import { convertSexValueToString, convertUTCtoLocalDate } from "../helpers";
import dayjs from "dayjs";

export const transformPatientListData = (
  patientListReadResponse: PatientListReadResponse
) => {
  const patientList = patientListReadResponse.data.map((item) => {
    return {
      personalInfo: {
        id: item.id,
        id_admission_record: item.id_admission_record,
        name: item.name,
        sex: convertSexValueToString(item.sex.toString()),
        age: dayjs().diff(dayjs(item.date_of_birth), "year"),
        dateOfBirth: item.date_of_birth,
      },
      treatmentInfo: {
        diagnosis: item.diagnosis,
        dayStay: item.is_discharged
          ? dayjs(item.icu_discharge_date).diff(
              dayjs(item.icu_admission_date),
              "day"
            ) + 1
          : dayjs().diff(dayjs(item.icu_admission_date), "day") + 1,
        doctor: item.doctors,
        ward: item.ward.name,
        room: item.room.name,
        department: item.departments,
        roomId: item.room.id,
        wardId: item.ward.id,
        admissionDate: convertUTCtoLocalDate(
          item.icu_admission_date,
          DATE_FORMAT.YYYYMMDD
        ),
        is_discharged: item.is_discharged,
        dischargeDate: item.is_discharged
          ? convertUTCtoLocalDate(item.icu_discharge_date, DATE_FORMAT.YYYYMMDD)
          : "",
      },
    };
  });

  return {
    data: patientList,
    totalPage: patientListReadResponse.total_page,
    totalData: patientListReadResponse.total_data,
  };
};
