import { useEffect, useRef, useState } from "react";
import { Input, InputRef } from "antd";
import { TimeSeriesExamTableRow } from "../../../../types/time-series-exam-table/TimeSeriesExamTableRow.model";
import "./TimeSeriesExamEditableCell.scss";
import { isEqual } from "lodash";

interface TimeSeriesExamEditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof TimeSeriesExamTableRow;
  record: TimeSeriesExamTableRow;
  id: number;
  handleUpdateData: (
    id: number,
    value: string | null,
    newRowData: {
      [key: string]: string | number;
    },
    createdAt: string | number
  ) => void;
}

export const TimeSeriesExamEditableCell: React.FC<
  React.PropsWithChildren<TimeSeriesExamEditableCellProps>
> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  id,
  handleUpdateData,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const [inputValue, setInputValue] = useState<string | null>(() => {
    if (record?.[dataIndex] !== undefined && record?.[dataIndex] !== null) {
      return record[dataIndex].toString();
    }

    return null;
  });
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (
      record !== undefined &&
      !isEqual(Number(record[dataIndex]), inputValue)
    ) {
      setInputValue(
        record[dataIndex] !== null && record[dataIndex] !== undefined
          ? record[dataIndex].toString()
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  const handleSaveData = (value: string) => {
    const newValue = value === "" ? null : value;
    const newRowData = {
      ...record,
      [dataIndex]: value === "" ? "-" : value,
    };

    setInputValue(value === "" ? null : value);

    if (
      (!record[dataIndex] && newValue !== null) ||
      (!!record[dataIndex] && newValue === null) ||
      (!!record[dataIndex] &&
        newValue !== null &&
        newValue !== record[dataIndex])
    ) {
      handleUpdateData(id, newValue, newRowData, dataIndex);
    }
    setEditing(!editing);
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <div>
        <Input
          ref={inputRef}
          onBlur={(e) => handleSaveData(e.target.value)}
          value={inputValue ?? undefined}
          rootClassName="time-series-exam-cell-input"
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={(e: any) => {
            if (e.keyCode === 13) {
              inputRef.current?.blur();
            }
          }}
        />
      </div>
    ) : (
      <div onClick={toggleEdit}>{children}</div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};
