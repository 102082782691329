import {
  DATE_FORMAT,
  DATE_FORMAT_SEND_TO_SERVER,
} from "../constants/common/dateFormat.constant";
import { LOCAL_STORAGE_KEY } from "../constants/common/localStorageKey.constant";
import { PATIENT_SEX_VALUE } from "../constants/patient-list/patientList.constant";
import { PatientSex } from "../types/patient-list/PatientSex.model";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// bind url with params
export const bindParams = (str: string, params: any = {}) => {
  let result = str;
  for (let key in params) {
    result = result.replace(new RegExp(`:${key}`, "g"), params[key]);
  }
  return result;
};

export const scrollToErrorField = ({ errorFields }: any) => {
  const el = document.getElementById(errorFields[0].name[0]) as HTMLElement;
  el.scrollIntoView();
  window.scrollTo(0, window.scrollY - el.offsetHeight - 300);
};

export const checkLogin = () => {
  return !!localStorage.getItem(LOCAL_STORAGE_KEY.accessToken);
};

export const preventPressEnter = (e: React.KeyboardEvent<HTMLElement>) => {
  if (e.key === "Enter") {
    e.preventDefault();
  }
};

export const convertSexValueToString = (value: string) => {
  switch (value) {
    case PATIENT_SEX_VALUE.male:
      return PatientSex.MALE;
    case PATIENT_SEX_VALUE.female:
      return PatientSex.FEMALE;
    default:
      return PatientSex.OTHER;
  }
};

export const convertUTCtoLocalDate = (
  date: string,
  targetFormat = DATE_FORMAT.YYYYMMDDHHmm
) => {
  return dayjs.utc(date).local().format(targetFormat);
};

export const formatDateBeforeSendToServer = (
  date: string | Date,
  format = DATE_FORMAT_SEND_TO_SERVER.YYYYMMDDHHmmss
) => {
  return dayjs.tz(date, dayjs.tz.guess()).utc().format(format);
};

export const convertUTCtoLocalDateWithNoFormat = (date: string) => {
  return dayjs.utc(date).local();
};

export const changeFavicon = (newFaviconUrl: string) => {
  const link = document.querySelector("link[rel='icon']") as HTMLAnchorElement;

  link.href = newFaviconUrl;

  document.getElementsByTagName("head")[0].appendChild(link);
};

export const generateRandomNumber = () => {
  const crypto = window.crypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array);
};
