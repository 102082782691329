import { Divider, Flex, Modal, Radio } from "antd";
import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import IcuButton from "../../common/button/IcuButton";
import { IcuDateInput } from "../../common/date-input/IcuDateInput";
import IcuRadio from "../../common/radio/IcuRadio";
import { IcuSelect } from "../../common/select/IcuSelect";
import { IcuTextInput } from "../../common/text-input/IcuTextInput";
import styles from "./PatientEditModal.module.scss";
import "./PatientEditModal.scss";
import { MedicalDataReadResponse } from "../../../types/patient-list/MedicalDataReadResponse.model";
import {
  filterFreeDoctorOptions,
  getNewDoctorSelectOptions,
} from "../../../utils/patient-detail/patient-register-modal/PatientRegisterModal.helper";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PATIENT_SEX_VALUE } from "../../../constants/patient-list/patientList.constant";
import ErrorMessage from "../../common/error-message/ErrorMessage";
import IcuErrorModal from "../../common/error-modal/IcuErrorModal";
import { DefaultOptionType } from "antd/es/select";
import { PatientListTabData } from "../../../types/patient-list/PatientListTabData.model";
import { TabFilterPatientList } from "../../../types/patient-list/TabFilter.model";
import dayjs from "dayjs";
import { PatientEditForm } from "../../../types/patient-list/PatientEditForm.model";
import { isEqual } from "lodash";

interface PatientEditModalProps {
  initialValues: PatientEditForm;
  medicalData: MedicalDataReadResponse;
  onSubmitForm: (formValues: PatientEditForm) => void;
  setTabData: Dispatch<SetStateAction<PatientListTabData>>;
  activeTab: TabFilterPatientList;
  editErrorMessage: string | undefined;
  setEditErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}

const PatientEditModal = ({
  initialValues,
  setTabData,
  medicalData,
  onSubmitForm,
  activeTab,
  editErrorMessage,
  setEditErrorMessage,
}: PatientEditModalProps) => {
  const { t } = useTranslation();

  const schema = yup.object({
    id: yup.string().required(t("ID is required")),
    name: yup.string().required(t("Name is required")),
    sex: yup.string().required(t("Sex is required")),
    birthday: yup.string().required(t("Birthday is required")),
    diagnosis: yup.string().required(t("Diagnosis is required")),
    ward: yup.number().required(t("Ward is required")),
    room: yup.number().required(t("Room is required")),
    department: yup.array().min(1, t("Department is required")),
    doctor: yup.array().min(1, t("Doctor is required")),
  });

  const wardOptions = useMemo(() => {
    return medicalData.ward.map((ward) => ({
      label: ward.name,
      value: ward.id,
    }));
  }, [medicalData.ward]);

  const [roomOptions, setRoomOptions] = useState<
    DefaultOptionType[] | undefined
  >(() => {
    return (
      medicalData.ward
        .find((ward) => ward.id === initialValues.ward)
        ?.rooms.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? []
    );
  });

  const departmentOptions = useMemo(() => {
    return medicalData.department.map((department) => ({
      label: department.name,
      value: department.id.toString(),
    }));
  }, [medicalData.department]);

  const [doctorOptions, setDoctorOptions] = useState<
    DefaultOptionType[] | undefined
  >(() => {
    let currentDoctorOptions: DefaultOptionType[] = [];
    if (
      initialValues.department?.length !== undefined &&
      initialValues.department.length > 0
    ) {
      currentDoctorOptions = medicalData.department
        .filter((item) =>
          initialValues.department?.includes(item.id.toString())
        )
        .flatMap((item) => item.doctors)
        .map((item) => ({
          label: item?.name,
          value: item?.id.toString(),
        }));
    }

    const freeDoctorOptions = medicalData.doctor_freely
      .filter((doctor) => initialValues.doctor?.includes(doctor.id.toString()))
      .map((item) => ({
        label: item?.name,
        value: item?.id.toString(),
      }));

    if (freeDoctorOptions.length > 0) {
      return [...currentDoctorOptions, ...freeDoctorOptions];
    }

    return currentDoctorOptions;
  });

  const {
    control,
    formState: { errors },
    getValues,
    clearErrors,
    setValue,
    reset,
    handleSubmit,
    watch,
  } = useForm<PatientEditForm>({
    defaultValues: initialValues,
    resolver: yupResolver(schema),
  });

  const onResetForm = () => {
    let currentDoctorOptions: DefaultOptionType[] = [];

    if (
      initialValues.department?.length !== undefined &&
      initialValues.department.length > 0
    ) {
      currentDoctorOptions = medicalData.department
        .filter((item) =>
          initialValues.department?.includes(item.id.toString())
        )
        .flatMap((item) => item.doctors)
        .map((item) => ({
          label: item?.name,
          value: item?.id.toString(),
        }));
    }

    const freeDoctorOptions = medicalData.doctor_freely
      .filter((doctor) => initialValues.doctor?.includes(doctor.id.toString()))
      .map((item) => ({
        label: item?.name,
        value: item?.id.toString(),
      }));

    if (freeDoctorOptions.length > 0) {
      currentDoctorOptions = [...currentDoctorOptions, ...freeDoctorOptions];
    }

    setDoctorOptions(currentDoctorOptions);

    reset();
    setRoomOptions(undefined);
  };

  const onCancel = () => {
    setTabData((tabData) => ({
      ...tabData,
      [activeTab]: {
        ...tabData[activeTab],
        isShowEditModal: false,
      },
    }));
  };

  const onWardChange = (
    value: number,
    fieldHelper: ControllerRenderProps<PatientEditForm, "ward">
  ) => {
    const newRoomOptions =
      medicalData.ward
        .find((ward) => ward.id === value)
        ?.rooms.map((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];

    setRoomOptions(newRoomOptions);

    setValue(
      "room",
      newRoomOptions.length > 0 ? newRoomOptions[0].value : undefined
    );

    fieldHelper.onChange(value);
  };

  const onDepartmentChange = async (
    value: string[],
    fieldHelper: ControllerRenderProps<PatientEditForm, "department">
  ) => {
    if (value.length > 0) {
      clearErrors("department");
      const currentSelectedDoctorIds = getValues("doctor") ?? [];

      const newDoctorOptions = getNewDoctorSelectOptions(
        medicalData.department,
        value,
        currentSelectedDoctorIds,
        medicalData.doctor_freely
      );

      if (currentSelectedDoctorIds.length > 0 && newDoctorOptions.length) {
        const newSelectedDoctor = newDoctorOptions
          .filter((option) => currentSelectedDoctorIds.includes(option.value))
          .map((option) => option.value);

        setValue("doctor", newSelectedDoctor);
      }

      setDoctorOptions(newDoctorOptions);
    } else {
      let currentSelectedDoctors = getValues("doctor") ?? [];

      if (currentSelectedDoctors.length > 0) {
        let freeDoctorOptions = filterFreeDoctorOptions(
          medicalData.department,
          currentSelectedDoctors
        );

        freeDoctorOptions = medicalData.doctor_freely
          .filter(
            (freeDoctor) =>
              freeDoctorOptions.findIndex(
                (option) => option.value === freeDoctor.id.toString()
              ) !== -1
          )
          .map((item) => ({
            label: item.name,
            value: item.id.toString(),
          }));

        currentSelectedDoctors = freeDoctorOptions.map((item) => item.value);

        if (currentSelectedDoctors.length === 0) {
          errors.department = {
            message: t("Department is required"),
          };
          errors.doctor = {
            message: t("Doctor is required"),
          };
        } else {
          errors.department = {
            message: t("Department is required"),
          };
        }

        setValue("doctor", currentSelectedDoctors);
        setDoctorOptions(freeDoctorOptions);
      } else {
        setValue("doctor", []);
        setDoctorOptions(undefined);
      }
    }

    fieldHelper.onChange(value);
  };

  const onDoctorChange = (
    selectedValue: string[],
    fieldHelper: ControllerRenderProps<PatientEditForm, "doctor">
  ) => {
    const currentSelectedDepartment = getValues("department") ?? [];
    const currentSelectedDoctor = getValues("doctor") ?? [];

    if (selectedValue.length > 0) {
      clearErrors("doctor");
    } else {
      errors.doctor = {
        message: t("Doctor is required"),
      };
    }

    if (selectedValue.length === 0 && currentSelectedDepartment.length === 0) {
      setDoctorOptions(undefined);
    }
    const freeDoctorOptions = filterFreeDoctorOptions(
      medicalData.department,
      currentSelectedDoctor
    );

    if (freeDoctorOptions.length > 0) {
      const notSelectedFreeDoctorOptions = freeDoctorOptions
        .filter((item) => !selectedValue.includes(item.value))
        .map((item) => item.value);

      const newDoctorOptions = doctorOptions?.filter(
        (option) =>
          typeof option.value === "string" &&
          (!notSelectedFreeDoctorOptions.includes(option.value) ||
            (medicalData.doctor_freely.find(
              (item) => item.id === Number(option.value)
            ) &&
              notSelectedFreeDoctorOptions.includes(option.value)))
      );

      setDoctorOptions(newDoctorOptions);
    }

    fieldHelper.onChange(selectedValue);
  };

  const handleCloseErrorModal = () => {
    setEditErrorMessage(undefined);
  };

  const currentId = watch("id");
  const currentName = watch("name");
  const currentSex = watch("sex");
  const currentBirthday = watch("birthday");
  const currentIllness = watch("diagnosis");
  const currentWard = watch("ward");
  const currentRoom = watch("room");
  const currentDepartment = watch("department");
  const currentDoctor = watch("doctor");

  const isSubmitBtnDisabled = useMemo(() => {
    if (
      initialValues.id === currentId &&
      initialValues.name === currentName &&
      initialValues.sex === currentSex &&
      dayjs(initialValues.birthday).isSame(currentBirthday) &&
      initialValues.diagnosis === currentIllness &&
      initialValues.ward === currentWard &&
      initialValues.room === currentRoom &&
      isEqual(initialValues.department, currentDepartment) &&
      isEqual(initialValues.doctor, currentDoctor)
    ) {
      return true;
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentId,
    currentName,
    currentSex,
    currentSex,
    currentBirthday,
    currentIllness,
    currentWard,
    currentRoom,
    currentDepartment,
    currentDoctor,
  ]);

  return (
    <>
      <Modal
        title={
          <div className={styles.patientEditTitle}>{t("Patient Edit")}</div>
        }
        open={true}
        onCancel={onCancel}
        width={751}
        centered
        className="patient-edit-modal"
        maskClosable={false}
      >
        <form className={styles.patientSearchForm} id="patient-edit-form">
          <Flex justify="space-between">
            <div className={styles.personalSection}>
              <div>
                <div className={styles.idLabel}>
                  <span>{t("ID")} </span>
                </div>
                <Controller
                  name="id"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.id?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.id?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.nameLabel}>
                  <span>{t("Name")} </span>
                </div>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.name?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.name?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.sexLabel}>
                  <span>{t("Sex")} </span>
                </div>
                <Controller
                  name="sex"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Radio.Group {...field}>
                        <IcuRadio value={PATIENT_SEX_VALUE.male}>
                          {t("Male")}
                        </IcuRadio>
                        <IcuRadio value={PATIENT_SEX_VALUE.female}>
                          {t("Female")}
                        </IcuRadio>
                        <IcuRadio value={PATIENT_SEX_VALUE.other}>
                          {t("Other")}
                        </IcuRadio>
                      </Radio.Group>
                    );
                  }}
                />
                {errors.sex?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.sex?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>

              <div>
                <div className={styles.birthdayLabel}>
                  <span>{t("Birthday")} </span>
                </div>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuDateInput
                        width={313.5}
                        height={42}
                        placeholder="yyyy/MM/dd"
                        maxDate={dayjs()}
                        allowClear={false}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.birthday?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.birthday?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <Divider type="vertical" className="patient-edit-divider" dashed />

            <div className={styles.treatmentSection}>
              <div>
                <div className={styles.illnessLabel}>
                  <span>{t("Diagnosis")} </span>
                </div>
                <Controller
                  name="diagnosis"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuTextInput width={313.5} height={42} {...field} />
                    );
                  }}
                />
                {errors.diagnosis?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.diagnosis?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.wardLabel}>
                  <span>{t("Ward")} </span>
                </div>
                <Controller
                  name="ward"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={wardOptions}
                        allowClear={false}
                        {...field}
                        onChange={(value) => onWardChange(value, field)}
                      />
                    );
                  }}
                />
                {errors.ward?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.ward?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.roomLabel}>
                  <span>{t("Room")} </span>
                </div>
                <Controller
                  name="room"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={roomOptions}
                        allowClear={false}
                        {...field}
                      />
                    );
                  }}
                />
                {errors.room?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.room?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.departmentLabel}>
                  <span>{t("Department")} </span>
                </div>
                <Controller
                  name="department"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={departmentOptions}
                        {...field}
                        onChange={(value) => onDepartmentChange(value, field)}
                        mode="multiple"
                        maxCount={3}
                        allowClear={false}
                      />
                    );
                  }}
                />
                {errors.department?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.department?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className={styles.departmentLabel}>
                  <span>{t("Doctor")} </span>
                </div>
                <Controller
                  name="doctor"
                  control={control}
                  render={({ field }) => {
                    return (
                      <IcuSelect
                        width={313.5}
                        height={42}
                        options={doctorOptions}
                        {...field}
                        onChange={(value) => onDoctorChange(value, field)}
                        mode="tags"
                        maxCount={3}
                        value={watch("doctor")}
                        allowClear={false}
                      />
                    );
                  }}
                />
                {errors.doctor?.message ? (
                  <ErrorMessage extraClassName="patient-edit-error-msg">
                    {t(errors.doctor?.message)}
                  </ErrorMessage>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Flex>
          <Divider className={styles.footerDivider} />
          <Flex justify="flex-end" gap={10}>
            <IcuButton
              onClick={() => onResetForm()}
              width={92}
              height={44}
              backgroundColor="light"
              color="grey-3"
              extraClassName="patient-edit-reset-btn"
              disabled={isSubmitBtnDisabled}
            >
              {t("Reset")}
            </IcuButton>
            <IcuButton
              onClick={handleSubmit(onSubmitForm)}
              width={92}
              height={44}
              htmlType="submit"
              disabled={isSubmitBtnDisabled}
            >
              {t("Confirm")}
            </IcuButton>
          </Flex>
        </form>
      </Modal>
      {editErrorMessage !== undefined && (
        <IcuErrorModal
          content={t(editErrorMessage)}
          title="Error"
          handleCancel={handleCloseErrorModal}
          isOpen={editErrorMessage !== undefined}
        />
      )}
    </>
  );
};

export default PatientEditModal;
