import { Flex, message } from "antd";
import {
  UPLOAD_IMAGE_KEY,
  UPLOAD_IMAGE_STATUS,
} from "../constants/common/uploadImageStatus.constant";
import closeIcon from "../assets/icons/close-icon.svg";
import checkedIcon from "../assets/icons/checked-icon.svg";
import failedIcon from "../assets/icons/failed-icon.svg";
import { useTranslation } from "react-i18next";

export const useUploadImage = () => {
  const { t } = useTranslation();

  const [messageApi, contextHolder] = message.useMessage();

  const handleUploadImageCallback = (result: string) => {
    if (result === UPLOAD_IMAGE_STATUS.success) {
      messageApi.open({
        key: UPLOAD_IMAGE_KEY.success,
        type: "success",
        content: (
          <Flex justify="space-between">
            <span>
              <img src={checkedIcon} className="upload-img-checked-icon" />
              <span>{t("Upload Success")}</span>
            </span>
            <img
              src={closeIcon}
              className="upload-img-close-icon"
              onClick={() => {
                messageApi.destroy(UPLOAD_IMAGE_KEY.success);
              }}
            />
          </Flex>
        ),
        duration: 5,
        className: UPLOAD_IMAGE_KEY.success,
        icon: <></>,
      });
    } else if (result === UPLOAD_IMAGE_STATUS.failed) {
      messageApi.open({
        key: UPLOAD_IMAGE_KEY.failed,
        type: "error",
        content: (
          <Flex justify="space-between">
            <span>
              <img src={failedIcon} className="upload-img-failed-icon" />
              <span>{t("Upload failed!")}</span>
            </span>
            <img
              src={closeIcon}
              className="upload-img-close-icon"
              onClick={() => {
                messageApi.destroy(UPLOAD_IMAGE_KEY.failed);
              }}
            />
          </Flex>
        ),
        duration: 5,
        className: UPLOAD_IMAGE_KEY.failed,
        icon: <></>,
      });
    }
  };

  return {
    handleUploadImageCallback,
    contextHolder,
  };
};
