export const DATE_FORMAT = {
  YYYYMMDD: "YYYY/MM/DD",
  YYYYMMDDHHmm: "YYYY/MM/DD HH:mm",
  HHmm: "HH:mm",
};

export const DATE_FORMAT_SEND_TO_SERVER = {
  YYYYMMDDHHmmss: "YYYY-MM-DD HH:mm:ss",
  YYYYMMDD: "YYYY-MM-DD",
};
