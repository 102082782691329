import {
  API_CREATE_EXAMINATION_RESULT_OCR,
  API_GET_EXAMINATION_RESULT,
  API_GET_LATEST_EXAMINATION_RESULT,
  API_PUT_EXAMINATION_RESULT,
  API_UPDATE_EXAMINATION_RESULT,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { ExaminationResultQueryTypeDate } from "../../constants/examination-result/ExaminationResultQueryTypeDate.constant";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { ExaminationResultUpdateResponse } from "../../types/examination-result/ExaminationResultUpdateResponse.model";
import { LatestExamResultReadResponse } from "../../types/examination-result/LatestExamResultReadResponse.model";
import { TimeSeriesExamResultReadResponse } from "../../types/time-series-exam-table/TimeSeriesExamResultReadResponse.model";

export const getLatestExamResult = (
  admissionRecordId: number,
  targetDate?: string,
  queryTypeDate?: ExaminationResultQueryTypeDate
): Promise<BaseResponse<LatestExamResultReadResponse[]>> => {
  return networkAdapter.get(
    `${API_GET_LATEST_EXAMINATION_RESULT}/${admissionRecordId}`,
    {
      date: targetDate,
      query_type_date: queryTypeDate,
    }
  );
};

export const updateExaminationResult = (
  id: number,
  value: string | null
): Promise<BaseResponse<ExaminationResultUpdateResponse>> => {
  return networkAdapter.patch(API_UPDATE_EXAMINATION_RESULT, {
    id,
    value,
  });
};

export const getAllExamResults = (
  admissionRecordId: number,
  category: number,
  targetDate?: string
): Promise<BaseResponse<TimeSeriesExamResultReadResponse[]>> => {
  return networkAdapter.get(
    `${API_GET_EXAMINATION_RESULT}/${admissionRecordId}/${category}`,
    {
      date: targetDate,
    }
  );
};

export const putExaminationResult = (
  admissionRecordId: number,
  typeId: number,
  value: string | null,
  createdAt: string
) => {
  return networkAdapter.put(API_PUT_EXAMINATION_RESULT, {
    id_admission_record: admissionRecordId,
    id_type: typeId,
    value: value,
    created_at: createdAt,
  });
};

export const createExaminationResultOCR = (
  admissionRecordId: string,
  image: string
) => {
  return networkAdapter.post(API_CREATE_EXAMINATION_RESULT_OCR, {
    id_admission_record: admissionRecordId,
    image,
  });
};
