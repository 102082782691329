import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LOGIN_PATH } from "../../../configs/routerPath";
import { checkLogin } from "../../../utils/helpers";

const PrivateRoute = () => {
  return checkLogin() ? <Outlet /> : <Navigate to={LOGIN_PATH} />;
};

export default PrivateRoute;
