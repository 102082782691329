import {
  EXAMINATION_CATEGORY_VALUE,
  ExaminationCategory,
} from "../../constants/examination-result/ExaminationCategory.constant";
import {
  ExaminationResultHeader,
  ExaminationResultTableRow,
} from "../../types/examination-result/ExaminationResultTableData.model";
import { ExaminationResultReadResponse } from "../../types/examination-result/ExaminationResultReadResponse.model";
import { EXAMINATION_PDF_TABLE_COLUMNS } from "../../constants/examination-result/ExaminationPdfTableColumns.constant";
import { LatestExamResultReadResponse } from "../../types/examination-result/LatestExamResultReadResponse.model";
import { convertUTCtoLocalDate } from "../helpers";

export const convertCategoryValue = (value: number) => {
  switch (value) {
    case EXAMINATION_CATEGORY_VALUE.biochemistry:
      return ExaminationCategory.BIOCHEMISTRY;
    case EXAMINATION_CATEGORY_VALUE.bloodCount:
      return ExaminationCategory.BLOOD_COUNT;
    case EXAMINATION_CATEGORY_VALUE.coagulation:
      return ExaminationCategory.COAGULATION;
    case EXAMINATION_CATEGORY_VALUE.bloodGasAnalysis:
      return ExaminationCategory.BLOOD_GAS_ANALYSIS;

    default:
      return ExaminationCategory.URINARY_ANALYSIS;
  }
};

const checkExamResultHeaderTitleExist = (
  headerTitleList: ExaminationResultHeader[],
  title: string
) => {
  return headerTitleList.findIndex((item) => item.name === title);
};

export const formatExaminationResultData = (
  data: ExaminationResultReadResponse[]
) => {
  if (data.length > 0) {
    let tableData: ExaminationResultTableRow[] = [];

    const createdAtSet = new Set<string>();
    const headers: ExaminationResultHeader[] = [];

    data.sort((a, b) => a.id_type - b.id_type);

    data.forEach((item) => {
      if (checkExamResultHeaderTitleExist(headers, item.name) === -1) {
        headers.push({
          name: item.name,
          unit: item.unit,
        });
      }

      const createdAt = convertUTCtoLocalDate(item.created_at);
      createdAtSet.add(createdAt);
    });

    const createdAtList = Array.from(createdAtSet);

    createdAtList.sort((a, b) => b.localeCompare(a));

    createdAtList.forEach((createdAt) => {
      const formattedRowData = data.reduce(
        (prev, current) => {
          if (
            current.created_at !== undefined &&
            convertUTCtoLocalDate(current.created_at) === createdAt
          ) {
            return {
              ...prev,
              [current.name]: current.value,
              [`${current.name}Min`]: current.reference_min,
              [`${current.name}Max`]: current.reference_max,
              [`${current.name}Name`]: current.name,
              [`${current.name}Unit`]: current.unit,
              [`${current.name}Id`]: current.id_result,
              [`${current.name}TypeId`]: current.id_type,
              [`${current.name}Ref`]: `${current.reference_min ?? ""}-${
                current.reference_max ?? ""
              }`,
              createdAt: createdAt,
            };
          }

          return prev;
        },
        {
          createdAt,
        }
      );

      tableData.push(formattedRowData);
    });

    return {
      tableData,
      headers,
    };
  }
};

export const tranformExaminationResultToTableData = (
  data: LatestExamResultReadResponse[]
) => {
  const biochemistryList = data
    ? formatExaminationResultData(
        data.find(
          (item) => item.category === EXAMINATION_CATEGORY_VALUE.biochemistry
        )?.examination_results ?? []
      )
    : undefined;

  const bloodCountList = data
    ? formatExaminationResultData(
        data.find(
          (item) => item.category === EXAMINATION_CATEGORY_VALUE.bloodCount
        )?.examination_results ?? []
      )
    : undefined;

  const coagulationList = data
    ? formatExaminationResultData(
        data.find(
          (item) => item.category === EXAMINATION_CATEGORY_VALUE.coagulation
        )?.examination_results ?? []
      )
    : undefined;

  const bloodGasList = data
    ? formatExaminationResultData(
        data.find(
          (item) =>
            item.category === EXAMINATION_CATEGORY_VALUE.bloodGasAnalysis
        )?.examination_results ?? []
      )
    : undefined;

  const urinaryList = data
    ? formatExaminationResultData(
        data.find(
          (item) => item.category === EXAMINATION_CATEGORY_VALUE.urinaryAnalysis
        )?.examination_results ?? []
      )
    : undefined;

  return {
    biochemistryList,
    bloodCountList,
    coagulationList,
    bloodGasList,
    urinaryList,
  };
};

export const transformExaminationResultToPdfTableData = (
  data: LatestExamResultReadResponse[]
) => {
  const biochemistryList =
    data?.find(
      (item) => item.category === EXAMINATION_CATEGORY_VALUE.biochemistry
    )?.examination_results ?? [];

  const bloodCountList =
    data?.find(
      (item) => item.category === EXAMINATION_CATEGORY_VALUE.bloodCount
    )?.examination_results ?? [];

  const coagulationList =
    data?.find(
      (item) => item.category === EXAMINATION_CATEGORY_VALUE.coagulation
    )?.examination_results ?? [];

  const bloodGasList =
    data?.find(
      (item) => item.category === EXAMINATION_CATEGORY_VALUE.bloodGasAnalysis
    )?.examination_results ?? [];

  const urinaryList =
    data?.find(
      (item) => item.category === EXAMINATION_CATEGORY_VALUE.urinaryAnalysis
    )?.examination_results ?? [];

  const biochemistrySubTableResults: ExaminationResultReadResponse[][] = [];
  let biochemistryResults: ExaminationResultReadResponse[] = [];

  biochemistryList.forEach((item, index) => {
    if (index > 0 && index % EXAMINATION_PDF_TABLE_COLUMNS === 0) {
      biochemistrySubTableResults.push(biochemistryResults);
      biochemistryResults = [];
    }

    if (index === biochemistryList.length - 1) {
      biochemistryResults.push(item);
      biochemistrySubTableResults.push(biochemistryResults);
    } else {
      biochemistryResults.push(item);
    }
  });

  const bloodCountSubTableResults: ExaminationResultReadResponse[][] = [];
  let bloodCountResults: ExaminationResultReadResponse[] = [];

  bloodCountList.forEach((item, index) => {
    if (index > 0 && index % EXAMINATION_PDF_TABLE_COLUMNS === 0) {
      bloodCountSubTableResults.push(bloodCountResults);
      bloodCountResults = [];
    }

    if (index === bloodCountList.length - 1) {
      bloodCountResults.push(item);
      bloodCountSubTableResults.push(bloodCountResults);
    } else {
      bloodCountResults.push(item);
    }
  });

  const coagulationSubTableResults: ExaminationResultReadResponse[][] = [];
  let coagulationResults: ExaminationResultReadResponse[] = [];

  coagulationList.forEach((item, index) => {
    if (index > 0 && index % EXAMINATION_PDF_TABLE_COLUMNS === 0) {
      coagulationSubTableResults.push(coagulationResults);
      coagulationResults = [];
    }

    if (index === coagulationList.length - 1) {
      coagulationResults.push(item);
      coagulationSubTableResults.push(coagulationResults);
    } else {
      coagulationResults.push(item);
    }
  });

  const bloodGasSubTableResults: ExaminationResultReadResponse[][] = [];
  let bloodGasResults: ExaminationResultReadResponse[] = [];

  bloodGasList.forEach((item, index) => {
    if (index > 0 && index % EXAMINATION_PDF_TABLE_COLUMNS === 0) {
      bloodGasSubTableResults.push(bloodGasResults);
      bloodGasResults = [];
    }

    if (index === bloodGasList.length - 1) {
      bloodGasResults.push(item);
      bloodGasSubTableResults.push(bloodGasResults);
    } else {
      bloodGasResults.push(item);
    }
  });

  const urinarySubTableResults: ExaminationResultReadResponse[][] = [];
  let urinaryResults: ExaminationResultReadResponse[] = [];

  urinaryList.forEach((item, index) => {
    if (index > 0 && index % EXAMINATION_PDF_TABLE_COLUMNS === 0) {
      urinarySubTableResults.push(urinaryResults);
      urinaryResults = [];
    }

    if (index === urinaryList.length - 1) {
      urinaryResults.push(item);
      urinarySubTableResults.push(urinaryResults);
    } else {
      urinaryResults.push(item);
    }
  });

  const biochemistrySubTableData = biochemistrySubTableResults.map((item) =>
    formatExaminationResultData(item)
  );
  const bloodCountSubTableData = bloodCountSubTableResults.map((item) =>
    formatExaminationResultData(item)
  );
  const coagulationSubTableData = coagulationSubTableResults.map((item) =>
    formatExaminationResultData(item)
  );
  const bloodGasSubTableData = bloodGasSubTableResults.map((item) =>
    formatExaminationResultData(item)
  );
  const urinarySubTableData = urinarySubTableResults.map((item) =>
    formatExaminationResultData(item)
  );

  return {
    biochemistrySubTableData,
    bloodCountSubTableData,
    coagulationSubTableData,
    bloodGasSubTableData,
    urinarySubTableData,
  };
};

export const checkExamValueInRange = (
  value: string | number,
  minVal: string | number,
  maxVal: string | number
) => {
  if (value && maxVal && minVal) {
    if (Number(value) > Number(maxVal)) {
      return "exam-result-red-text";
    } else if (Number(value) < Number(minVal)) {
      return "exam-result-blue-text";
    }
  }

  return "";
};
