import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./ProblemList.module.scss";
import { Flex } from "antd";
import IcuButton from "../../common/button/IcuButton";
import { PlusCircleFilled } from "@ant-design/icons";
import "./ProblemList.scss";
import ProblemItem from "../problem-item/ProblemItem";
import { ProblemItemEntity } from "../../../types/patient-detail/ProblemItem.model";
import { ProblemListRef } from "../../../types/patient-list/ProblemListRef.model";
import { useTranslation } from "react-i18next";
import { UpdatedProblemTypeParams } from "../../../types/patient-detail/UpdatedProblemTypeParams.model";

interface ProblemListProps {
  data: ProblemItemEntity[];
  onAddProblem: () => void;
  onDeleteProblem: (id?: number) => void;
  onBlurProblemInput: (value: string, id?: number) => void;
  onUpdateProblemType: (item: UpdatedProblemTypeParams) => void;
}

function ProblemListInner(
  {
    data,
    onAddProblem,
    onDeleteProblem,
    onBlurProblemInput,
    onUpdateProblemType,
  }: ProblemListProps,
  ref: ForwardedRef<ProblemListRef>
) {
  const { t } = useTranslation();

  const [isDisabledAddBtn, setIsDisabledAddBtn] = useState(false);

  useImperativeHandle(
    ref,
    () => {
      return {
        disableAddBtn: () => setIsDisabledAddBtn(true),
        enableAddBtn: () => setIsDisabledAddBtn(false),
      };
    },
    []
  );

  return (
    <div className={styles.problemListSection}>
      <Flex justify="space-between" align="center" className={styles.heading}>
        <div>{t("Problem list")}</div>
        <IcuButton
          width={83}
          height={34}
          icon={<PlusCircleFilled />}
          extraClassName="add-problem-btn"
          onClick={onAddProblem}
          disabled={isDisabledAddBtn}
        >
          {t("Add")}
        </IcuButton>
      </Flex>

      <Flex vertical className={`${styles.list} problem-list-items`}>
        {data.map((item, index) => (
          <ProblemItem
            data={item}
            key={item.id}
            onDeleteProblem={onDeleteProblem}
            onBlurProblemInput={onBlurProblemInput}
            currentIndex={index}
            onUpdateProblemType={onUpdateProblemType}
          />
        ))}
      </Flex>
    </div>
  );
}

export const ProblemList = forwardRef<ProblemListRef, ProblemListProps>(
  ProblemListInner
);
