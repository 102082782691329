import Logo from "../../../assets/img/txp-medical-logo-2.png";
import { Avatar, Dropdown, Flex, MenuProps } from "antd";
import styles from "./IcuHeader.module.scss";
import "./IcuHeader.scss";
import { useTranslation } from "react-i18next";
import { CaretDownOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LOGIN_PATH, PATIENT_LIST_PATH } from "../../../configs/routerPath";
import arrowOutline from "../../../assets/icons/arrow-outline.svg";
import { LOCAL_STORAGE_KEY } from "../../../constants/common/localStorageKey.constant";
import CryptoJS from "crypto-js";
import { useMemo } from "react";

const IcuHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userName = useMemo(() => {
    const encryptedUsername =
      localStorage.getItem(LOCAL_STORAGE_KEY.userName) ?? "";

    return CryptoJS.AES.decrypt(
      encryptedUsername,
      process.env.REACT_APP_FRONTEND_ENCRYPTED_KEY ?? ""
    ).toString(CryptoJS.enc.Utf8);
  }, []);

  const handleLogout = () => {
    localStorage.clear();

    navigate(LOGIN_PATH);
  };

  const menuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Flex gap={8} align="center">
          <span>
            <img
              src={arrowOutline}
              alt="log out icon"
              className={styles.logOutIcon}
            />
          </span>
          <span className={styles.logOutText}>{t("Log out")}</span>
        </Flex>
      ),
    },
  ];

  const navigateToPatientListPage = () => {
    window.location.replace(PATIENT_LIST_PATH);
  };

  return (
    <Flex className={`${styles.header} icu-header`} justify="space-between">
      <div role="button" onClick={navigateToPatientListPage}>
        <img src={Logo} alt="txp medical logo" className={styles.logo} />
      </div>

      <Dropdown
        menu={{ items: menuItems, onClick: handleLogout }}
        className={styles.dropdownMenu}
      >
        <Flex className={`${styles.userInfo} header-user-info`}>
          <Avatar
            shape="square"
            size={42}
            icon={<UserOutlined />}
            className={styles.avatar}
          />
          <Flex gap={8} align="center">
            <p className={styles.userName}>{userName}</p>
            <div>
              <CaretDownOutlined className={styles.dropdownIcon} />
            </div>
          </Flex>
        </Flex>
      </Dropdown>
    </Flex>
  );
};

export default IcuHeader;
