import {
  API_CREATE_PROGRESS_NOTE,
  API_GET_PROGRESS_NOTE,
  API_UPDATE_PROGRESS_NOTE,
} from "../../configs/api/endpoints";
import networkAdapter from "../../configs/api/networkAdapter";
import { BaseResponse } from "../../types/common/BaseResponse.model";
import { ProgressNoteCreateRequest } from "../../types/patient-detail/ProgressNoteCreateRequest.model";
import { ProgressNotePatchRequest } from "../../types/patient-detail/ProgressNotePatchRequest.model";
import { ProgressNoteReadResponse } from "../../types/patient-detail/ProgressNoteReadResponse.model";

export const getProgressNotes = (
  admissionRecordId: number,
  date?: string
): Promise<BaseResponse<ProgressNoteReadResponse[]>> => {
  return networkAdapter.get(`${API_GET_PROGRESS_NOTE}/${admissionRecordId}`, {
    date,
  });
};

export const createProgressNote = (params: ProgressNoteCreateRequest) => {
  return networkAdapter.post(API_CREATE_PROGRESS_NOTE, params);
};

export const updateProgressNote = (data: ProgressNotePatchRequest) => {
  return networkAdapter.patch(API_UPDATE_PROGRESS_NOTE, data);
};
