import React, { ForwardedRef, forwardRef } from "react";
import { Input, InputRef } from "antd";
import "./IcuTextInput.scss";
import { FieldValues, Path } from "react-hook-form";
import { CompoundIcuTextInputProps } from "../../../types/common/IcuTextInput.model";
import { preventPressEnter } from "../../../utils/helpers";

function IcuTextInputInner<T extends FieldValues, K extends Path<T>>(
  {
    width,
    height,
    type = "text",
    id,
    customRootClassName = "",
    onPressEnter = preventPressEnter,
    ...fieldProps
  }: CompoundIcuTextInputProps<T, K>,
  ref: ForwardedRef<InputRef>
) {
  return (
    <Input
      className="bg-light border-none icu-text-input"
      style={{
        width,
        height,
      }}
      type={type}
      id={id}
      onKeyDown={onPressEnter}
      rootClassName={customRootClassName}
      {...fieldProps}
      ref={ref}
    />
  );
}

export const IcuTextInput = forwardRef(IcuTextInputInner);
